/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipRequirements from './ShipRequirements';

/**
 * The ShipMount model module.
 * @module models/ShipMount
 * @version 2.0.0
 */
class ShipMount {
    /**
     * Constructs a new <code>ShipMount</code>.
     * A mount is installed on the exterier of a ship.
     * @alias module:models/ShipMount
     * @param symbol {module:models/ShipMount.SymbolEnum} Symbo of this mount.
     * @param name {String} Name of this mount.
     * @param requirements {module:models/ShipRequirements} 
     */
    constructor(symbol, name, requirements) { 
        
        ShipMount.initialize(this, symbol, name, requirements);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, name, requirements) { 
        obj['symbol'] = symbol;
        obj['name'] = name;
        obj['requirements'] = requirements;
    }

    /**
     * Constructs a <code>ShipMount</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipMount} obj Optional instance to populate.
     * @return {module:models/ShipMount} The populated <code>ShipMount</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipMount();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('strength')) {
                obj['strength'] = ApiClient.convertToType(data['strength'], 'Number');
            }
            if (data.hasOwnProperty('deposits')) {
                obj['deposits'] = ApiClient.convertToType(data['deposits'], ['String']);
            }
            if (data.hasOwnProperty('requirements')) {
                obj['requirements'] = ShipRequirements.constructFromObject(data['requirements']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipMount</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipMount</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipMount.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is an array
        if (!Array.isArray(data['deposits'])) {
            throw new Error("Expected the field `deposits` to be an array in the JSON data but got " + data['deposits']);
        }
        // validate the optional field `requirements`
        if (data['requirements']) { // data not null
          ShipRequirements.validateJSON(data['requirements']);
        }

        return true;
    }


}

ShipMount.RequiredProperties = ["symbol", "name", "requirements"];

/**
 * Symbo of this mount.
 * @member {module:models/ShipMount.SymbolEnum} symbol
 */
ShipMount.prototype['symbol'] = undefined;

/**
 * Name of this mount.
 * @member {String} name
 */
ShipMount.prototype['name'] = undefined;

/**
 * Description of this mount.
 * @member {String} description
 */
ShipMount.prototype['description'] = undefined;

/**
 * Mounts that have this value, such as mining lasers, denote how powerful this mount's capabilities are.
 * @member {Number} strength
 */
ShipMount.prototype['strength'] = undefined;

/**
 * Mounts that have this value denote what goods can be produced from using the mount.
 * @member {Array.<module:models/ShipMount.DepositsEnum>} deposits
 */
ShipMount.prototype['deposits'] = undefined;

/**
 * @member {module:models/ShipRequirements} requirements
 */
ShipMount.prototype['requirements'] = undefined;





/**
 * Allowed values for the <code>symbol</code> property.
 * @enum {String}
 * @readonly
 */
ShipMount['SymbolEnum'] = {

    /**
     * value: "MOUNT_GAS_SIPHON_I"
     * @const
     */
    "GAS_SIPHON_I": "MOUNT_GAS_SIPHON_I",

    /**
     * value: "MOUNT_GAS_SIPHON_II"
     * @const
     */
    "GAS_SIPHON_II": "MOUNT_GAS_SIPHON_II",

    /**
     * value: "MOUNT_GAS_SIPHON_III"
     * @const
     */
    "GAS_SIPHON_III": "MOUNT_GAS_SIPHON_III",

    /**
     * value: "MOUNT_SURVEYOR_I"
     * @const
     */
    "SURVEYOR_I": "MOUNT_SURVEYOR_I",

    /**
     * value: "MOUNT_SURVEYOR_II"
     * @const
     */
    "SURVEYOR_II": "MOUNT_SURVEYOR_II",

    /**
     * value: "MOUNT_SURVEYOR_III"
     * @const
     */
    "SURVEYOR_III": "MOUNT_SURVEYOR_III",

    /**
     * value: "MOUNT_SENSOR_ARRAY_I"
     * @const
     */
    "SENSOR_ARRAY_I": "MOUNT_SENSOR_ARRAY_I",

    /**
     * value: "MOUNT_SENSOR_ARRAY_II"
     * @const
     */
    "SENSOR_ARRAY_II": "MOUNT_SENSOR_ARRAY_II",

    /**
     * value: "MOUNT_SENSOR_ARRAY_III"
     * @const
     */
    "SENSOR_ARRAY_III": "MOUNT_SENSOR_ARRAY_III",

    /**
     * value: "MOUNT_MINING_LASER_I"
     * @const
     */
    "MINING_LASER_I": "MOUNT_MINING_LASER_I",

    /**
     * value: "MOUNT_MINING_LASER_II"
     * @const
     */
    "MINING_LASER_II": "MOUNT_MINING_LASER_II",

    /**
     * value: "MOUNT_MINING_LASER_III"
     * @const
     */
    "MINING_LASER_III": "MOUNT_MINING_LASER_III",

    /**
     * value: "MOUNT_LASER_CANNON_I"
     * @const
     */
    "LASER_CANNON_I": "MOUNT_LASER_CANNON_I",

    /**
     * value: "MOUNT_MISSILE_LAUNCHER_I"
     * @const
     */
    "MISSILE_LAUNCHER_I": "MOUNT_MISSILE_LAUNCHER_I",

    /**
     * value: "MOUNT_TURRET_I"
     * @const
     */
    "TURRET_I": "MOUNT_TURRET_I"
};


/**
 * Allowed values for the <code>deposits</code> property.
 * @enum {String}
 * @readonly
 */
ShipMount['DepositsEnum'] = {

    /**
     * value: "QUARTZ_SAND"
     * @const
     */
    "QUARTZ_SAND": "QUARTZ_SAND",

    /**
     * value: "SILICON_CRYSTALS"
     * @const
     */
    "SILICON_CRYSTALS": "SILICON_CRYSTALS",

    /**
     * value: "PRECIOUS_STONES"
     * @const
     */
    "PRECIOUS_STONES": "PRECIOUS_STONES",

    /**
     * value: "ICE_WATER"
     * @const
     */
    "ICE_WATER": "ICE_WATER",

    /**
     * value: "AMMONIA_ICE"
     * @const
     */
    "AMMONIA_ICE": "AMMONIA_ICE",

    /**
     * value: "IRON_ORE"
     * @const
     */
    "IRON_ORE": "IRON_ORE",

    /**
     * value: "COPPER_ORE"
     * @const
     */
    "COPPER_ORE": "COPPER_ORE",

    /**
     * value: "SILVER_ORE"
     * @const
     */
    "SILVER_ORE": "SILVER_ORE",

    /**
     * value: "ALUMINUM_ORE"
     * @const
     */
    "ALUMINUM_ORE": "ALUMINUM_ORE",

    /**
     * value: "GOLD_ORE"
     * @const
     */
    "GOLD_ORE": "GOLD_ORE",

    /**
     * value: "PLATINUM_ORE"
     * @const
     */
    "PLATINUM_ORE": "PLATINUM_ORE",

    /**
     * value: "DIAMONDS"
     * @const
     */
    "DIAMONDS": "DIAMONDS",

    /**
     * value: "URANITE_ORE"
     * @const
     */
    "URANITE_ORE": "URANITE_ORE",

    /**
     * value: "MERITIUM_ORE"
     * @const
     */
    "MERITIUM_ORE": "MERITIUM_ORE"
};



export default ShipMount;

