import SaveManager from "@/libs/saveManager";
import {instance as api} from "./api.js";
// {agent} from "@/libs/api/models/agent";
import {contract} from "@/libs/api/models/contract";
import {error} from "@/libs/api/error";
import {registeringResponse} from "@/libs/api/models/registeringResponse";
export function getToken(){
    return SaveManager.read("auth_token");
}
export function registerAgent(agentName) {
    //if (!api.hasToken()) {
        api.queryApi("register", { symbol: agentName, faction: "COSMIC", }, "POST", (async x => {
            if (x.status) {
                SaveManager.save("auth_token", x.result.data.token);
                console.log("Saved token : " + x.result.data.token);

                api.setupAPI(x.result.data.token);
                //console.log(JSON.stringify(x.result));
                //console.log(new agent(x.result.data.agent));
                console.log(new registeringResponse(x.result.data));
            }
            else{
                console.log("Could not create the token");
                console.log(new error(x.result));
            }
        }));
    //}
    //else console.log("Token already exists : " + SaveManager.read("auth_token"));
}

/**
 *
 * @param {function(contract[]):void} callback
 */
export function getContracts(callback) {
    if (api.hasToken()) {
        api.queryApi("my/contracts", { }, "GET", (async x => {
            if (x.status) {
                console.log(x.result);
                let contracts = [];
                x.result.data.forEach(d => {
                    contracts.push(new contract(d));
                });
                /** @var {contract} x.result */
                callback(contracts);
                //console.log(new agent(x.result.data.agent));
            }
            else console.log("Could not fetch contracts");
        }));
    }
    else console.log("Missing API token, please register.");
}