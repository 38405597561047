/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class SystemType.
* @enum {}
* @readonly
*/
export default class SystemType {
    
        /**
         * value: "NEUTRON_STAR"
         * @const
         */
        "NEUTRON_STAR" = "NEUTRON_STAR";

    
        /**
         * value: "RED_STAR"
         * @const
         */
        "RED_STAR" = "RED_STAR";

    
        /**
         * value: "ORANGE_STAR"
         * @const
         */
        "ORANGE_STAR" = "ORANGE_STAR";

    
        /**
         * value: "BLUE_STAR"
         * @const
         */
        "BLUE_STAR" = "BLUE_STAR";

    
        /**
         * value: "YOUNG_STAR"
         * @const
         */
        "YOUNG_STAR" = "YOUNG_STAR";

    
        /**
         * value: "WHITE_DWARF"
         * @const
         */
        "WHITE_DWARF" = "WHITE_DWARF";

    
        /**
         * value: "BLACK_HOLE"
         * @const
         */
        "BLACK_HOLE" = "BLACK_HOLE";

    
        /**
         * value: "HYPERGIANT"
         * @const
         */
        "HYPERGIANT" = "HYPERGIANT";

    
        /**
         * value: "NEBULA"
         * @const
         */
        "NEBULA" = "NEBULA";

    
        /**
         * value: "UNSTABLE"
         * @const
         */
        "UNSTABLE" = "UNSTABLE";

    

    /**
    * Returns a <code>SystemType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/SystemType} The enum <code>SystemType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

