<template>
  <div>Test component</div>
</template>

<script>
export default {
  name: "TEST"
};
</script>

<style scoped>
  div {color: indianred;}
</style>