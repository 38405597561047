/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ScannedShipEngine from './ScannedShipEngine';
import ScannedShipFrame from './ScannedShipFrame';
import ScannedShipMountsInner from './ScannedShipMountsInner';
import ScannedShipReactor from './ScannedShipReactor';
import ShipNav from './ShipNav';
import ShipRegistration from './ShipRegistration';

/**
 * The ScannedShip model module.
 * @module models/ScannedShip
 * @version 2.0.0
 */
class ScannedShip {
    /**
     * Constructs a new <code>ScannedShip</code>.
     * The ship that was scanned. Details include information about the ship that could be detected by the scanner.
     * @alias module:models/ScannedShip
     * @param symbol {String} The globally unique identifier of the ship.
     * @param registration {module:models/ShipRegistration} 
     * @param nav {module:models/ShipNav} 
     * @param engine {module:models/ScannedShipEngine} 
     */
    constructor(symbol, registration, nav, engine) { 
        
        ScannedShip.initialize(this, symbol, registration, nav, engine);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, registration, nav, engine) { 
        obj['symbol'] = symbol;
        obj['registration'] = registration;
        obj['nav'] = nav;
        obj['engine'] = engine;
    }

    /**
     * Constructs a <code>ScannedShip</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ScannedShip} obj Optional instance to populate.
     * @return {module:models/ScannedShip} The populated <code>ScannedShip</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ScannedShip();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('registration')) {
                obj['registration'] = ShipRegistration.constructFromObject(data['registration']);
            }
            if (data.hasOwnProperty('nav')) {
                obj['nav'] = ShipNav.constructFromObject(data['nav']);
            }
            if (data.hasOwnProperty('frame')) {
                obj['frame'] = ScannedShipFrame.constructFromObject(data['frame']);
            }
            if (data.hasOwnProperty('reactor')) {
                obj['reactor'] = ScannedShipReactor.constructFromObject(data['reactor']);
            }
            if (data.hasOwnProperty('engine')) {
                obj['engine'] = ScannedShipEngine.constructFromObject(data['engine']);
            }
            if (data.hasOwnProperty('mounts')) {
                obj['mounts'] = ApiClient.convertToType(data['mounts'], [ScannedShipMountsInner]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ScannedShip</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ScannedShip</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ScannedShip.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // validate the optional field `registration`
        if (data['registration']) { // data not null
          ShipRegistration.validateJSON(data['registration']);
        }
        // validate the optional field `nav`
        if (data['nav']) { // data not null
          ShipNav.validateJSON(data['nav']);
        }
        // validate the optional field `frame`
        if (data['frame']) { // data not null
          ScannedShipFrame.validateJSON(data['frame']);
        }
        // validate the optional field `reactor`
        if (data['reactor']) { // data not null
          ScannedShipReactor.validateJSON(data['reactor']);
        }
        // validate the optional field `engine`
        if (data['engine']) { // data not null
          ScannedShipEngine.validateJSON(data['engine']);
        }
        if (data['mounts']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['mounts'])) {
                throw new Error("Expected the field `mounts` to be an array in the JSON data but got " + data['mounts']);
            }
            // validate the optional field `mounts` (array)
            for (const item of data['mounts']) {
                ScannedShipMountsInner.validateJSON(item);
            };
        }

        return true;
    }


}

ScannedShip.RequiredProperties = ["symbol", "registration", "nav", "engine"];

/**
 * The globally unique identifier of the ship.
 * @member {String} symbol
 */
ScannedShip.prototype['symbol'] = undefined;

/**
 * @member {module:models/ShipRegistration} registration
 */
ScannedShip.prototype['registration'] = undefined;

/**
 * @member {module:models/ShipNav} nav
 */
ScannedShip.prototype['nav'] = undefined;

/**
 * @member {module:models/ScannedShipFrame} frame
 */
ScannedShip.prototype['frame'] = undefined;

/**
 * @member {module:models/ScannedShipReactor} reactor
 */
ScannedShip.prototype['reactor'] = undefined;

/**
 * @member {module:models/ScannedShipEngine} engine
 */
ScannedShip.prototype['engine'] = undefined;

/**
 * List of mounts installed in the ship.
 * @member {Array.<module:models/ScannedShipMountsInner>} mounts
 */
ScannedShip.prototype['mounts'] = undefined;






export default ScannedShip;

