/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import RemoveMount201ResponseData from './RemoveMount201ResponseData';

/**
 * The RemoveMount201Response model module.
 * @module models/RemoveMount201Response
 * @version 2.0.0
 */
class RemoveMount201Response {
    /**
     * Constructs a new <code>RemoveMount201Response</code>.
     * @alias module:models/RemoveMount201Response
     * @param data {module:models/RemoveMount201ResponseData} 
     */
    constructor(data) { 
        
        RemoveMount201Response.initialize(this, data);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, data) { 
        obj['data'] = data;
    }

    /**
     * Constructs a <code>RemoveMount201Response</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/RemoveMount201Response} obj Optional instance to populate.
     * @return {module:models/RemoveMount201Response} The populated <code>RemoveMount201Response</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RemoveMount201Response();

            if (data.hasOwnProperty('data')) {
                obj['data'] = RemoveMount201ResponseData.constructFromObject(data['data']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RemoveMount201Response</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RemoveMount201Response</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RemoveMount201Response.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `data`
        if (data['data']) { // data not null
          RemoveMount201ResponseData.validateJSON(data['data']);
        }

        return true;
    }


}

RemoveMount201Response.RequiredProperties = ["data"];

/**
 * @member {module:models/RemoveMount201ResponseData} data
 */
RemoveMount201Response.prototype['data'] = undefined;






export default RemoveMount201Response;

