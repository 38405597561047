/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import MarketTransaction from './MarketTransaction';
import ShipFuel from './ShipFuel';

/**
 * The RefuelShip200ResponseData model module.
 * @module models/RefuelShip200ResponseData
 * @version 2.0.0
 */
class RefuelShip200ResponseData {
    /**
     * Constructs a new <code>RefuelShip200ResponseData</code>.
     * @alias module:models/RefuelShip200ResponseData
     * @param agent {module:models/Agent} 
     * @param fuel {module:models/ShipFuel} 
     * @param transaction {module:models/MarketTransaction} 
     */
    constructor(agent, fuel, transaction) { 
        
        RefuelShip200ResponseData.initialize(this, agent, fuel, transaction);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, agent, fuel, transaction) { 
        obj['agent'] = agent;
        obj['fuel'] = fuel;
        obj['transaction'] = transaction;
    }

    /**
     * Constructs a <code>RefuelShip200ResponseData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/RefuelShip200ResponseData} obj Optional instance to populate.
     * @return {module:models/RefuelShip200ResponseData} The populated <code>RefuelShip200ResponseData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RefuelShip200ResponseData();

            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('fuel')) {
                obj['fuel'] = ShipFuel.constructFromObject(data['fuel']);
            }
            if (data.hasOwnProperty('transaction')) {
                obj['transaction'] = MarketTransaction.constructFromObject(data['transaction']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>RefuelShip200ResponseData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>RefuelShip200ResponseData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of RefuelShip200ResponseData.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        // validate the optional field `fuel`
        if (data['fuel']) { // data not null
          ShipFuel.validateJSON(data['fuel']);
        }
        // validate the optional field `transaction`
        if (data['transaction']) { // data not null
          MarketTransaction.validateJSON(data['transaction']);
        }

        return true;
    }


}

RefuelShip200ResponseData.RequiredProperties = ["agent", "fuel", "transaction"];

/**
 * @member {module:models/Agent} agent
 */
RefuelShip200ResponseData.prototype['agent'] = undefined;

/**
 * @member {module:models/ShipFuel} fuel
 */
RefuelShip200ResponseData.prototype['fuel'] = undefined;

/**
 * @member {module:models/MarketTransaction} transaction
 */
RefuelShip200ResponseData.prototype['transaction'] = undefined;






export default RefuelShip200ResponseData;

