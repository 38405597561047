/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class FactionSymbol.
* @enum {}
* @readonly
*/
export default class FactionSymbol {
    
        /**
         * value: "COSMIC"
         * @const
         */
        "COSMIC" = "COSMIC";

    
        /**
         * value: "VOID"
         * @const
         */
        "VOID" = "VOID";

    
        /**
         * value: "GALACTIC"
         * @const
         */
        "GALACTIC" = "GALACTIC";

    
        /**
         * value: "QUANTUM"
         * @const
         */
        "QUANTUM" = "QUANTUM";

    
        /**
         * value: "DOMINION"
         * @const
         */
        "DOMINION" = "DOMINION";

    
        /**
         * value: "ASTRO"
         * @const
         */
        "ASTRO" = "ASTRO";

    
        /**
         * value: "CORSAIRS"
         * @const
         */
        "CORSAIRS" = "CORSAIRS";

    
        /**
         * value: "OBSIDIAN"
         * @const
         */
        "OBSIDIAN" = "OBSIDIAN";

    
        /**
         * value: "AEGIS"
         * @const
         */
        "AEGIS" = "AEGIS";

    
        /**
         * value: "UNITED"
         * @const
         */
        "UNITED" = "UNITED";

    
        /**
         * value: "SOLITARY"
         * @const
         */
        "SOLITARY" = "SOLITARY";

    
        /**
         * value: "COBALT"
         * @const
         */
        "COBALT" = "COBALT";

    
        /**
         * value: "OMEGA"
         * @const
         */
        "OMEGA" = "OMEGA";

    
        /**
         * value: "ECHO"
         * @const
         */
        "ECHO" = "ECHO";

    
        /**
         * value: "LORDS"
         * @const
         */
        "LORDS" = "LORDS";

    
        /**
         * value: "CULT"
         * @const
         */
        "CULT" = "CULT";

    
        /**
         * value: "ANCIENTS"
         * @const
         */
        "ANCIENTS" = "ANCIENTS";

    
        /**
         * value: "SHADOW"
         * @const
         */
        "SHADOW" = "SHADOW";

    
        /**
         * value: "ETHEREAL"
         * @const
         */
        "ETHEREAL" = "ETHEREAL";

    

    /**
    * Returns a <code>FactionSymbol</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/FactionSymbol} The enum <code>FactionSymbol</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

