/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ActivityLevel from './ActivityLevel';
import SupplyLevel from './SupplyLevel';
import TradeSymbol from './TradeSymbol';

/**
 * The MarketTradeGood model module.
 * @module models/MarketTradeGood
 * @version 2.0.0
 */
class MarketTradeGood {
    /**
     * Constructs a new <code>MarketTradeGood</code>.
     * @alias module:models/MarketTradeGood
     * @param symbol {module:models/TradeSymbol} 
     * @param type {module:models/MarketTradeGood.TypeEnum} The type of trade good (export, import, or exchange).
     * @param tradeVolume {Number} This is the maximum number of units that can be purchased or sold at this market in a single trade for this good. Trade volume also gives an indication of price volatility. A market with a low trade volume will have large price swings, while high trade volume will be more resilient to price changes.
     * @param supply {module:models/SupplyLevel} 
     * @param purchasePrice {Number} The price at which this good can be purchased from the market.
     * @param sellPrice {Number} The price at which this good can be sold to the market.
     */
    constructor(symbol, type, tradeVolume, supply, purchasePrice, sellPrice) { 
        
        MarketTradeGood.initialize(this, symbol, type, tradeVolume, supply, purchasePrice, sellPrice);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, type, tradeVolume, supply, purchasePrice, sellPrice) { 
        obj['symbol'] = symbol;
        obj['type'] = type;
        obj['tradeVolume'] = tradeVolume;
        obj['supply'] = supply;
        obj['purchasePrice'] = purchasePrice;
        obj['sellPrice'] = sellPrice;
    }

    /**
     * Constructs a <code>MarketTradeGood</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/MarketTradeGood} obj Optional instance to populate.
     * @return {module:models/MarketTradeGood} The populated <code>MarketTradeGood</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MarketTradeGood();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = TradeSymbol.constructFromObject(data['symbol']);
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
            if (data.hasOwnProperty('tradeVolume')) {
                obj['tradeVolume'] = ApiClient.convertToType(data['tradeVolume'], 'Number');
            }
            if (data.hasOwnProperty('supply')) {
                obj['supply'] = SupplyLevel.constructFromObject(data['supply']);
            }
            if (data.hasOwnProperty('activity')) {
                obj['activity'] = ActivityLevel.constructFromObject(data['activity']);
            }
            if (data.hasOwnProperty('purchasePrice')) {
                obj['purchasePrice'] = ApiClient.convertToType(data['purchasePrice'], 'Number');
            }
            if (data.hasOwnProperty('sellPrice')) {
                obj['sellPrice'] = ApiClient.convertToType(data['sellPrice'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>MarketTradeGood</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>MarketTradeGood</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of MarketTradeGood.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['type'] && !(typeof data['type'] === 'string' || data['type'] instanceof String)) {
            throw new Error("Expected the field `type` to be a primitive type in the JSON string but got " + data['type']);
        }

        return true;
    }


}

MarketTradeGood.RequiredProperties = ["symbol", "type", "tradeVolume", "supply", "purchasePrice", "sellPrice"];

/**
 * @member {module:models/TradeSymbol} symbol
 */
MarketTradeGood.prototype['symbol'] = undefined;

/**
 * The type of trade good (export, import, or exchange).
 * @member {module:models/MarketTradeGood.TypeEnum} type
 */
MarketTradeGood.prototype['type'] = undefined;

/**
 * This is the maximum number of units that can be purchased or sold at this market in a single trade for this good. Trade volume also gives an indication of price volatility. A market with a low trade volume will have large price swings, while high trade volume will be more resilient to price changes.
 * @member {Number} tradeVolume
 */
MarketTradeGood.prototype['tradeVolume'] = undefined;

/**
 * @member {module:models/SupplyLevel} supply
 */
MarketTradeGood.prototype['supply'] = undefined;

/**
 * @member {module:models/ActivityLevel} activity
 */
MarketTradeGood.prototype['activity'] = undefined;

/**
 * The price at which this good can be purchased from the market.
 * @member {Number} purchasePrice
 */
MarketTradeGood.prototype['purchasePrice'] = undefined;

/**
 * The price at which this good can be sold to the market.
 * @member {Number} sellPrice
 */
MarketTradeGood.prototype['sellPrice'] = undefined;





/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
MarketTradeGood['TypeEnum'] = {

    /**
     * value: "EXPORT"
     * @const
     */
    "EXPORT": "EXPORT",

    /**
     * value: "IMPORT"
     * @const
     */
    "IMPORT": "IMPORT",

    /**
     * value: "EXCHANGE"
     * @const
     */
    "EXCHANGE": "EXCHANGE"
};



export default MarketTradeGood;

