/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import WaypointTraitSymbol from './WaypointTraitSymbol';

/**
 * The GetSystemWaypointsTraitsParameter model module.
 * @module models/GetSystemWaypointsTraitsParameter
 * @version 2.0.0
 */
class GetSystemWaypointsTraitsParameter {
    /**
     * Constructs a new <code>GetSystemWaypointsTraitsParameter</code>.
     * @alias module:models/GetSystemWaypointsTraitsParameter
     * @param {(module:models/WaypointTraitSymbol|module:models/[WaypointTraitSymbol])} instance The actual instance to initialize GetSystemWaypointsTraitsParameter.
     */
    constructor(instance = null) {
        if (instance === null) {
            this.actualInstance = null;
            return;
        }
        var match = 0;
        var errorMessages = [];
        try {
            if (typeof instance === "WaypointTraitSymbol") {
                this.actualInstance = instance;
            } else {
                // plain JS object
                // validate the object
                WaypointTraitSymbol.validateJSON(instance); // throw an exception if no match
                // create WaypointTraitSymbol from JS object
                this.actualInstance = WaypointTraitSymbol.constructFromObject(instance);
            }
            match++;
        } catch(err) {
            // json data failed to deserialize into WaypointTraitSymbol
            errorMessages.push("Failed to construct WaypointTraitSymbol: " + err)
        }

        try {
            if (typeof instance === "[WaypointTraitSymbol]") {
                this.actualInstance = instance;
            } else {
                // plain JS object
                // validate the object
                [WaypointTraitSymbol].validateJSON(instance); // throw an exception if no match
                // create [WaypointTraitSymbol] from JS object
                this.actualInstance = [WaypointTraitSymbol].constructFromObject(instance);
            }
            match++;
        } catch(err) {
            // json data failed to deserialize into [WaypointTraitSymbol]
            errorMessages.push("Failed to construct [WaypointTraitSymbol]: " + err)
        }

        if (match > 1) {
            throw new Error("Multiple matches found constructing `GetSystemWaypointsTraitsParameter` with oneOf schemas WaypointTraitSymbol, [WaypointTraitSymbol]. Input: " + JSON.stringify(instance));
        } else if (match === 0) {
            this.actualInstance = null; // clear the actual instance in case there are multiple matches
            throw new Error("No match found constructing `GetSystemWaypointsTraitsParameter` with oneOf schemas WaypointTraitSymbol, [WaypointTraitSymbol]. Details: " +
                            errorMessages.join(", "));
        } else { // only 1 match
            // the input is valid
        }
    }

    /**
     * Constructs a <code>GetSystemWaypointsTraitsParameter</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/GetSystemWaypointsTraitsParameter} obj Optional instance to populate.
     * @return {module:models/GetSystemWaypointsTraitsParameter} The populated <code>GetSystemWaypointsTraitsParameter</code> instance.
     */
    static constructFromObject(data, obj) {
        return new GetSystemWaypointsTraitsParameter(data);
    }

    /**
     * Gets the actual instance, which can be <code>WaypointTraitSymbol</code>, <code>[WaypointTraitSymbol]</code>.
     * @return {(module:models/WaypointTraitSymbol|module:models/[WaypointTraitSymbol])} The actual instance.
     */
    getActualInstance() {
        return this.actualInstance;
    }

    /**
     * Sets the actual instance, which can be <code>WaypointTraitSymbol</code>, <code>[WaypointTraitSymbol]</code>.
     * @param {(module:models/WaypointTraitSymbol|module:models/[WaypointTraitSymbol])} obj The actual instance.
     */
    setActualInstance(obj) {
       this.actualInstance = GetSystemWaypointsTraitsParameter.constructFromObject(obj).getActualInstance();
    }

    /**
     * Returns the JSON representation of the actual instance.
     * @return {string}
     */
    toJSON = function(){
        return this.getActualInstance();
    }

    /**
     * Create an instance of GetSystemWaypointsTraitsParameter from a JSON string.
     * @param {string} json_string JSON string.
     * @return {module:models/GetSystemWaypointsTraitsParameter} An instance of GetSystemWaypointsTraitsParameter.
     */
    static fromJSON = function(json_string){
        return GetSystemWaypointsTraitsParameter.constructFromObject(JSON.parse(json_string));
    }
}


GetSystemWaypointsTraitsParameter.OneOf = ["WaypointTraitSymbol", "[WaypointTraitSymbol]"];

export default GetSystemWaypointsTraitsParameter;

