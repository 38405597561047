<!-- MovableContainer.vue -->
<template>
  <div  class="movable-container"
        ref="container"
        @mousemove="onMouseMove"
        @mouseup="onMouseUp"
        @mouseleave="onMouseLeave"
        @mouseenter="onMouseEnter"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      movingItem: null, // Track the item being moved
      offsetX: 0,
      offsetY: 0,
      hoveredContainer: null, // Track the hovered container
    };
  },
  methods: {
    // this is called dynamically, hence why there are no references found
    onMouseDown(event, item) {
      this.movingItem = item;
      const container = this.$refs.container;
      const containerRect = container.getBoundingClientRect();

      // Calculate offset between the mouse position and item's position
      this.offsetX = event.clientX - /*containerRect.left -*/ item.x;
      this.offsetY = event.clientY - /*containerRect.top -*/ item.y;

      // Debugging offsets
      console.log({
        mouseX: event.clientX,
        mouseY: event.clientY,
        itemX: item.x,
        itemY: item.y,
        containerLeft: containerRect.left,
        containerTop: containerRect.top,
        offsetX: this.offsetX,
        offsetY: this.offsetY,
      });
    },
    onMouseMove(event) {
      if (this.movingItem) {
        const item = this.movingItem;
        const constrainToParent = item.constrainToParent;
        const container = this.$refs.container;
        const containerRect = container.getBoundingClientRect();

        // Calculate new position based on mouse movement
        let x = event.clientX - this.offsetX;
        let y = event.clientY - this.offsetY;

        if (constrainToParent) {
          const itemWidth = parseFloat(item.width) || 150; // Default width
          const itemHeight = parseFloat(item.height) || 100; // Default height

          // Ensure the item stays within bounds of the container
          x = Math.max(0, Math.min(containerRect.width - itemWidth, x));
          y = Math.max(0, Math.min(containerRect.height - itemHeight, y));
        }

        // Update item position
        item.x = x;
        item.y = y;

        // Debugging the computed positions
        console.log({
          mouseX: event.clientX,
          mouseY: event.clientY,
          containerLeft: containerRect.left,
          containerTop: containerRect.top,
          computedX: x,
          computedY: y,
          itemY: item.y,
          containerRectTop: containerRect.top,
        });
      }
    },
    onMouseUp() {
      if (this.movingItem) {
        // Check if the item has moved to another container
        if (this.hoveredContainer && this.hoveredContainer !== this.$refs.container) {
          this.hoveredContainer.appendChild(this.movingItem);
        }
        // Save the position to localStorage
        localStorage.setItem(`dashboard-${this.movingItem.id}`, JSON.stringify({ x: this.movingItem.x, y: this.movingItem.y }));

        // Finalize the drag
        this.movingItem = null;
        this.hoveredContainer = null;
      }
    },
    onMouseEnter() {
      this.hoveredContainer = this.$refs.container;
    },
    onMouseLeave() {
      this.hoveredContainer = null;
    },
  },
};
</script>

<style scoped>
.movable-container {
  position: relative;
  height: 40vh;
  width: 100%;
  border: 2px dashed rosybrown;
  border-radius: 15px;
}
</style>
