/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GetStatus200ResponseStats model module.
 * @module models/GetStatus200ResponseStats
 * @version 2.0.0
 */
class GetStatus200ResponseStats {
    /**
     * Constructs a new <code>GetStatus200ResponseStats</code>.
     * @alias module:models/GetStatus200ResponseStats
     * @param agents {Number} Number of registered agents in the game.
     * @param ships {Number} Total number of ships in the game.
     * @param systems {Number} Total number of systems in the game.
     * @param waypoints {Number} Total number of waypoints in the game.
     */
    constructor(agents, ships, systems, waypoints) { 
        
        GetStatus200ResponseStats.initialize(this, agents, ships, systems, waypoints);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, agents, ships, systems, waypoints) { 
        obj['agents'] = agents;
        obj['ships'] = ships;
        obj['systems'] = systems;
        obj['waypoints'] = waypoints;
    }

    /**
     * Constructs a <code>GetStatus200ResponseStats</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/GetStatus200ResponseStats} obj Optional instance to populate.
     * @return {module:models/GetStatus200ResponseStats} The populated <code>GetStatus200ResponseStats</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetStatus200ResponseStats();

            if (data.hasOwnProperty('agents')) {
                obj['agents'] = ApiClient.convertToType(data['agents'], 'Number');
            }
            if (data.hasOwnProperty('ships')) {
                obj['ships'] = ApiClient.convertToType(data['ships'], 'Number');
            }
            if (data.hasOwnProperty('systems')) {
                obj['systems'] = ApiClient.convertToType(data['systems'], 'Number');
            }
            if (data.hasOwnProperty('waypoints')) {
                obj['waypoints'] = ApiClient.convertToType(data['waypoints'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GetStatus200ResponseStats</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetStatus200ResponseStats</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of GetStatus200ResponseStats.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

GetStatus200ResponseStats.RequiredProperties = ["agents", "ships", "systems", "waypoints"];

/**
 * Number of registered agents in the game.
 * @member {Number} agents
 */
GetStatus200ResponseStats.prototype['agents'] = undefined;

/**
 * Total number of ships in the game.
 * @member {Number} ships
 */
GetStatus200ResponseStats.prototype['ships'] = undefined;

/**
 * Total number of systems in the game.
 * @member {Number} systems
 */
GetStatus200ResponseStats.prototype['systems'] = undefined;

/**
 * Total number of waypoints in the game.
 * @member {Number} waypoints
 */
GetStatus200ResponseStats.prototype['waypoints'] = undefined;






export default GetStatus200ResponseStats;

