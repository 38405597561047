/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ShipConditionEvent model module.
 * @module models/ShipConditionEvent
 * @version 2.0.0
 */
class ShipConditionEvent {
    /**
     * Constructs a new <code>ShipConditionEvent</code>.
     * An event that represents damage or wear to a ship&#39;s reactor, frame, or engine, reducing the condition of the ship.
     * @alias module:models/ShipConditionEvent
     * @param symbol {module:models/ShipConditionEvent.SymbolEnum} 
     * @param component {module:models/ShipConditionEvent.ComponentEnum} 
     * @param name {String} The name of the event.
     * @param description {String} A description of the event.
     */
    constructor(symbol, component, name, description) { 
        
        ShipConditionEvent.initialize(this, symbol, component, name, description);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, component, name, description) { 
        obj['symbol'] = symbol;
        obj['component'] = component;
        obj['name'] = name;
        obj['description'] = description;
    }

    /**
     * Constructs a <code>ShipConditionEvent</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipConditionEvent} obj Optional instance to populate.
     * @return {module:models/ShipConditionEvent} The populated <code>ShipConditionEvent</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipConditionEvent();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('component')) {
                obj['component'] = ApiClient.convertToType(data['component'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipConditionEvent</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipConditionEvent</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipConditionEvent.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['component'] && !(typeof data['component'] === 'string' || data['component'] instanceof String)) {
            throw new Error("Expected the field `component` to be a primitive type in the JSON string but got " + data['component']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }

        return true;
    }


}

ShipConditionEvent.RequiredProperties = ["symbol", "component", "name", "description"];

/**
 * @member {module:models/ShipConditionEvent.SymbolEnum} symbol
 */
ShipConditionEvent.prototype['symbol'] = undefined;

/**
 * @member {module:models/ShipConditionEvent.ComponentEnum} component
 */
ShipConditionEvent.prototype['component'] = undefined;

/**
 * The name of the event.
 * @member {String} name
 */
ShipConditionEvent.prototype['name'] = undefined;

/**
 * A description of the event.
 * @member {String} description
 */
ShipConditionEvent.prototype['description'] = undefined;





/**
 * Allowed values for the <code>symbol</code> property.
 * @enum {String}
 * @readonly
 */
ShipConditionEvent['SymbolEnum'] = {

    /**
     * value: "REACTOR_OVERLOAD"
     * @const
     */
    "REACTOR_OVERLOAD": "REACTOR_OVERLOAD",

    /**
     * value: "ENERGY_SPIKE_FROM_MINERAL"
     * @const
     */
    "ENERGY_SPIKE_FROM_MINERAL": "ENERGY_SPIKE_FROM_MINERAL",

    /**
     * value: "SOLAR_FLARE_INTERFERENCE"
     * @const
     */
    "SOLAR_FLARE_INTERFERENCE": "SOLAR_FLARE_INTERFERENCE",

    /**
     * value: "COOLANT_LEAK"
     * @const
     */
    "COOLANT_LEAK": "COOLANT_LEAK",

    /**
     * value: "POWER_DISTRIBUTION_FLUCTUATION"
     * @const
     */
    "POWER_DISTRIBUTION_FLUCTUATION": "POWER_DISTRIBUTION_FLUCTUATION",

    /**
     * value: "MAGNETIC_FIELD_DISRUPTION"
     * @const
     */
    "MAGNETIC_FIELD_DISRUPTION": "MAGNETIC_FIELD_DISRUPTION",

    /**
     * value: "HULL_MICROMETEORITE_STRIKES"
     * @const
     */
    "HULL_MICROMETEORITE_STRIKES": "HULL_MICROMETEORITE_STRIKES",

    /**
     * value: "STRUCTURAL_STRESS_FRACTURES"
     * @const
     */
    "STRUCTURAL_STRESS_FRACTURES": "STRUCTURAL_STRESS_FRACTURES",

    /**
     * value: "CORROSIVE_MINERAL_CONTAMINATION"
     * @const
     */
    "CORROSIVE_MINERAL_CONTAMINATION": "CORROSIVE_MINERAL_CONTAMINATION",

    /**
     * value: "THERMAL_EXPANSION_MISMATCH"
     * @const
     */
    "THERMAL_EXPANSION_MISMATCH": "THERMAL_EXPANSION_MISMATCH",

    /**
     * value: "VIBRATION_DAMAGE_FROM_DRILLING"
     * @const
     */
    "VIBRATION_DAMAGE_FROM_DRILLING": "VIBRATION_DAMAGE_FROM_DRILLING",

    /**
     * value: "ELECTROMAGNETIC_FIELD_INTERFERENCE"
     * @const
     */
    "ELECTROMAGNETIC_FIELD_INTERFERENCE": "ELECTROMAGNETIC_FIELD_INTERFERENCE",

    /**
     * value: "IMPACT_WITH_EXTRACTED_DEBRIS"
     * @const
     */
    "IMPACT_WITH_EXTRACTED_DEBRIS": "IMPACT_WITH_EXTRACTED_DEBRIS",

    /**
     * value: "FUEL_EFFICIENCY_DEGRADATION"
     * @const
     */
    "FUEL_EFFICIENCY_DEGRADATION": "FUEL_EFFICIENCY_DEGRADATION",

    /**
     * value: "COOLANT_SYSTEM_AGEING"
     * @const
     */
    "COOLANT_SYSTEM_AGEING": "COOLANT_SYSTEM_AGEING",

    /**
     * value: "DUST_MICROABRASIONS"
     * @const
     */
    "DUST_MICROABRASIONS": "DUST_MICROABRASIONS",

    /**
     * value: "THRUSTER_NOZZLE_WEAR"
     * @const
     */
    "THRUSTER_NOZZLE_WEAR": "THRUSTER_NOZZLE_WEAR",

    /**
     * value: "EXHAUST_PORT_CLOGGING"
     * @const
     */
    "EXHAUST_PORT_CLOGGING": "EXHAUST_PORT_CLOGGING",

    /**
     * value: "BEARING_LUBRICATION_FADE"
     * @const
     */
    "BEARING_LUBRICATION_FADE": "BEARING_LUBRICATION_FADE",

    /**
     * value: "SENSOR_CALIBRATION_DRIFT"
     * @const
     */
    "SENSOR_CALIBRATION_DRIFT": "SENSOR_CALIBRATION_DRIFT",

    /**
     * value: "HULL_MICROMETEORITE_DAMAGE"
     * @const
     */
    "HULL_MICROMETEORITE_DAMAGE": "HULL_MICROMETEORITE_DAMAGE",

    /**
     * value: "SPACE_DEBRIS_COLLISION"
     * @const
     */
    "SPACE_DEBRIS_COLLISION": "SPACE_DEBRIS_COLLISION",

    /**
     * value: "THERMAL_STRESS"
     * @const
     */
    "THERMAL_STRESS": "THERMAL_STRESS",

    /**
     * value: "VIBRATION_OVERLOAD"
     * @const
     */
    "VIBRATION_OVERLOAD": "VIBRATION_OVERLOAD",

    /**
     * value: "PRESSURE_DIFFERENTIAL_STRESS"
     * @const
     */
    "PRESSURE_DIFFERENTIAL_STRESS": "PRESSURE_DIFFERENTIAL_STRESS",

    /**
     * value: "ELECTROMAGNETIC_SURGE_EFFECTS"
     * @const
     */
    "ELECTROMAGNETIC_SURGE_EFFECTS": "ELECTROMAGNETIC_SURGE_EFFECTS",

    /**
     * value: "ATMOSPHERIC_ENTRY_HEAT"
     * @const
     */
    "ATMOSPHERIC_ENTRY_HEAT": "ATMOSPHERIC_ENTRY_HEAT"
};


/**
 * Allowed values for the <code>component</code> property.
 * @enum {String}
 * @readonly
 */
ShipConditionEvent['ComponentEnum'] = {

    /**
     * value: "FRAME"
     * @const
     */
    "FRAME": "FRAME",

    /**
     * value: "REACTOR"
     * @const
     */
    "REACTOR": "REACTOR",

    /**
     * value: "ENGINE"
     * @const
     */
    "ENGINE": "ENGINE"
};



export default ShipConditionEvent;

