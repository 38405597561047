/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import GetStatus200ResponseAnnouncementsInner from './GetStatus200ResponseAnnouncementsInner';
import GetStatus200ResponseLeaderboards from './GetStatus200ResponseLeaderboards';
import GetStatus200ResponseLinksInner from './GetStatus200ResponseLinksInner';
import GetStatus200ResponseServerResets from './GetStatus200ResponseServerResets';
import GetStatus200ResponseStats from './GetStatus200ResponseStats';

/**
 * The GetStatus200Response model module.
 * @module models/GetStatus200Response
 * @version 2.0.0
 */
class GetStatus200Response {
    /**
     * Constructs a new <code>GetStatus200Response</code>.
     * @alias module:models/GetStatus200Response
     * @param status {String} The current status of the game server.
     * @param version {String} The current version of the API.
     * @param resetDate {String} The date when the game server was last reset.
     * @param description {String} 
     * @param stats {module:models/GetStatus200ResponseStats} 
     * @param leaderboards {module:models/GetStatus200ResponseLeaderboards} 
     * @param serverResets {module:models/GetStatus200ResponseServerResets} 
     * @param announcements {Array.<module:models/GetStatus200ResponseAnnouncementsInner>} 
     * @param links {Array.<module:models/GetStatus200ResponseLinksInner>} 
     */
    constructor(status, version, resetDate, description, stats, leaderboards, serverResets, announcements, links) { 
        
        GetStatus200Response.initialize(this, status, version, resetDate, description, stats, leaderboards, serverResets, announcements, links);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, status, version, resetDate, description, stats, leaderboards, serverResets, announcements, links) { 
        obj['status'] = status;
        obj['version'] = version;
        obj['resetDate'] = resetDate;
        obj['description'] = description;
        obj['stats'] = stats;
        obj['leaderboards'] = leaderboards;
        obj['serverResets'] = serverResets;
        obj['announcements'] = announcements;
        obj['links'] = links;
    }

    /**
     * Constructs a <code>GetStatus200Response</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/GetStatus200Response} obj Optional instance to populate.
     * @return {module:models/GetStatus200Response} The populated <code>GetStatus200Response</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetStatus200Response();

            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'String');
            }
            if (data.hasOwnProperty('resetDate')) {
                obj['resetDate'] = ApiClient.convertToType(data['resetDate'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('stats')) {
                obj['stats'] = GetStatus200ResponseStats.constructFromObject(data['stats']);
            }
            if (data.hasOwnProperty('leaderboards')) {
                obj['leaderboards'] = GetStatus200ResponseLeaderboards.constructFromObject(data['leaderboards']);
            }
            if (data.hasOwnProperty('serverResets')) {
                obj['serverResets'] = GetStatus200ResponseServerResets.constructFromObject(data['serverResets']);
            }
            if (data.hasOwnProperty('announcements')) {
                obj['announcements'] = ApiClient.convertToType(data['announcements'], [GetStatus200ResponseAnnouncementsInner]);
            }
            if (data.hasOwnProperty('links')) {
                obj['links'] = ApiClient.convertToType(data['links'], [GetStatus200ResponseLinksInner]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GetStatus200Response</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetStatus200Response</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of GetStatus200Response.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['status'] && !(typeof data['status'] === 'string' || data['status'] instanceof String)) {
            throw new Error("Expected the field `status` to be a primitive type in the JSON string but got " + data['status']);
        }
        // ensure the json data is a string
        if (data['version'] && !(typeof data['version'] === 'string' || data['version'] instanceof String)) {
            throw new Error("Expected the field `version` to be a primitive type in the JSON string but got " + data['version']);
        }
        // ensure the json data is a string
        if (data['resetDate'] && !(typeof data['resetDate'] === 'string' || data['resetDate'] instanceof String)) {
            throw new Error("Expected the field `resetDate` to be a primitive type in the JSON string but got " + data['resetDate']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // validate the optional field `stats`
        if (data['stats']) { // data not null
          GetStatus200ResponseStats.validateJSON(data['stats']);
        }
        // validate the optional field `leaderboards`
        if (data['leaderboards']) { // data not null
          GetStatus200ResponseLeaderboards.validateJSON(data['leaderboards']);
        }
        // validate the optional field `serverResets`
        if (data['serverResets']) { // data not null
          GetStatus200ResponseServerResets.validateJSON(data['serverResets']);
        }
        if (data['announcements']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['announcements'])) {
                throw new Error("Expected the field `announcements` to be an array in the JSON data but got " + data['announcements']);
            }
            // validate the optional field `announcements` (array)
            for (const item of data['announcements']) {
                GetStatus200ResponseAnnouncementsInner.validateJSON(item);
            };
        }
        if (data['links']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['links'])) {
                throw new Error("Expected the field `links` to be an array in the JSON data but got " + data['links']);
            }
            // validate the optional field `links` (array)
            for (const item of data['links']) {
                GetStatus200ResponseLinksInner.validateJSON(item);
            };
        }

        return true;
    }


}

GetStatus200Response.RequiredProperties = ["status", "version", "resetDate", "description", "stats", "leaderboards", "serverResets", "announcements", "links"];

/**
 * The current status of the game server.
 * @member {String} status
 */
GetStatus200Response.prototype['status'] = undefined;

/**
 * The current version of the API.
 * @member {String} version
 */
GetStatus200Response.prototype['version'] = undefined;

/**
 * The date when the game server was last reset.
 * @member {String} resetDate
 */
GetStatus200Response.prototype['resetDate'] = undefined;

/**
 * @member {String} description
 */
GetStatus200Response.prototype['description'] = undefined;

/**
 * @member {module:models/GetStatus200ResponseStats} stats
 */
GetStatus200Response.prototype['stats'] = undefined;

/**
 * @member {module:models/GetStatus200ResponseLeaderboards} leaderboards
 */
GetStatus200Response.prototype['leaderboards'] = undefined;

/**
 * @member {module:models/GetStatus200ResponseServerResets} serverResets
 */
GetStatus200Response.prototype['serverResets'] = undefined;

/**
 * @member {Array.<module:models/GetStatus200ResponseAnnouncementsInner>} announcements
 */
GetStatus200Response.prototype['announcements'] = undefined;

/**
 * @member {Array.<module:models/GetStatus200ResponseLinksInner>} links
 */
GetStatus200Response.prototype['links'] = undefined;






export default GetStatus200Response;

