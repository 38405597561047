/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipRequirements from './ShipRequirements';

/**
 * The ShipFrame model module.
 * @module models/ShipFrame
 * @version 2.0.0
 */
class ShipFrame {
    /**
     * Constructs a new <code>ShipFrame</code>.
     * The frame of the ship. The frame determines the number of modules and mounting points of the ship, as well as base fuel capacity. As the condition of the frame takes more wear, the ship will become more sluggish and less maneuverable.
     * @alias module:models/ShipFrame
     * @param symbol {module:models/ShipFrame.SymbolEnum} Symbol of the frame.
     * @param name {String} Name of the frame.
     * @param description {String} Description of the frame.
     * @param condition {Number} The repairable condition of a component. A value of 0 indicates the component needs significant repairs, while a value of 1 indicates the component is in near perfect condition. As the condition of a component is repaired, the overall integrity of the component decreases.
     * @param integrity {Number} The overall integrity of the component, which determines the performance of the component. A value of 0 indicates that the component is almost completely degraded, while a value of 1 indicates that the component is in near perfect condition. The integrity of the component is non-repairable, and represents permanent wear over time.
     * @param moduleSlots {Number} The amount of slots that can be dedicated to modules installed in the ship. Each installed module take up a number of slots, and once there are no more slots, no new modules can be installed.
     * @param mountingPoints {Number} The amount of slots that can be dedicated to mounts installed in the ship. Each installed mount takes up a number of points, and once there are no more points remaining, no new mounts can be installed.
     * @param fuelCapacity {Number} The maximum amount of fuel that can be stored in this ship. When refueling, the ship will be refueled to this amount.
     * @param requirements {module:models/ShipRequirements} 
     */
    constructor(symbol, name, description, condition, integrity, moduleSlots, mountingPoints, fuelCapacity, requirements) { 
        
        ShipFrame.initialize(this, symbol, name, description, condition, integrity, moduleSlots, mountingPoints, fuelCapacity, requirements);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, name, description, condition, integrity, moduleSlots, mountingPoints, fuelCapacity, requirements) { 
        obj['symbol'] = symbol;
        obj['name'] = name;
        obj['description'] = description;
        obj['condition'] = condition;
        obj['integrity'] = integrity;
        obj['moduleSlots'] = moduleSlots;
        obj['mountingPoints'] = mountingPoints;
        obj['fuelCapacity'] = fuelCapacity;
        obj['requirements'] = requirements;
    }

    /**
     * Constructs a <code>ShipFrame</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipFrame} obj Optional instance to populate.
     * @return {module:models/ShipFrame} The populated <code>ShipFrame</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipFrame();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('condition')) {
                obj['condition'] = ApiClient.convertToType(data['condition'], 'Number');
            }
            if (data.hasOwnProperty('integrity')) {
                obj['integrity'] = ApiClient.convertToType(data['integrity'], 'Number');
            }
            if (data.hasOwnProperty('moduleSlots')) {
                obj['moduleSlots'] = ApiClient.convertToType(data['moduleSlots'], 'Number');
            }
            if (data.hasOwnProperty('mountingPoints')) {
                obj['mountingPoints'] = ApiClient.convertToType(data['mountingPoints'], 'Number');
            }
            if (data.hasOwnProperty('fuelCapacity')) {
                obj['fuelCapacity'] = ApiClient.convertToType(data['fuelCapacity'], 'Number');
            }
            if (data.hasOwnProperty('requirements')) {
                obj['requirements'] = ShipRequirements.constructFromObject(data['requirements']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipFrame</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipFrame</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipFrame.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // validate the optional field `requirements`
        if (data['requirements']) { // data not null
          ShipRequirements.validateJSON(data['requirements']);
        }

        return true;
    }


}

ShipFrame.RequiredProperties = ["symbol", "name", "description", "condition", "integrity", "moduleSlots", "mountingPoints", "fuelCapacity", "requirements"];

/**
 * Symbol of the frame.
 * @member {module:models/ShipFrame.SymbolEnum} symbol
 */
ShipFrame.prototype['symbol'] = undefined;

/**
 * Name of the frame.
 * @member {String} name
 */
ShipFrame.prototype['name'] = undefined;

/**
 * Description of the frame.
 * @member {String} description
 */
ShipFrame.prototype['description'] = undefined;

/**
 * The repairable condition of a component. A value of 0 indicates the component needs significant repairs, while a value of 1 indicates the component is in near perfect condition. As the condition of a component is repaired, the overall integrity of the component decreases.
 * @member {Number} condition
 */
ShipFrame.prototype['condition'] = undefined;

/**
 * The overall integrity of the component, which determines the performance of the component. A value of 0 indicates that the component is almost completely degraded, while a value of 1 indicates that the component is in near perfect condition. The integrity of the component is non-repairable, and represents permanent wear over time.
 * @member {Number} integrity
 */
ShipFrame.prototype['integrity'] = undefined;

/**
 * The amount of slots that can be dedicated to modules installed in the ship. Each installed module take up a number of slots, and once there are no more slots, no new modules can be installed.
 * @member {Number} moduleSlots
 */
ShipFrame.prototype['moduleSlots'] = undefined;

/**
 * The amount of slots that can be dedicated to mounts installed in the ship. Each installed mount takes up a number of points, and once there are no more points remaining, no new mounts can be installed.
 * @member {Number} mountingPoints
 */
ShipFrame.prototype['mountingPoints'] = undefined;

/**
 * The maximum amount of fuel that can be stored in this ship. When refueling, the ship will be refueled to this amount.
 * @member {Number} fuelCapacity
 */
ShipFrame.prototype['fuelCapacity'] = undefined;

/**
 * @member {module:models/ShipRequirements} requirements
 */
ShipFrame.prototype['requirements'] = undefined;





/**
 * Allowed values for the <code>symbol</code> property.
 * @enum {String}
 * @readonly
 */
ShipFrame['SymbolEnum'] = {

    /**
     * value: "FRAME_PROBE"
     * @const
     */
    "PROBE": "FRAME_PROBE",

    /**
     * value: "FRAME_DRONE"
     * @const
     */
    "DRONE": "FRAME_DRONE",

    /**
     * value: "FRAME_INTERCEPTOR"
     * @const
     */
    "INTERCEPTOR": "FRAME_INTERCEPTOR",

    /**
     * value: "FRAME_RACER"
     * @const
     */
    "RACER": "FRAME_RACER",

    /**
     * value: "FRAME_FIGHTER"
     * @const
     */
    "FIGHTER": "FRAME_FIGHTER",

    /**
     * value: "FRAME_FRIGATE"
     * @const
     */
    "FRIGATE": "FRAME_FRIGATE",

    /**
     * value: "FRAME_SHUTTLE"
     * @const
     */
    "SHUTTLE": "FRAME_SHUTTLE",

    /**
     * value: "FRAME_EXPLORER"
     * @const
     */
    "EXPLORER": "FRAME_EXPLORER",

    /**
     * value: "FRAME_MINER"
     * @const
     */
    "MINER": "FRAME_MINER",

    /**
     * value: "FRAME_LIGHT_FREIGHTER"
     * @const
     */
    "LIGHT_FREIGHTER": "FRAME_LIGHT_FREIGHTER",

    /**
     * value: "FRAME_HEAVY_FREIGHTER"
     * @const
     */
    "HEAVY_FREIGHTER": "FRAME_HEAVY_FREIGHTER",

    /**
     * value: "FRAME_TRANSPORT"
     * @const
     */
    "TRANSPORT": "FRAME_TRANSPORT",

    /**
     * value: "FRAME_DESTROYER"
     * @const
     */
    "DESTROYER": "FRAME_DESTROYER",

    /**
     * value: "FRAME_CRUISER"
     * @const
     */
    "CRUISER": "FRAME_CRUISER",

    /**
     * value: "FRAME_CARRIER"
     * @const
     */
    "CARRIER": "FRAME_CARRIER"
};



export default ShipFrame;

