/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Agent model module.
 * @module models/Agent
 * @version 2.0.0
 */
class Agent {
    /**
     * Constructs a new <code>Agent</code>.
     * Agent details.
     * @alias module:models/Agent
     * @param symbol {String} Symbol of the agent.
     * @param headquarters {String} The headquarters of the agent.
     * @param credits {Number} The number of credits the agent has available. Credits can be negative if funds have been overdrawn.
     * @param startingFaction {String} The faction the agent started with.
     * @param shipCount {Number} How many ships are owned by the agent.
     */
    constructor(symbol, headquarters, credits, startingFaction, shipCount) { 
        
        Agent.initialize(this, symbol, headquarters, credits, startingFaction, shipCount);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, headquarters, credits, startingFaction, shipCount) { 
        obj['symbol'] = symbol;
        obj['headquarters'] = headquarters;
        obj['credits'] = credits;
        obj['startingFaction'] = startingFaction;
        obj['shipCount'] = shipCount;
    }

    /**
     * Constructs a <code>Agent</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/Agent} obj Optional instance to populate.
     * @return {module:models/Agent} The populated <code>Agent</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Agent();

            if (data.hasOwnProperty('accountId')) {
                obj['accountId'] = ApiClient.convertToType(data['accountId'], 'String');
            }
            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('headquarters')) {
                obj['headquarters'] = ApiClient.convertToType(data['headquarters'], 'String');
            }
            if (data.hasOwnProperty('credits')) {
                obj['credits'] = ApiClient.convertToType(data['credits'], 'Number');
            }
            if (data.hasOwnProperty('startingFaction')) {
                obj['startingFaction'] = ApiClient.convertToType(data['startingFaction'], 'String');
            }
            if (data.hasOwnProperty('shipCount')) {
                obj['shipCount'] = ApiClient.convertToType(data['shipCount'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Agent</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Agent</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Agent.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['accountId'] && !(typeof data['accountId'] === 'string' || data['accountId'] instanceof String)) {
            throw new Error("Expected the field `accountId` to be a primitive type in the JSON string but got " + data['accountId']);
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['headquarters'] && !(typeof data['headquarters'] === 'string' || data['headquarters'] instanceof String)) {
            throw new Error("Expected the field `headquarters` to be a primitive type in the JSON string but got " + data['headquarters']);
        }
        // ensure the json data is a string
        if (data['startingFaction'] && !(typeof data['startingFaction'] === 'string' || data['startingFaction'] instanceof String)) {
            throw new Error("Expected the field `startingFaction` to be a primitive type in the JSON string but got " + data['startingFaction']);
        }

        return true;
    }


}

Agent.RequiredProperties = ["symbol", "headquarters", "credits", "startingFaction", "shipCount"];

/**
 * Account ID that is tied to this agent. Only included on your own agent.
 * @member {String} accountId
 */
Agent.prototype['accountId'] = undefined;

/**
 * Symbol of the agent.
 * @member {String} symbol
 */
Agent.prototype['symbol'] = undefined;

/**
 * The headquarters of the agent.
 * @member {String} headquarters
 */
Agent.prototype['headquarters'] = undefined;

/**
 * The number of credits the agent has available. Credits can be negative if funds have been overdrawn.
 * @member {Number} credits
 */
Agent.prototype['credits'] = undefined;

/**
 * The faction the agent started with.
 * @member {String} startingFaction
 */
Agent.prototype['startingFaction'] = undefined;

/**
 * How many ships are owned by the agent.
 * @member {Number} shipCount
 */
Agent.prototype['shipCount'] = undefined;






export default Agent;

