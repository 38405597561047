/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipNavRouteWaypoint from './ShipNavRouteWaypoint';

/**
 * The ShipNavRoute model module.
 * @module models/ShipNavRoute
 * @version 2.0.0
 */
class ShipNavRoute {
    /**
     * Constructs a new <code>ShipNavRoute</code>.
     * The routing information for the ship&#39;s most recent transit or current location.
     * @alias module:models/ShipNavRoute
     * @param destination {module:models/ShipNavRouteWaypoint} 
     * @param origin {module:models/ShipNavRouteWaypoint} 
     * @param departureTime {Date} The date time of the ship's departure.
     * @param arrival {Date} The date time of the ship's arrival. If the ship is in-transit, this is the expected time of arrival.
     */
    constructor(destination, origin, departureTime, arrival) { 
        
        ShipNavRoute.initialize(this, destination, origin, departureTime, arrival);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, destination, origin, departureTime, arrival) { 
        obj['destination'] = destination;
        obj['origin'] = origin;
        obj['departureTime'] = departureTime;
        obj['arrival'] = arrival;
    }

    /**
     * Constructs a <code>ShipNavRoute</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipNavRoute} obj Optional instance to populate.
     * @return {module:models/ShipNavRoute} The populated <code>ShipNavRoute</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipNavRoute();

            if (data.hasOwnProperty('destination')) {
                obj['destination'] = ShipNavRouteWaypoint.constructFromObject(data['destination']);
            }
            if (data.hasOwnProperty('origin')) {
                obj['origin'] = ShipNavRouteWaypoint.constructFromObject(data['origin']);
            }
            if (data.hasOwnProperty('departureTime')) {
                obj['departureTime'] = ApiClient.convertToType(data['departureTime'], 'Date');
            }
            if (data.hasOwnProperty('arrival')) {
                obj['arrival'] = ApiClient.convertToType(data['arrival'], 'Date');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipNavRoute</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipNavRoute</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipNavRoute.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `destination`
        if (data['destination']) { // data not null
          ShipNavRouteWaypoint.validateJSON(data['destination']);
        }
        // validate the optional field `origin`
        if (data['origin']) { // data not null
          ShipNavRouteWaypoint.validateJSON(data['origin']);
        }

        return true;
    }


}

ShipNavRoute.RequiredProperties = ["destination", "origin", "departureTime", "arrival"];

/**
 * @member {module:models/ShipNavRouteWaypoint} destination
 */
ShipNavRoute.prototype['destination'] = undefined;

/**
 * @member {module:models/ShipNavRouteWaypoint} origin
 */
ShipNavRoute.prototype['origin'] = undefined;

/**
 * The date time of the ship's departure.
 * @member {Date} departureTime
 */
ShipNavRoute.prototype['departureTime'] = undefined;

/**
 * The date time of the ship's arrival. If the ship is in-transit, this is the expected time of arrival.
 * @member {Date} arrival
 */
ShipNavRoute.prototype['arrival'] = undefined;






export default ShipNavRoute;

