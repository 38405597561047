/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ShipCrew model module.
 * @module models/ShipCrew
 * @version 2.0.0
 */
class ShipCrew {
    /**
     * Constructs a new <code>ShipCrew</code>.
     * The ship&#39;s crew service and maintain the ship&#39;s systems and equipment.
     * @alias module:models/ShipCrew
     * @param current {Number} The current number of crew members on the ship.
     * @param required {Number} The minimum number of crew members required to maintain the ship.
     * @param capacity {Number} The maximum number of crew members the ship can support.
     * @param rotation {module:models/ShipCrew.RotationEnum} The rotation of crew shifts. A stricter shift improves the ship's performance. A more relaxed shift improves the crew's morale.
     * @param morale {Number} A rough measure of the crew's morale. A higher morale means the crew is happier and more productive. A lower morale means the ship is more prone to accidents.
     * @param wages {Number} The amount of credits per crew member paid per hour. Wages are paid when a ship docks at a civilized waypoint.
     */
    constructor(current, required, capacity, rotation, morale, wages) { 
        
        ShipCrew.initialize(this, current, required, capacity, rotation, morale, wages);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, current, required, capacity, rotation, morale, wages) { 
        obj['current'] = current;
        obj['required'] = required;
        obj['capacity'] = capacity;
        obj['rotation'] = rotation || 'STRICT';
        obj['morale'] = morale;
        obj['wages'] = wages;
    }

    /**
     * Constructs a <code>ShipCrew</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipCrew} obj Optional instance to populate.
     * @return {module:models/ShipCrew} The populated <code>ShipCrew</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipCrew();

            if (data.hasOwnProperty('current')) {
                obj['current'] = ApiClient.convertToType(data['current'], 'Number');
            }
            if (data.hasOwnProperty('required')) {
                obj['required'] = ApiClient.convertToType(data['required'], 'Number');
            }
            if (data.hasOwnProperty('capacity')) {
                obj['capacity'] = ApiClient.convertToType(data['capacity'], 'Number');
            }
            if (data.hasOwnProperty('rotation')) {
                obj['rotation'] = ApiClient.convertToType(data['rotation'], 'String');
            }
            if (data.hasOwnProperty('morale')) {
                obj['morale'] = ApiClient.convertToType(data['morale'], 'Number');
            }
            if (data.hasOwnProperty('wages')) {
                obj['wages'] = ApiClient.convertToType(data['wages'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipCrew</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipCrew</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipCrew.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['rotation'] && !(typeof data['rotation'] === 'string' || data['rotation'] instanceof String)) {
            throw new Error("Expected the field `rotation` to be a primitive type in the JSON string but got " + data['rotation']);
        }

        return true;
    }


}

ShipCrew.RequiredProperties = ["current", "required", "capacity", "rotation", "morale", "wages"];

/**
 * The current number of crew members on the ship.
 * @member {Number} current
 */
ShipCrew.prototype['current'] = undefined;

/**
 * The minimum number of crew members required to maintain the ship.
 * @member {Number} required
 */
ShipCrew.prototype['required'] = undefined;

/**
 * The maximum number of crew members the ship can support.
 * @member {Number} capacity
 */
ShipCrew.prototype['capacity'] = undefined;

/**
 * The rotation of crew shifts. A stricter shift improves the ship's performance. A more relaxed shift improves the crew's morale.
 * @member {module:models/ShipCrew.RotationEnum} rotation
 * @default 'STRICT'
 */
ShipCrew.prototype['rotation'] = 'STRICT';

/**
 * A rough measure of the crew's morale. A higher morale means the crew is happier and more productive. A lower morale means the ship is more prone to accidents.
 * @member {Number} morale
 */
ShipCrew.prototype['morale'] = undefined;

/**
 * The amount of credits per crew member paid per hour. Wages are paid when a ship docks at a civilized waypoint.
 * @member {Number} wages
 */
ShipCrew.prototype['wages'] = undefined;





/**
 * Allowed values for the <code>rotation</code> property.
 * @enum {String}
 * @readonly
 */
ShipCrew['RotationEnum'] = {

    /**
     * value: "STRICT"
     * @const
     */
    "STRICT": "STRICT",

    /**
     * value: "RELAXED"
     * @const
     */
    "RELAXED": "RELAXED"
};



export default ShipCrew;

