/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import WaypointModifierSymbol from './WaypointModifierSymbol';

/**
 * The WaypointModifier model module.
 * @module models/WaypointModifier
 * @version 2.0.0
 */
class WaypointModifier {
    /**
     * Constructs a new <code>WaypointModifier</code>.
     * @alias module:models/WaypointModifier
     * @param symbol {module:models/WaypointModifierSymbol} 
     * @param name {String} The name of the trait.
     * @param description {String} A description of the trait.
     */
    constructor(symbol, name, description) { 
        
        WaypointModifier.initialize(this, symbol, name, description);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, name, description) { 
        obj['symbol'] = symbol;
        obj['name'] = name;
        obj['description'] = description;
    }

    /**
     * Constructs a <code>WaypointModifier</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/WaypointModifier} obj Optional instance to populate.
     * @return {module:models/WaypointModifier} The populated <code>WaypointModifier</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WaypointModifier();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = WaypointModifierSymbol.constructFromObject(data['symbol']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>WaypointModifier</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>WaypointModifier</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of WaypointModifier.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }

        return true;
    }


}

WaypointModifier.RequiredProperties = ["symbol", "name", "description"];

/**
 * @member {module:models/WaypointModifierSymbol} symbol
 */
WaypointModifier.prototype['symbol'] = undefined;

/**
 * The name of the trait.
 * @member {String} name
 */
WaypointModifier.prototype['name'] = undefined;

/**
 * A description of the trait.
 * @member {String} description
 */
WaypointModifier.prototype['description'] = undefined;






export default WaypointModifier;

