<!-- MovableItem.vue -->
<template>
  <div
      class="movable-item"
      :style="{
      left: `${x}px`,
      top: `${y}px`,
      width: width || '150px',
      height: height || '100px'
    }"
      @mousedown="onMouseDown"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    width: { type: String, default: '150px' }, // default width if not provided
    height: { type: String, default: '100px' }, // default height if not provided
    remember: { type: Boolean, default: false },
    constrainToParent: { type: Boolean, default: false },
  },
  data() {
    return {
      x: 0,
      y: 0,
    };
  },
  created() {
    if(this.remember){
      // Load saved position from localStorage
      const savedPosition = localStorage.getItem(`dashboard-${this.id}`);
      if (savedPosition) {
        const { x, y } = JSON.parse(savedPosition);
        this.x = x;
        this.y = y;
      }
    }
  },
  methods: {
    onMouseDown(event) {
      console.log(event, this.x, this.y);
      this.$parent.onMouseDown(event, this);
    },
  },
};
</script>

<style scoped>
.movable-item {
  position: absolute;
  background-color: #4285f4;
  color: white;
  padding: 10px;
  cursor: move;
  user-select: none;
}
</style>
