/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The GetStatus200ResponseAnnouncementsInner model module.
 * @module models/GetStatus200ResponseAnnouncementsInner
 * @version 2.0.0
 */
class GetStatus200ResponseAnnouncementsInner {
    /**
     * Constructs a new <code>GetStatus200ResponseAnnouncementsInner</code>.
     * @alias module:models/GetStatus200ResponseAnnouncementsInner
     * @param title {String} 
     * @param body {String} 
     */
    constructor(title, body) { 
        
        GetStatus200ResponseAnnouncementsInner.initialize(this, title, body);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, title, body) { 
        obj['title'] = title;
        obj['body'] = body;
    }

    /**
     * Constructs a <code>GetStatus200ResponseAnnouncementsInner</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/GetStatus200ResponseAnnouncementsInner} obj Optional instance to populate.
     * @return {module:models/GetStatus200ResponseAnnouncementsInner} The populated <code>GetStatus200ResponseAnnouncementsInner</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetStatus200ResponseAnnouncementsInner();

            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('body')) {
                obj['body'] = ApiClient.convertToType(data['body'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GetStatus200ResponseAnnouncementsInner</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetStatus200ResponseAnnouncementsInner</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of GetStatus200ResponseAnnouncementsInner.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['title'] && !(typeof data['title'] === 'string' || data['title'] instanceof String)) {
            throw new Error("Expected the field `title` to be a primitive type in the JSON string but got " + data['title']);
        }
        // ensure the json data is a string
        if (data['body'] && !(typeof data['body'] === 'string' || data['body'] instanceof String)) {
            throw new Error("Expected the field `body` to be a primitive type in the JSON string but got " + data['body']);
        }

        return true;
    }


}

GetStatus200ResponseAnnouncementsInner.RequiredProperties = ["title", "body"];

/**
 * @member {String} title
 */
GetStatus200ResponseAnnouncementsInner.prototype['title'] = undefined;

/**
 * @member {String} body
 */
GetStatus200ResponseAnnouncementsInner.prototype['body'] = undefined;






export default GetStatus200ResponseAnnouncementsInner;

