/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Cooldown from './Cooldown';
import ShipCargo from './ShipCargo';
import ShipRefine201ResponseDataProducedInner from './ShipRefine201ResponseDataProducedInner';

/**
 * The ShipRefine201ResponseData model module.
 * @module models/ShipRefine201ResponseData
 * @version 2.0.0
 */
class ShipRefine201ResponseData {
    /**
     * Constructs a new <code>ShipRefine201ResponseData</code>.
     * @alias module:models/ShipRefine201ResponseData
     * @param cargo {module:models/ShipCargo} 
     * @param cooldown {module:models/Cooldown} 
     * @param produced {Array.<module:models/ShipRefine201ResponseDataProducedInner>} Goods that were produced by this refining process.
     * @param consumed {Array.<module:models/ShipRefine201ResponseDataProducedInner>} Goods that were consumed during this refining process.
     */
    constructor(cargo, cooldown, produced, consumed) { 
        
        ShipRefine201ResponseData.initialize(this, cargo, cooldown, produced, consumed);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, cargo, cooldown, produced, consumed) { 
        obj['cargo'] = cargo;
        obj['cooldown'] = cooldown;
        obj['produced'] = produced;
        obj['consumed'] = consumed;
    }

    /**
     * Constructs a <code>ShipRefine201ResponseData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipRefine201ResponseData} obj Optional instance to populate.
     * @return {module:models/ShipRefine201ResponseData} The populated <code>ShipRefine201ResponseData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipRefine201ResponseData();

            if (data.hasOwnProperty('cargo')) {
                obj['cargo'] = ShipCargo.constructFromObject(data['cargo']);
            }
            if (data.hasOwnProperty('cooldown')) {
                obj['cooldown'] = Cooldown.constructFromObject(data['cooldown']);
            }
            if (data.hasOwnProperty('produced')) {
                obj['produced'] = ApiClient.convertToType(data['produced'], [ShipRefine201ResponseDataProducedInner]);
            }
            if (data.hasOwnProperty('consumed')) {
                obj['consumed'] = ApiClient.convertToType(data['consumed'], [ShipRefine201ResponseDataProducedInner]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipRefine201ResponseData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipRefine201ResponseData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipRefine201ResponseData.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `cargo`
        if (data['cargo']) { // data not null
          ShipCargo.validateJSON(data['cargo']);
        }
        // validate the optional field `cooldown`
        if (data['cooldown']) { // data not null
          Cooldown.validateJSON(data['cooldown']);
        }
        if (data['produced']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['produced'])) {
                throw new Error("Expected the field `produced` to be an array in the JSON data but got " + data['produced']);
            }
            // validate the optional field `produced` (array)
            for (const item of data['produced']) {
                ShipRefine201ResponseDataProducedInner.validateJSON(item);
            };
        }
        if (data['consumed']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['consumed'])) {
                throw new Error("Expected the field `consumed` to be an array in the JSON data but got " + data['consumed']);
            }
            // validate the optional field `consumed` (array)
            for (const item of data['consumed']) {
                ShipRefine201ResponseDataProducedInner.validateJSON(item);
            };
        }

        return true;
    }


}

ShipRefine201ResponseData.RequiredProperties = ["cargo", "cooldown", "produced", "consumed"];

/**
 * @member {module:models/ShipCargo} cargo
 */
ShipRefine201ResponseData.prototype['cargo'] = undefined;

/**
 * @member {module:models/Cooldown} cooldown
 */
ShipRefine201ResponseData.prototype['cooldown'] = undefined;

/**
 * Goods that were produced by this refining process.
 * @member {Array.<module:models/ShipRefine201ResponseDataProducedInner>} produced
 */
ShipRefine201ResponseData.prototype['produced'] = undefined;

/**
 * Goods that were consumed during this refining process.
 * @member {Array.<module:models/ShipRefine201ResponseDataProducedInner>} consumed
 */
ShipRefine201ResponseData.prototype['consumed'] = undefined;






export default ShipRefine201ResponseData;

