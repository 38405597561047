/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Chart from './Chart';
import WaypointFaction from './WaypointFaction';
import WaypointModifier from './WaypointModifier';
import WaypointOrbital from './WaypointOrbital';
import WaypointTrait from './WaypointTrait';
import WaypointType from './WaypointType';

/**
 * The Waypoint model module.
 * @module models/Waypoint
 * @version 2.0.0
 */
class Waypoint {
    /**
     * Constructs a new <code>Waypoint</code>.
     * A waypoint is a location that ships can travel to such as a Planet, Moon or Space Station.
     * @alias module:models/Waypoint
     * @param symbol {String} The symbol of the waypoint.
     * @param type {module:models/WaypointType} 
     * @param systemSymbol {String} The symbol of the system.
     * @param x {Number} Relative position of the waypoint on the system's x axis. This is not an absolute position in the universe.
     * @param y {Number} Relative position of the waypoint on the system's y axis. This is not an absolute position in the universe.
     * @param orbitals {Array.<module:models/WaypointOrbital>} Waypoints that orbit this waypoint.
     * @param traits {Array.<module:models/WaypointTrait>} The traits of the waypoint.
     * @param isUnderConstruction {Boolean} True if the waypoint is under construction.
     */
    constructor(symbol, type, systemSymbol, x, y, orbitals, traits, isUnderConstruction) { 
        
        Waypoint.initialize(this, symbol, type, systemSymbol, x, y, orbitals, traits, isUnderConstruction);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, type, systemSymbol, x, y, orbitals, traits, isUnderConstruction) { 
        obj['symbol'] = symbol;
        obj['type'] = type;
        obj['systemSymbol'] = systemSymbol;
        obj['x'] = x;
        obj['y'] = y;
        obj['orbitals'] = orbitals;
        obj['traits'] = traits;
        obj['isUnderConstruction'] = isUnderConstruction;
    }

    /**
     * Constructs a <code>Waypoint</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/Waypoint} obj Optional instance to populate.
     * @return {module:models/Waypoint} The populated <code>Waypoint</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Waypoint();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = WaypointType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('systemSymbol')) {
                obj['systemSymbol'] = ApiClient.convertToType(data['systemSymbol'], 'String');
            }
            if (data.hasOwnProperty('x')) {
                obj['x'] = ApiClient.convertToType(data['x'], 'Number');
            }
            if (data.hasOwnProperty('y')) {
                obj['y'] = ApiClient.convertToType(data['y'], 'Number');
            }
            if (data.hasOwnProperty('orbitals')) {
                obj['orbitals'] = ApiClient.convertToType(data['orbitals'], [WaypointOrbital]);
            }
            if (data.hasOwnProperty('orbits')) {
                obj['orbits'] = ApiClient.convertToType(data['orbits'], 'String');
            }
            if (data.hasOwnProperty('faction')) {
                obj['faction'] = WaypointFaction.constructFromObject(data['faction']);
            }
            if (data.hasOwnProperty('traits')) {
                obj['traits'] = ApiClient.convertToType(data['traits'], [WaypointTrait]);
            }
            if (data.hasOwnProperty('modifiers')) {
                obj['modifiers'] = ApiClient.convertToType(data['modifiers'], [WaypointModifier]);
            }
            if (data.hasOwnProperty('chart')) {
                obj['chart'] = Chart.constructFromObject(data['chart']);
            }
            if (data.hasOwnProperty('isUnderConstruction')) {
                obj['isUnderConstruction'] = ApiClient.convertToType(data['isUnderConstruction'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Waypoint</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Waypoint</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Waypoint.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['systemSymbol'] && !(typeof data['systemSymbol'] === 'string' || data['systemSymbol'] instanceof String)) {
            throw new Error("Expected the field `systemSymbol` to be a primitive type in the JSON string but got " + data['systemSymbol']);
        }
        if (data['orbitals']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['orbitals'])) {
                throw new Error("Expected the field `orbitals` to be an array in the JSON data but got " + data['orbitals']);
            }
            // validate the optional field `orbitals` (array)
            for (const item of data['orbitals']) {
                WaypointOrbital.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['orbits'] && !(typeof data['orbits'] === 'string' || data['orbits'] instanceof String)) {
            throw new Error("Expected the field `orbits` to be a primitive type in the JSON string but got " + data['orbits']);
        }
        // validate the optional field `faction`
        if (data['faction']) { // data not null
          WaypointFaction.validateJSON(data['faction']);
        }
        if (data['traits']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['traits'])) {
                throw new Error("Expected the field `traits` to be an array in the JSON data but got " + data['traits']);
            }
            // validate the optional field `traits` (array)
            for (const item of data['traits']) {
                WaypointTrait.validateJSON(item);
            };
        }
        if (data['modifiers']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['modifiers'])) {
                throw new Error("Expected the field `modifiers` to be an array in the JSON data but got " + data['modifiers']);
            }
            // validate the optional field `modifiers` (array)
            for (const item of data['modifiers']) {
                WaypointModifier.validateJSON(item);
            };
        }
        // validate the optional field `chart`
        if (data['chart']) { // data not null
          Chart.validateJSON(data['chart']);
        }

        return true;
    }


}

Waypoint.RequiredProperties = ["symbol", "type", "systemSymbol", "x", "y", "orbitals", "traits", "isUnderConstruction"];

/**
 * The symbol of the waypoint.
 * @member {String} symbol
 */
Waypoint.prototype['symbol'] = undefined;

/**
 * @member {module:models/WaypointType} type
 */
Waypoint.prototype['type'] = undefined;

/**
 * The symbol of the system.
 * @member {String} systemSymbol
 */
Waypoint.prototype['systemSymbol'] = undefined;

/**
 * Relative position of the waypoint on the system's x axis. This is not an absolute position in the universe.
 * @member {Number} x
 */
Waypoint.prototype['x'] = undefined;

/**
 * Relative position of the waypoint on the system's y axis. This is not an absolute position in the universe.
 * @member {Number} y
 */
Waypoint.prototype['y'] = undefined;

/**
 * Waypoints that orbit this waypoint.
 * @member {Array.<module:models/WaypointOrbital>} orbitals
 */
Waypoint.prototype['orbitals'] = undefined;

/**
 * The symbol of the parent waypoint, if this waypoint is in orbit around another waypoint. Otherwise this value is undefined.
 * @member {String} orbits
 */
Waypoint.prototype['orbits'] = undefined;

/**
 * @member {module:models/WaypointFaction} faction
 */
Waypoint.prototype['faction'] = undefined;

/**
 * The traits of the waypoint.
 * @member {Array.<module:models/WaypointTrait>} traits
 */
Waypoint.prototype['traits'] = undefined;

/**
 * The modifiers of the waypoint.
 * @member {Array.<module:models/WaypointModifier>} modifiers
 */
Waypoint.prototype['modifiers'] = undefined;

/**
 * @member {module:models/Chart} chart
 */
Waypoint.prototype['chart'] = undefined;

/**
 * True if the waypoint is under construction.
 * @member {Boolean} isUnderConstruction
 */
Waypoint.prototype['isUnderConstruction'] = undefined;






export default Waypoint;

