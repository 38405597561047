/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipConditionEvent from './ShipConditionEvent';

/**
 * The ExtractResources201ResponseDataEventsInner model module.
 * @module models/ExtractResources201ResponseDataEventsInner
 * @version 2.0.0
 */
class ExtractResources201ResponseDataEventsInner {
    /**
     * Constructs a new <code>ExtractResources201ResponseDataEventsInner</code>.
     * @alias module:models/ExtractResources201ResponseDataEventsInner
     * @param {(module:models/ShipConditionEvent)} instance The actual instance to initialize ExtractResources201ResponseDataEventsInner.
     */
    constructor(instance = null) {
        if (instance === null) {
            this.actualInstance = null;
            return;
        }
        var match = 0;
        var errorMessages = [];
        try {
            if (typeof instance === "ShipConditionEvent") {
                this.actualInstance = instance;
            } else {
                // plain JS object
                // validate the object
                ShipConditionEvent.validateJSON(instance); // throw an exception if no match
                // create ShipConditionEvent from JS object
                this.actualInstance = ShipConditionEvent.constructFromObject(instance);
            }
            match++;
        } catch(err) {
            // json data failed to deserialize into ShipConditionEvent
            errorMessages.push("Failed to construct ShipConditionEvent: " + err)
        }

        if (match > 1) {
            throw new Error("Multiple matches found constructing `ExtractResources201ResponseDataEventsInner` with oneOf schemas ShipConditionEvent. Input: " + JSON.stringify(instance));
        } else if (match === 0) {
            this.actualInstance = null; // clear the actual instance in case there are multiple matches
            throw new Error("No match found constructing `ExtractResources201ResponseDataEventsInner` with oneOf schemas ShipConditionEvent. Details: " +
                            errorMessages.join(", "));
        } else { // only 1 match
            // the input is valid
        }
    }

    /**
     * Constructs a <code>ExtractResources201ResponseDataEventsInner</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ExtractResources201ResponseDataEventsInner} obj Optional instance to populate.
     * @return {module:models/ExtractResources201ResponseDataEventsInner} The populated <code>ExtractResources201ResponseDataEventsInner</code> instance.
     */
    static constructFromObject(data, obj) {
        return new ExtractResources201ResponseDataEventsInner(data);
    }

    /**
     * Gets the actual instance, which can be <code>ShipConditionEvent</code>.
     * @return {(module:models/ShipConditionEvent)} The actual instance.
     */
    getActualInstance() {
        return this.actualInstance;
    }

    /**
     * Sets the actual instance, which can be <code>ShipConditionEvent</code>.
     * @param {(module:models/ShipConditionEvent)} obj The actual instance.
     */
    setActualInstance(obj) {
       this.actualInstance = ExtractResources201ResponseDataEventsInner.constructFromObject(obj).getActualInstance();
    }

    /**
     * Returns the JSON representation of the actual instance.
     * @return {string}
     */
    toJSON = function(){
        return this.getActualInstance();
    }

    /**
     * Create an instance of ExtractResources201ResponseDataEventsInner from a JSON string.
     * @param {string} json_string JSON string.
     * @return {module:models/ExtractResources201ResponseDataEventsInner} An instance of ExtractResources201ResponseDataEventsInner.
     */
    static fromJSON = function(json_string){
        return ExtractResources201ResponseDataEventsInner.constructFromObject(JSON.parse(json_string));
    }
}

/**
 * @member {module:models/ExtractResources201ResponseDataEventsInner.SymbolEnum} symbol
 */
ExtractResources201ResponseDataEventsInner.prototype['symbol'] = undefined;

/**
 * @member {module:models/ExtractResources201ResponseDataEventsInner.ComponentEnum} component
 */
ExtractResources201ResponseDataEventsInner.prototype['component'] = undefined;

/**
 * The name of the event.
 * @member {String} name
 */
ExtractResources201ResponseDataEventsInner.prototype['name'] = undefined;

/**
 * A description of the event.
 * @member {String} description
 */
ExtractResources201ResponseDataEventsInner.prototype['description'] = undefined;


ExtractResources201ResponseDataEventsInner.OneOf = ["ShipConditionEvent"];

export default ExtractResources201ResponseDataEventsInner;

