/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ShipRole.
* @enum {}
* @readonly
*/
export default class ShipRole {
    
        /**
         * value: "FABRICATOR"
         * @const
         */
        "FABRICATOR" = "FABRICATOR";

    
        /**
         * value: "HARVESTER"
         * @const
         */
        "HARVESTER" = "HARVESTER";

    
        /**
         * value: "HAULER"
         * @const
         */
        "HAULER" = "HAULER";

    
        /**
         * value: "INTERCEPTOR"
         * @const
         */
        "INTERCEPTOR" = "INTERCEPTOR";

    
        /**
         * value: "EXCAVATOR"
         * @const
         */
        "EXCAVATOR" = "EXCAVATOR";

    
        /**
         * value: "TRANSPORT"
         * @const
         */
        "TRANSPORT" = "TRANSPORT";

    
        /**
         * value: "REPAIR"
         * @const
         */
        "REPAIR" = "REPAIR";

    
        /**
         * value: "SURVEYOR"
         * @const
         */
        "SURVEYOR" = "SURVEYOR";

    
        /**
         * value: "COMMAND"
         * @const
         */
        "COMMAND" = "COMMAND";

    
        /**
         * value: "CARRIER"
         * @const
         */
        "CARRIER" = "CARRIER";

    
        /**
         * value: "PATROL"
         * @const
         */
        "PATROL" = "PATROL";

    
        /**
         * value: "SATELLITE"
         * @const
         */
        "SATELLITE" = "SATELLITE";

    
        /**
         * value: "EXPLORER"
         * @const
         */
        "EXPLORER" = "EXPLORER";

    
        /**
         * value: "REFINERY"
         * @const
         */
        "REFINERY" = "REFINERY";

    

    /**
    * Returns a <code>ShipRole</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/ShipRole} The enum <code>ShipRole</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

