/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ShipRefineRequest model module.
 * @module models/ShipRefineRequest
 * @version 2.0.0
 */
class ShipRefineRequest {
    /**
     * Constructs a new <code>ShipRefineRequest</code>.
     * @alias module:models/ShipRefineRequest
     * @param produce {module:models/ShipRefineRequest.ProduceEnum} The type of good to produce out of the refining process.
     */
    constructor(produce) { 
        
        ShipRefineRequest.initialize(this, produce);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, produce) { 
        obj['produce'] = produce;
    }

    /**
     * Constructs a <code>ShipRefineRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipRefineRequest} obj Optional instance to populate.
     * @return {module:models/ShipRefineRequest} The populated <code>ShipRefineRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipRefineRequest();

            if (data.hasOwnProperty('produce')) {
                obj['produce'] = ApiClient.convertToType(data['produce'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipRefineRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipRefineRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipRefineRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['produce'] && !(typeof data['produce'] === 'string' || data['produce'] instanceof String)) {
            throw new Error("Expected the field `produce` to be a primitive type in the JSON string but got " + data['produce']);
        }

        return true;
    }


}

ShipRefineRequest.RequiredProperties = ["produce"];

/**
 * The type of good to produce out of the refining process.
 * @member {module:models/ShipRefineRequest.ProduceEnum} produce
 */
ShipRefineRequest.prototype['produce'] = undefined;





/**
 * Allowed values for the <code>produce</code> property.
 * @enum {String}
 * @readonly
 */
ShipRefineRequest['ProduceEnum'] = {

    /**
     * value: "IRON"
     * @const
     */
    "IRON": "IRON",

    /**
     * value: "COPPER"
     * @const
     */
    "COPPER": "COPPER",

    /**
     * value: "SILVER"
     * @const
     */
    "SILVER": "SILVER",

    /**
     * value: "GOLD"
     * @const
     */
    "GOLD": "GOLD",

    /**
     * value: "ALUMINUM"
     * @const
     */
    "ALUMINUM": "ALUMINUM",

    /**
     * value: "PLATINUM"
     * @const
     */
    "PLATINUM": "PLATINUM",

    /**
     * value: "URANITE"
     * @const
     */
    "URANITE": "URANITE",

    /**
     * value: "MERITIUM"
     * @const
     */
    "MERITIUM": "MERITIUM",

    /**
     * value: "FUEL"
     * @const
     */
    "FUEL": "FUEL"
};



export default ShipRefineRequest;

