/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ActivityLevel from './ActivityLevel';
import ShipEngine from './ShipEngine';
import ShipFrame from './ShipFrame';
import ShipModule from './ShipModule';
import ShipMount from './ShipMount';
import ShipReactor from './ShipReactor';
import ShipType from './ShipType';
import ShipyardShipCrew from './ShipyardShipCrew';
import SupplyLevel from './SupplyLevel';

/**
 * The ShipyardShip model module.
 * @module models/ShipyardShip
 * @version 2.0.0
 */
class ShipyardShip {
    /**
     * Constructs a new <code>ShipyardShip</code>.
     * 
     * @alias module:models/ShipyardShip
     * @param type {module:models/ShipType} 
     * @param name {String} 
     * @param description {String} 
     * @param supply {module:models/SupplyLevel} 
     * @param purchasePrice {Number} 
     * @param frame {module:models/ShipFrame} 
     * @param reactor {module:models/ShipReactor} 
     * @param engine {module:models/ShipEngine} 
     * @param modules {Array.<module:models/ShipModule>} 
     * @param mounts {Array.<module:models/ShipMount>} 
     * @param crew {module:models/ShipyardShipCrew} 
     */
    constructor(type, name, description, supply, purchasePrice, frame, reactor, engine, modules, mounts, crew) { 
        
        ShipyardShip.initialize(this, type, name, description, supply, purchasePrice, frame, reactor, engine, modules, mounts, crew);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, type, name, description, supply, purchasePrice, frame, reactor, engine, modules, mounts, crew) { 
        obj['type'] = type;
        obj['name'] = name;
        obj['description'] = description;
        obj['supply'] = supply;
        obj['purchasePrice'] = purchasePrice;
        obj['frame'] = frame;
        obj['reactor'] = reactor;
        obj['engine'] = engine;
        obj['modules'] = modules;
        obj['mounts'] = mounts;
        obj['crew'] = crew;
    }

    /**
     * Constructs a <code>ShipyardShip</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipyardShip} obj Optional instance to populate.
     * @return {module:models/ShipyardShip} The populated <code>ShipyardShip</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipyardShip();

            if (data.hasOwnProperty('type')) {
                obj['type'] = ShipType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('supply')) {
                obj['supply'] = SupplyLevel.constructFromObject(data['supply']);
            }
            if (data.hasOwnProperty('activity')) {
                obj['activity'] = ActivityLevel.constructFromObject(data['activity']);
            }
            if (data.hasOwnProperty('purchasePrice')) {
                obj['purchasePrice'] = ApiClient.convertToType(data['purchasePrice'], 'Number');
            }
            if (data.hasOwnProperty('frame')) {
                obj['frame'] = ShipFrame.constructFromObject(data['frame']);
            }
            if (data.hasOwnProperty('reactor')) {
                obj['reactor'] = ShipReactor.constructFromObject(data['reactor']);
            }
            if (data.hasOwnProperty('engine')) {
                obj['engine'] = ShipEngine.constructFromObject(data['engine']);
            }
            if (data.hasOwnProperty('modules')) {
                obj['modules'] = ApiClient.convertToType(data['modules'], [ShipModule]);
            }
            if (data.hasOwnProperty('mounts')) {
                obj['mounts'] = ApiClient.convertToType(data['mounts'], [ShipMount]);
            }
            if (data.hasOwnProperty('crew')) {
                obj['crew'] = ShipyardShipCrew.constructFromObject(data['crew']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipyardShip</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipyardShip</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipyardShip.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // validate the optional field `frame`
        if (data['frame']) { // data not null
          ShipFrame.validateJSON(data['frame']);
        }
        // validate the optional field `reactor`
        if (data['reactor']) { // data not null
          ShipReactor.validateJSON(data['reactor']);
        }
        // validate the optional field `engine`
        if (data['engine']) { // data not null
          ShipEngine.validateJSON(data['engine']);
        }
        if (data['modules']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['modules'])) {
                throw new Error("Expected the field `modules` to be an array in the JSON data but got " + data['modules']);
            }
            // validate the optional field `modules` (array)
            for (const item of data['modules']) {
                ShipModule.validateJSON(item);
            };
        }
        if (data['mounts']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['mounts'])) {
                throw new Error("Expected the field `mounts` to be an array in the JSON data but got " + data['mounts']);
            }
            // validate the optional field `mounts` (array)
            for (const item of data['mounts']) {
                ShipMount.validateJSON(item);
            };
        }
        // validate the optional field `crew`
        if (data['crew']) { // data not null
          ShipyardShipCrew.validateJSON(data['crew']);
        }

        return true;
    }


}

ShipyardShip.RequiredProperties = ["type", "name", "description", "supply", "purchasePrice", "frame", "reactor", "engine", "modules", "mounts", "crew"];

/**
 * @member {module:models/ShipType} type
 */
ShipyardShip.prototype['type'] = undefined;

/**
 * @member {String} name
 */
ShipyardShip.prototype['name'] = undefined;

/**
 * @member {String} description
 */
ShipyardShip.prototype['description'] = undefined;

/**
 * @member {module:models/SupplyLevel} supply
 */
ShipyardShip.prototype['supply'] = undefined;

/**
 * @member {module:models/ActivityLevel} activity
 */
ShipyardShip.prototype['activity'] = undefined;

/**
 * @member {Number} purchasePrice
 */
ShipyardShip.prototype['purchasePrice'] = undefined;

/**
 * @member {module:models/ShipFrame} frame
 */
ShipyardShip.prototype['frame'] = undefined;

/**
 * @member {module:models/ShipReactor} reactor
 */
ShipyardShip.prototype['reactor'] = undefined;

/**
 * @member {module:models/ShipEngine} engine
 */
ShipyardShip.prototype['engine'] = undefined;

/**
 * @member {Array.<module:models/ShipModule>} modules
 */
ShipyardShip.prototype['modules'] = undefined;

/**
 * @member {Array.<module:models/ShipMount>} mounts
 */
ShipyardShip.prototype['mounts'] = undefined;

/**
 * @member {module:models/ShipyardShipCrew} crew
 */
ShipyardShip.prototype['crew'] = undefined;






export default ShipyardShip;

