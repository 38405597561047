/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class WaypointType.
* @enum {}
* @readonly
*/
export default class WaypointType {
    
        /**
         * value: "PLANET"
         * @const
         */
        "PLANET" = "PLANET";

    
        /**
         * value: "GAS_GIANT"
         * @const
         */
        "GAS_GIANT" = "GAS_GIANT";

    
        /**
         * value: "MOON"
         * @const
         */
        "MOON" = "MOON";

    
        /**
         * value: "ORBITAL_STATION"
         * @const
         */
        "ORBITAL_STATION" = "ORBITAL_STATION";

    
        /**
         * value: "JUMP_GATE"
         * @const
         */
        "JUMP_GATE" = "JUMP_GATE";

    
        /**
         * value: "ASTEROID_FIELD"
         * @const
         */
        "ASTEROID_FIELD" = "ASTEROID_FIELD";

    
        /**
         * value: "ASTEROID"
         * @const
         */
        "ASTEROID" = "ASTEROID";

    
        /**
         * value: "ENGINEERED_ASTEROID"
         * @const
         */
        "ENGINEERED_ASTEROID" = "ENGINEERED_ASTEROID";

    
        /**
         * value: "ASTEROID_BASE"
         * @const
         */
        "ASTEROID_BASE" = "ASTEROID_BASE";

    
        /**
         * value: "NEBULA"
         * @const
         */
        "NEBULA" = "NEBULA";

    
        /**
         * value: "DEBRIS_FIELD"
         * @const
         */
        "DEBRIS_FIELD" = "DEBRIS_FIELD";

    
        /**
         * value: "GRAVITY_WELL"
         * @const
         */
        "GRAVITY_WELL" = "GRAVITY_WELL";

    
        /**
         * value: "ARTIFICIAL_GRAVITY_WELL"
         * @const
         */
        "ARTIFICIAL_GRAVITY_WELL" = "ARTIFICIAL_GRAVITY_WELL";

    
        /**
         * value: "FUEL_STATION"
         * @const
         */
        "FUEL_STATION" = "FUEL_STATION";

    

    /**
    * Returns a <code>WaypointType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/WaypointType} The enum <code>WaypointType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

