/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Meta model module.
 * @module models/Meta
 * @version 2.0.0
 */
class Meta {
    /**
     * Constructs a new <code>Meta</code>.
     * Meta details for pagination.
     * @alias module:models/Meta
     * @param total {Number} Shows the total amount of items of this kind that exist.
     * @param page {Number} A page denotes an amount of items, offset from the first item. Each page holds an amount of items equal to the `limit`.
     * @param limit {Number} The amount of items in each page. Limits how many items can be fetched at once.
     */
    constructor(total, page, limit) { 
        
        Meta.initialize(this, total, page, limit);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, total, page, limit) { 
        obj['total'] = total;
        obj['page'] = page || 1;
        obj['limit'] = limit || 10;
    }

    /**
     * Constructs a <code>Meta</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/Meta} obj Optional instance to populate.
     * @return {module:models/Meta} The populated <code>Meta</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Meta();

            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
            if (data.hasOwnProperty('page')) {
                obj['page'] = ApiClient.convertToType(data['page'], 'Number');
            }
            if (data.hasOwnProperty('limit')) {
                obj['limit'] = ApiClient.convertToType(data['limit'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Meta</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Meta</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Meta.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

Meta.RequiredProperties = ["total", "page", "limit"];

/**
 * Shows the total amount of items of this kind that exist.
 * @member {Number} total
 */
Meta.prototype['total'] = undefined;

/**
 * A page denotes an amount of items, offset from the first item. Each page holds an amount of items equal to the `limit`.
 * @member {Number} page
 * @default 1
 */
Meta.prototype['page'] = 1;

/**
 * The amount of items in each page. Limits how many items can be fetched at once.
 * @member {Number} limit
 * @default 10
 */
Meta.prototype['limit'] = 10;






export default Meta;

