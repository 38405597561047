/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SystemType from './SystemType';

/**
 * The ScannedSystem model module.
 * @module models/ScannedSystem
 * @version 2.0.0
 */
class ScannedSystem {
    /**
     * Constructs a new <code>ScannedSystem</code>.
     * Details of a system was that scanned.
     * @alias module:models/ScannedSystem
     * @param symbol {String} Symbol of the system.
     * @param sectorSymbol {String} Symbol of the system's sector.
     * @param type {module:models/SystemType} 
     * @param x {Number} Position in the universe in the x axis.
     * @param y {Number} Position in the universe in the y axis.
     * @param distance {Number} The system's distance from the scanning ship.
     */
    constructor(symbol, sectorSymbol, type, x, y, distance) { 
        
        ScannedSystem.initialize(this, symbol, sectorSymbol, type, x, y, distance);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, sectorSymbol, type, x, y, distance) { 
        obj['symbol'] = symbol;
        obj['sectorSymbol'] = sectorSymbol;
        obj['type'] = type;
        obj['x'] = x;
        obj['y'] = y;
        obj['distance'] = distance;
    }

    /**
     * Constructs a <code>ScannedSystem</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ScannedSystem} obj Optional instance to populate.
     * @return {module:models/ScannedSystem} The populated <code>ScannedSystem</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ScannedSystem();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('sectorSymbol')) {
                obj['sectorSymbol'] = ApiClient.convertToType(data['sectorSymbol'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = SystemType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('x')) {
                obj['x'] = ApiClient.convertToType(data['x'], 'Number');
            }
            if (data.hasOwnProperty('y')) {
                obj['y'] = ApiClient.convertToType(data['y'], 'Number');
            }
            if (data.hasOwnProperty('distance')) {
                obj['distance'] = ApiClient.convertToType(data['distance'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ScannedSystem</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ScannedSystem</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ScannedSystem.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['sectorSymbol'] && !(typeof data['sectorSymbol'] === 'string' || data['sectorSymbol'] instanceof String)) {
            throw new Error("Expected the field `sectorSymbol` to be a primitive type in the JSON string but got " + data['sectorSymbol']);
        }

        return true;
    }


}

ScannedSystem.RequiredProperties = ["symbol", "sectorSymbol", "type", "x", "y", "distance"];

/**
 * Symbol of the system.
 * @member {String} symbol
 */
ScannedSystem.prototype['symbol'] = undefined;

/**
 * Symbol of the system's sector.
 * @member {String} sectorSymbol
 */
ScannedSystem.prototype['sectorSymbol'] = undefined;

/**
 * @member {module:models/SystemType} type
 */
ScannedSystem.prototype['type'] = undefined;

/**
 * Position in the universe in the x axis.
 * @member {Number} x
 */
ScannedSystem.prototype['x'] = undefined;

/**
 * Position in the universe in the y axis.
 * @member {Number} y
 */
ScannedSystem.prototype['y'] = undefined;

/**
 * The system's distance from the scanning ship.
 * @member {Number} distance
 */
ScannedSystem.prototype['distance'] = undefined;






export default ScannedSystem;

