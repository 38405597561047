/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class FactionTraitSymbol.
* @enum {}
* @readonly
*/
export default class FactionTraitSymbol {
    
        /**
         * value: "BUREAUCRATIC"
         * @const
         */
        "BUREAUCRATIC" = "BUREAUCRATIC";

    
        /**
         * value: "SECRETIVE"
         * @const
         */
        "SECRETIVE" = "SECRETIVE";

    
        /**
         * value: "CAPITALISTIC"
         * @const
         */
        "CAPITALISTIC" = "CAPITALISTIC";

    
        /**
         * value: "INDUSTRIOUS"
         * @const
         */
        "INDUSTRIOUS" = "INDUSTRIOUS";

    
        /**
         * value: "PEACEFUL"
         * @const
         */
        "PEACEFUL" = "PEACEFUL";

    
        /**
         * value: "DISTRUSTFUL"
         * @const
         */
        "DISTRUSTFUL" = "DISTRUSTFUL";

    
        /**
         * value: "WELCOMING"
         * @const
         */
        "WELCOMING" = "WELCOMING";

    
        /**
         * value: "SMUGGLERS"
         * @const
         */
        "SMUGGLERS" = "SMUGGLERS";

    
        /**
         * value: "SCAVENGERS"
         * @const
         */
        "SCAVENGERS" = "SCAVENGERS";

    
        /**
         * value: "REBELLIOUS"
         * @const
         */
        "REBELLIOUS" = "REBELLIOUS";

    
        /**
         * value: "EXILES"
         * @const
         */
        "EXILES" = "EXILES";

    
        /**
         * value: "PIRATES"
         * @const
         */
        "PIRATES" = "PIRATES";

    
        /**
         * value: "RAIDERS"
         * @const
         */
        "RAIDERS" = "RAIDERS";

    
        /**
         * value: "CLAN"
         * @const
         */
        "CLAN" = "CLAN";

    
        /**
         * value: "GUILD"
         * @const
         */
        "GUILD" = "GUILD";

    
        /**
         * value: "DOMINION"
         * @const
         */
        "DOMINION" = "DOMINION";

    
        /**
         * value: "FRINGE"
         * @const
         */
        "FRINGE" = "FRINGE";

    
        /**
         * value: "FORSAKEN"
         * @const
         */
        "FORSAKEN" = "FORSAKEN";

    
        /**
         * value: "ISOLATED"
         * @const
         */
        "ISOLATED" = "ISOLATED";

    
        /**
         * value: "LOCALIZED"
         * @const
         */
        "LOCALIZED" = "LOCALIZED";

    
        /**
         * value: "ESTABLISHED"
         * @const
         */
        "ESTABLISHED" = "ESTABLISHED";

    
        /**
         * value: "NOTABLE"
         * @const
         */
        "NOTABLE" = "NOTABLE";

    
        /**
         * value: "DOMINANT"
         * @const
         */
        "DOMINANT" = "DOMINANT";

    
        /**
         * value: "INESCAPABLE"
         * @const
         */
        "INESCAPABLE" = "INESCAPABLE";

    
        /**
         * value: "INNOVATIVE"
         * @const
         */
        "INNOVATIVE" = "INNOVATIVE";

    
        /**
         * value: "BOLD"
         * @const
         */
        "BOLD" = "BOLD";

    
        /**
         * value: "VISIONARY"
         * @const
         */
        "VISIONARY" = "VISIONARY";

    
        /**
         * value: "CURIOUS"
         * @const
         */
        "CURIOUS" = "CURIOUS";

    
        /**
         * value: "DARING"
         * @const
         */
        "DARING" = "DARING";

    
        /**
         * value: "EXPLORATORY"
         * @const
         */
        "EXPLORATORY" = "EXPLORATORY";

    
        /**
         * value: "RESOURCEFUL"
         * @const
         */
        "RESOURCEFUL" = "RESOURCEFUL";

    
        /**
         * value: "FLEXIBLE"
         * @const
         */
        "FLEXIBLE" = "FLEXIBLE";

    
        /**
         * value: "COOPERATIVE"
         * @const
         */
        "COOPERATIVE" = "COOPERATIVE";

    
        /**
         * value: "UNITED"
         * @const
         */
        "UNITED" = "UNITED";

    
        /**
         * value: "STRATEGIC"
         * @const
         */
        "STRATEGIC" = "STRATEGIC";

    
        /**
         * value: "INTELLIGENT"
         * @const
         */
        "INTELLIGENT" = "INTELLIGENT";

    
        /**
         * value: "RESEARCH_FOCUSED"
         * @const
         */
        "RESEARCH_FOCUSED" = "RESEARCH_FOCUSED";

    
        /**
         * value: "COLLABORATIVE"
         * @const
         */
        "COLLABORATIVE" = "COLLABORATIVE";

    
        /**
         * value: "PROGRESSIVE"
         * @const
         */
        "PROGRESSIVE" = "PROGRESSIVE";

    
        /**
         * value: "MILITARISTIC"
         * @const
         */
        "MILITARISTIC" = "MILITARISTIC";

    
        /**
         * value: "TECHNOLOGICALLY_ADVANCED"
         * @const
         */
        "TECHNOLOGICALLY_ADVANCED" = "TECHNOLOGICALLY_ADVANCED";

    
        /**
         * value: "AGGRESSIVE"
         * @const
         */
        "AGGRESSIVE" = "AGGRESSIVE";

    
        /**
         * value: "IMPERIALISTIC"
         * @const
         */
        "IMPERIALISTIC" = "IMPERIALISTIC";

    
        /**
         * value: "TREASURE_HUNTERS"
         * @const
         */
        "TREASURE_HUNTERS" = "TREASURE_HUNTERS";

    
        /**
         * value: "DEXTEROUS"
         * @const
         */
        "DEXTEROUS" = "DEXTEROUS";

    
        /**
         * value: "UNPREDICTABLE"
         * @const
         */
        "UNPREDICTABLE" = "UNPREDICTABLE";

    
        /**
         * value: "BRUTAL"
         * @const
         */
        "BRUTAL" = "BRUTAL";

    
        /**
         * value: "FLEETING"
         * @const
         */
        "FLEETING" = "FLEETING";

    
        /**
         * value: "ADAPTABLE"
         * @const
         */
        "ADAPTABLE" = "ADAPTABLE";

    
        /**
         * value: "SELF_SUFFICIENT"
         * @const
         */
        "SELF_SUFFICIENT" = "SELF_SUFFICIENT";

    
        /**
         * value: "DEFENSIVE"
         * @const
         */
        "DEFENSIVE" = "DEFENSIVE";

    
        /**
         * value: "PROUD"
         * @const
         */
        "PROUD" = "PROUD";

    
        /**
         * value: "DIVERSE"
         * @const
         */
        "DIVERSE" = "DIVERSE";

    
        /**
         * value: "INDEPENDENT"
         * @const
         */
        "INDEPENDENT" = "INDEPENDENT";

    
        /**
         * value: "SELF_INTERESTED"
         * @const
         */
        "SELF_INTERESTED" = "SELF_INTERESTED";

    
        /**
         * value: "FRAGMENTED"
         * @const
         */
        "FRAGMENTED" = "FRAGMENTED";

    
        /**
         * value: "COMMERCIAL"
         * @const
         */
        "COMMERCIAL" = "COMMERCIAL";

    
        /**
         * value: "FREE_MARKETS"
         * @const
         */
        "FREE_MARKETS" = "FREE_MARKETS";

    
        /**
         * value: "ENTREPRENEURIAL"
         * @const
         */
        "ENTREPRENEURIAL" = "ENTREPRENEURIAL";

    

    /**
    * Returns a <code>FactionTraitSymbol</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/FactionTraitSymbol} The enum <code>FactionTraitSymbol</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

