/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ShipType.
* @enum {}
* @readonly
*/
export default class ShipType {
    
        /**
         * value: "SHIP_PROBE"
         * @const
         */
        "PROBE" = "SHIP_PROBE";

    
        /**
         * value: "SHIP_MINING_DRONE"
         * @const
         */
        "MINING_DRONE" = "SHIP_MINING_DRONE";

    
        /**
         * value: "SHIP_SIPHON_DRONE"
         * @const
         */
        "SIPHON_DRONE" = "SHIP_SIPHON_DRONE";

    
        /**
         * value: "SHIP_INTERCEPTOR"
         * @const
         */
        "INTERCEPTOR" = "SHIP_INTERCEPTOR";

    
        /**
         * value: "SHIP_LIGHT_HAULER"
         * @const
         */
        "LIGHT_HAULER" = "SHIP_LIGHT_HAULER";

    
        /**
         * value: "SHIP_COMMAND_FRIGATE"
         * @const
         */
        "COMMAND_FRIGATE" = "SHIP_COMMAND_FRIGATE";

    
        /**
         * value: "SHIP_EXPLORER"
         * @const
         */
        "EXPLORER" = "SHIP_EXPLORER";

    
        /**
         * value: "SHIP_HEAVY_FREIGHTER"
         * @const
         */
        "HEAVY_FREIGHTER" = "SHIP_HEAVY_FREIGHTER";

    
        /**
         * value: "SHIP_LIGHT_SHUTTLE"
         * @const
         */
        "LIGHT_SHUTTLE" = "SHIP_LIGHT_SHUTTLE";

    
        /**
         * value: "SHIP_ORE_HOUND"
         * @const
         */
        "ORE_HOUND" = "SHIP_ORE_HOUND";

    
        /**
         * value: "SHIP_REFINING_FREIGHTER"
         * @const
         */
        "REFINING_FREIGHTER" = "SHIP_REFINING_FREIGHTER";

    
        /**
         * value: "SHIP_SURVEYOR"
         * @const
         */
        "SURVEYOR" = "SHIP_SURVEYOR";

    

    /**
    * Returns a <code>ShipType</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/ShipType} The enum <code>ShipType</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

