<template>
  <button @click="serverStatus">Status</button>
  <br><br>
  <div>Test div here</div>
  <br>
<!--  <GG text="text_prop_custom_value"></GG>-->
  <a href="#/GG">GG Link</a>
  <br>
  <br>
  <a href="#/Test">Test Link</a>
  <br>
  <br>
  <a href="#/orwgowrkg">Broken Link</a>
  <br>
  <br>
  <br>
  <component :is="currentView" />
</template>

<script>
import { status } from "./libs/api/models/status.js";
import GG from "./components/GG.vue";
import TEST from "./components/TEST.vue";

import DefaultApi from "@/libs/api-v2/api/DefaultApi";

const routes = {
  '/GG': GG,
  '/Test': TEST,
};

export default {
  name: 'App',
  components: { GG, TEST },
  data() {
    return {
      currentPath: window.location.hash
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath.slice(1) || '/'] || GG;
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash;
    });
  },
  methods: {
    serverStatus() {
      fetch('https://api.spacetraders.io/v2/')
        .then((response) => response.json())
        .then((response) => console.log(new status(response)))
        .catch((err) => console.error(err));
      new DefaultApi().getStatus().then(status => {
        console.log(status);
      });
    },
  },
};
</script>

<style>
  div {color: darkgreen;}
</style>
