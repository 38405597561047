<template>
  <div class="GG">
    <button @click="register">Register</button>
    <br><br>
    <button @click="test">{{ text }}</button>
    <br><br>
    <button @click="getContracts">/v2/my/contracts</button>
    <br><br>
    <MoveableDiv style="display: none" id="myDiv" :options=options></MoveableDiv>
    <br><br>
    <MovableContainer>
      <MovableItem id="item1" width="200px" height="300px" :remember="true" :constrain-to-parent="true">
        <img height="100px" src="https://wallpapercave.com/wp/wp4959699.jpg" alt="dark anime bitch">
      </MovableItem>
      <MovableItem id="item2" :remember="true" :constrain-to-parent="false">
        <img height="100px" src="https://content.assets.pressassociation.io/2017/07/18153241/17_7_5_WildcatKitten_SA_11.jpg" alt="kitten in the wood">
      </MovableItem>
      <MovableItem
          v-for="(item, index) in movableItems"
          :key="item.id"
          :id="item.id"
          :width="item.width"
          :height="item.height"
          :remember="false"
      >
        {{index}} --> {{ item.content }}
      </MovableItem>
    </MovableContainer>

    <MovableContainer>
      <MovableItem id="item01" width="150px" height="100px" :constrainToParent="true">Item 01</MovableItem>
      <MovableItem id="item02" width="200px" height="150px" :constrainToParent="false">Item 02</MovableItem>
    </MovableContainer>

    <MovableContainer>
      <MovableItem id="item03" width="150px" height="100px" :constrainToParent="true">Item 03</MovableItem>
    </MovableContainer>
  </div>
</template>

<script>
//import * as _api from "./libs/_api/ApiClient";
import MovableContainer from '@/components/MovableContainer.vue';
import MovableItem from '@/components/MovableItem.vue';
import * as api from "../libs/api/client.js";
import MoveableDiv, {options} from "@/components/MoveableDiv.vue";

import {ContractsApi , DefaultApi, ApiClient} from "@/libs/api-v2/";

export default {
  name: "GG",
  components: {MovableContainer, MovableItem, MoveableDiv},
  props: {
    text: String,
  },
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    // setup() receives props as the first argument.
    //console.log(props.text);
    document.body.style.background = "#321";
  },
  data(){
    return {
      options: (new options({resizable: true, draggable: true})),

      // could load from Local Storage while fetching from API
      movableItems: [
        {
          id: "ID_nah_ID",
          width: '150px',
          height: '100px',
          content: `test`,
        },
      ], // Array to store movable items

      api: new DefaultApi(),
    };
  },
  methods: {
    register() {
      console.log("Register");
      //api.registerAgent("cdig_2f2");
      this.api.register("teststststst");
    },
    test() {
      console.log(api);
    },
    getContracts() {
      /*console.log(Object.getOwnPropertyNames(ApiClient));
      return;
      console.log(ApiClient.instance);return;

      ApiClient.instance.getContracts().then((response) => {console.log(response)});*/
      /*api.getContracts(contracts => {
        for(let index in contracts)
        {
          console.log(`${contracts[index]}`);
          this.movableItems.push({
            id: "ID_" + this.movableItems.length + "_ID",
            width: '200px',
            height: '175px',
            content: `${contracts[index]}`,
          });
        }
        //contracts.forEach(contract => console.log(contract));
      });*/
      ApiClient.instance.authentications = {
        'AgentToken': {type: 'bearer', accessToken: api.getToken()}
      };
      //console.log(ApiClient.instance.authentications);

      new ContractsApi(ApiClient.instance).getContracts().then(response => {
        let contracts = response.data;
        console.log(contracts);
        for(let index in contracts)
        {
          console.log(`${contracts[index]}`);
          this.movableItems.push({
            id: "ID_" + this.movableItems.length + "_ID",
            width: '200px',
            height: '175px',
            content: `${contracts[index]}`,
          });
        }
        //contracts.forEach(contract => console.log(contract));
      }).catch(e => console.log(e));
    },
  },
};
</script>

<style scoped>
.GG{
  background: dimgray;
  border: chocolate 3px solid;
  border-radius: 25px;
  padding: 30px;
}
</style>