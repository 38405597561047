/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TradeSymbol from './TradeSymbol';

/**
 * The ConstructionMaterial model module.
 * @module models/ConstructionMaterial
 * @version 2.0.0
 */
class ConstructionMaterial {
    /**
     * Constructs a new <code>ConstructionMaterial</code>.
     * The details of the required construction materials for a given waypoint under construction.
     * @alias module:models/ConstructionMaterial
     * @param tradeSymbol {module:models/TradeSymbol} 
     * @param required {Number} The number of units required.
     * @param fulfilled {Number} The number of units fulfilled toward the required amount.
     */
    constructor(tradeSymbol, required, fulfilled) { 
        
        ConstructionMaterial.initialize(this, tradeSymbol, required, fulfilled);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, tradeSymbol, required, fulfilled) { 
        obj['tradeSymbol'] = tradeSymbol;
        obj['required'] = required;
        obj['fulfilled'] = fulfilled;
    }

    /**
     * Constructs a <code>ConstructionMaterial</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ConstructionMaterial} obj Optional instance to populate.
     * @return {module:models/ConstructionMaterial} The populated <code>ConstructionMaterial</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ConstructionMaterial();

            if (data.hasOwnProperty('tradeSymbol')) {
                obj['tradeSymbol'] = TradeSymbol.constructFromObject(data['tradeSymbol']);
            }
            if (data.hasOwnProperty('required')) {
                obj['required'] = ApiClient.convertToType(data['required'], 'Number');
            }
            if (data.hasOwnProperty('fulfilled')) {
                obj['fulfilled'] = ApiClient.convertToType(data['fulfilled'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ConstructionMaterial</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ConstructionMaterial</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ConstructionMaterial.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }

        return true;
    }


}

ConstructionMaterial.RequiredProperties = ["tradeSymbol", "required", "fulfilled"];

/**
 * @member {module:models/TradeSymbol} tradeSymbol
 */
ConstructionMaterial.prototype['tradeSymbol'] = undefined;

/**
 * The number of units required.
 * @member {Number} required
 */
ConstructionMaterial.prototype['required'] = undefined;

/**
 * The number of units fulfilled toward the required amount.
 * @member {Number} fulfilled
 */
ConstructionMaterial.prototype['fulfilled'] = undefined;






export default ConstructionMaterial;

