/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ContractDeliverGood from './ContractDeliverGood';
import ContractPayment from './ContractPayment';

/**
 * The ContractTerms model module.
 * @module models/ContractTerms
 * @version 2.0.0
 */
class ContractTerms {
    /**
     * Constructs a new <code>ContractTerms</code>.
     * The terms to fulfill the contract.
     * @alias module:models/ContractTerms
     * @param deadline {Date} The deadline for the contract.
     * @param payment {module:models/ContractPayment} 
     */
    constructor(deadline, payment) { 
        
        ContractTerms.initialize(this, deadline, payment);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, deadline, payment) { 
        obj['deadline'] = deadline;
        obj['payment'] = payment;
    }

    /**
     * Constructs a <code>ContractTerms</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ContractTerms} obj Optional instance to populate.
     * @return {module:models/ContractTerms} The populated <code>ContractTerms</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContractTerms();

            if (data.hasOwnProperty('deadline')) {
                obj['deadline'] = ApiClient.convertToType(data['deadline'], 'Date');
            }
            if (data.hasOwnProperty('payment')) {
                obj['payment'] = ContractPayment.constructFromObject(data['payment']);
            }
            if (data.hasOwnProperty('deliver')) {
                obj['deliver'] = ApiClient.convertToType(data['deliver'], [ContractDeliverGood]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ContractTerms</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ContractTerms</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ContractTerms.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `payment`
        if (data['payment']) { // data not null
          ContractPayment.validateJSON(data['payment']);
        }
        if (data['deliver']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['deliver'])) {
                throw new Error("Expected the field `deliver` to be an array in the JSON data but got " + data['deliver']);
            }
            // validate the optional field `deliver` (array)
            for (const item of data['deliver']) {
                ContractDeliverGood.validateJSON(item);
            };
        }

        return true;
    }


}

ContractTerms.RequiredProperties = ["deadline", "payment"];

/**
 * The deadline for the contract.
 * @member {Date} deadline
 */
ContractTerms.prototype['deadline'] = undefined;

/**
 * @member {module:models/ContractPayment} payment
 */
ContractTerms.prototype['payment'] = undefined;

/**
 * The cargo that needs to be delivered to fulfill the contract.
 * @member {Array.<module:models/ContractDeliverGood>} deliver
 */
ContractTerms.prototype['deliver'] = undefined;






export default ContractTerms;

