/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import ShipCargo from './ShipCargo';
import ShipModificationTransaction from './ShipModificationTransaction';
import ShipMount from './ShipMount';

/**
 * The InstallMount201ResponseData model module.
 * @module models/InstallMount201ResponseData
 * @version 2.0.0
 */
class InstallMount201ResponseData {
    /**
     * Constructs a new <code>InstallMount201ResponseData</code>.
     * @alias module:models/InstallMount201ResponseData
     * @param agent {module:models/Agent} 
     * @param mounts {Array.<module:models/ShipMount>} List of installed mounts after the installation of the new mount.
     * @param cargo {module:models/ShipCargo} 
     * @param transaction {module:models/ShipModificationTransaction} 
     */
    constructor(agent, mounts, cargo, transaction) { 
        
        InstallMount201ResponseData.initialize(this, agent, mounts, cargo, transaction);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, agent, mounts, cargo, transaction) { 
        obj['agent'] = agent;
        obj['mounts'] = mounts;
        obj['cargo'] = cargo;
        obj['transaction'] = transaction;
    }

    /**
     * Constructs a <code>InstallMount201ResponseData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/InstallMount201ResponseData} obj Optional instance to populate.
     * @return {module:models/InstallMount201ResponseData} The populated <code>InstallMount201ResponseData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new InstallMount201ResponseData();

            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('mounts')) {
                obj['mounts'] = ApiClient.convertToType(data['mounts'], [ShipMount]);
            }
            if (data.hasOwnProperty('cargo')) {
                obj['cargo'] = ShipCargo.constructFromObject(data['cargo']);
            }
            if (data.hasOwnProperty('transaction')) {
                obj['transaction'] = ShipModificationTransaction.constructFromObject(data['transaction']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>InstallMount201ResponseData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>InstallMount201ResponseData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of InstallMount201ResponseData.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        if (data['mounts']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['mounts'])) {
                throw new Error("Expected the field `mounts` to be an array in the JSON data but got " + data['mounts']);
            }
            // validate the optional field `mounts` (array)
            for (const item of data['mounts']) {
                ShipMount.validateJSON(item);
            };
        }
        // validate the optional field `cargo`
        if (data['cargo']) { // data not null
          ShipCargo.validateJSON(data['cargo']);
        }
        // validate the optional field `transaction`
        if (data['transaction']) { // data not null
          ShipModificationTransaction.validateJSON(data['transaction']);
        }

        return true;
    }


}

InstallMount201ResponseData.RequiredProperties = ["agent", "mounts", "cargo", "transaction"];

/**
 * @member {module:models/Agent} agent
 */
InstallMount201ResponseData.prototype['agent'] = undefined;

/**
 * List of installed mounts after the installation of the new mount.
 * @member {Array.<module:models/ShipMount>} mounts
 */
InstallMount201ResponseData.prototype['mounts'] = undefined;

/**
 * @member {module:models/ShipCargo} cargo
 */
InstallMount201ResponseData.prototype['cargo'] = undefined;

/**
 * @member {module:models/ShipModificationTransaction} transaction
 */
InstallMount201ResponseData.prototype['transaction'] = undefined;






export default InstallMount201ResponseData;

