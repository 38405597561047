/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SystemFaction from './SystemFaction';
import SystemType from './SystemType';
import SystemWaypoint from './SystemWaypoint';

/**
 * The System model module.
 * @module models/System
 * @version 2.0.0
 */
class System {
    /**
     * Constructs a new <code>System</code>.
     * @alias module:models/System
     * @param symbol {String} The symbol of the system.
     * @param sectorSymbol {String} The symbol of the sector.
     * @param type {module:models/SystemType} 
     * @param x {Number} Relative position of the system in the sector in the x axis.
     * @param y {Number} Relative position of the system in the sector in the y axis.
     * @param waypoints {Array.<module:models/SystemWaypoint>} Waypoints in this system.
     * @param factions {Array.<module:models/SystemFaction>} Factions that control this system.
     */
    constructor(symbol, sectorSymbol, type, x, y, waypoints, factions) { 
        
        System.initialize(this, symbol, sectorSymbol, type, x, y, waypoints, factions);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, sectorSymbol, type, x, y, waypoints, factions) { 
        obj['symbol'] = symbol;
        obj['sectorSymbol'] = sectorSymbol;
        obj['type'] = type;
        obj['x'] = x;
        obj['y'] = y;
        obj['waypoints'] = waypoints;
        obj['factions'] = factions;
    }

    /**
     * Constructs a <code>System</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/System} obj Optional instance to populate.
     * @return {module:models/System} The populated <code>System</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new System();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('sectorSymbol')) {
                obj['sectorSymbol'] = ApiClient.convertToType(data['sectorSymbol'], 'String');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = SystemType.constructFromObject(data['type']);
            }
            if (data.hasOwnProperty('x')) {
                obj['x'] = ApiClient.convertToType(data['x'], 'Number');
            }
            if (data.hasOwnProperty('y')) {
                obj['y'] = ApiClient.convertToType(data['y'], 'Number');
            }
            if (data.hasOwnProperty('waypoints')) {
                obj['waypoints'] = ApiClient.convertToType(data['waypoints'], [SystemWaypoint]);
            }
            if (data.hasOwnProperty('factions')) {
                obj['factions'] = ApiClient.convertToType(data['factions'], [SystemFaction]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>System</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>System</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of System.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['sectorSymbol'] && !(typeof data['sectorSymbol'] === 'string' || data['sectorSymbol'] instanceof String)) {
            throw new Error("Expected the field `sectorSymbol` to be a primitive type in the JSON string but got " + data['sectorSymbol']);
        }
        if (data['waypoints']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['waypoints'])) {
                throw new Error("Expected the field `waypoints` to be an array in the JSON data but got " + data['waypoints']);
            }
            // validate the optional field `waypoints` (array)
            for (const item of data['waypoints']) {
                SystemWaypoint.validateJSON(item);
            };
        }
        if (data['factions']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['factions'])) {
                throw new Error("Expected the field `factions` to be an array in the JSON data but got " + data['factions']);
            }
            // validate the optional field `factions` (array)
            for (const item of data['factions']) {
                SystemFaction.validateJSON(item);
            };
        }

        return true;
    }


}

System.RequiredProperties = ["symbol", "sectorSymbol", "type", "x", "y", "waypoints", "factions"];

/**
 * The symbol of the system.
 * @member {String} symbol
 */
System.prototype['symbol'] = undefined;

/**
 * The symbol of the sector.
 * @member {String} sectorSymbol
 */
System.prototype['sectorSymbol'] = undefined;

/**
 * @member {module:models/SystemType} type
 */
System.prototype['type'] = undefined;

/**
 * Relative position of the system in the sector in the x axis.
 * @member {Number} x
 */
System.prototype['x'] = undefined;

/**
 * Relative position of the system in the sector in the y axis.
 * @member {Number} y
 */
System.prototype['y'] = undefined;

/**
 * Waypoints in this system.
 * @member {Array.<module:models/SystemWaypoint>} waypoints
 */
System.prototype['waypoints'] = undefined;

/**
 * Factions that control this system.
 * @member {Array.<module:models/SystemFaction>} factions
 */
System.prototype['factions'] = undefined;






export default System;

