/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TradeSymbol from './TradeSymbol';

/**
 * The TransferCargoRequest model module.
 * @module models/TransferCargoRequest
 * @version 2.0.0
 */
class TransferCargoRequest {
    /**
     * Constructs a new <code>TransferCargoRequest</code>.
     * @alias module:models/TransferCargoRequest
     * @param tradeSymbol {module:models/TradeSymbol} 
     * @param units {Number} Amount of units to transfer.
     * @param shipSymbol {String} The symbol of the ship to transfer to.
     */
    constructor(tradeSymbol, units, shipSymbol) { 
        
        TransferCargoRequest.initialize(this, tradeSymbol, units, shipSymbol);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, tradeSymbol, units, shipSymbol) { 
        obj['tradeSymbol'] = tradeSymbol;
        obj['units'] = units;
        obj['shipSymbol'] = shipSymbol;
    }

    /**
     * Constructs a <code>TransferCargoRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/TransferCargoRequest} obj Optional instance to populate.
     * @return {module:models/TransferCargoRequest} The populated <code>TransferCargoRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TransferCargoRequest();

            if (data.hasOwnProperty('tradeSymbol')) {
                obj['tradeSymbol'] = TradeSymbol.constructFromObject(data['tradeSymbol']);
            }
            if (data.hasOwnProperty('units')) {
                obj['units'] = ApiClient.convertToType(data['units'], 'Number');
            }
            if (data.hasOwnProperty('shipSymbol')) {
                obj['shipSymbol'] = ApiClient.convertToType(data['shipSymbol'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>TransferCargoRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>TransferCargoRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of TransferCargoRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['shipSymbol'] && !(typeof data['shipSymbol'] === 'string' || data['shipSymbol'] instanceof String)) {
            throw new Error("Expected the field `shipSymbol` to be a primitive type in the JSON string but got " + data['shipSymbol']);
        }

        return true;
    }


}

TransferCargoRequest.RequiredProperties = ["tradeSymbol", "units", "shipSymbol"];

/**
 * @member {module:models/TradeSymbol} tradeSymbol
 */
TransferCargoRequest.prototype['tradeSymbol'] = undefined;

/**
 * Amount of units to transfer.
 * @member {Number} units
 */
TransferCargoRequest.prototype['units'] = undefined;

/**
 * The symbol of the ship to transfer to.
 * @member {String} shipSymbol
 */
TransferCargoRequest.prototype['shipSymbol'] = undefined;






export default TransferCargoRequest;

