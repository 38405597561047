/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipFuelConsumed from './ShipFuelConsumed';

/**
 * The ShipFuel model module.
 * @module models/ShipFuel
 * @version 2.0.0
 */
class ShipFuel {
    /**
     * Constructs a new <code>ShipFuel</code>.
     * Details of the ship&#39;s fuel tanks including how much fuel was consumed during the last transit or action.
     * @alias module:models/ShipFuel
     * @param current {Number} The current amount of fuel in the ship's tanks.
     * @param capacity {Number} The maximum amount of fuel the ship's tanks can hold.
     */
    constructor(current, capacity) { 
        
        ShipFuel.initialize(this, current, capacity);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, current, capacity) { 
        obj['current'] = current;
        obj['capacity'] = capacity;
    }

    /**
     * Constructs a <code>ShipFuel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipFuel} obj Optional instance to populate.
     * @return {module:models/ShipFuel} The populated <code>ShipFuel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipFuel();

            if (data.hasOwnProperty('current')) {
                obj['current'] = ApiClient.convertToType(data['current'], 'Number');
            }
            if (data.hasOwnProperty('capacity')) {
                obj['capacity'] = ApiClient.convertToType(data['capacity'], 'Number');
            }
            if (data.hasOwnProperty('consumed')) {
                obj['consumed'] = ShipFuelConsumed.constructFromObject(data['consumed']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipFuel</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipFuel</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipFuel.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `consumed`
        if (data['consumed']) { // data not null
          ShipFuelConsumed.validateJSON(data['consumed']);
        }

        return true;
    }


}

ShipFuel.RequiredProperties = ["current", "capacity"];

/**
 * The current amount of fuel in the ship's tanks.
 * @member {Number} current
 */
ShipFuel.prototype['current'] = undefined;

/**
 * The maximum amount of fuel the ship's tanks can hold.
 * @member {Number} capacity
 */
ShipFuel.prototype['capacity'] = undefined;

/**
 * @member {module:models/ShipFuelConsumed} consumed
 */
ShipFuel.prototype['consumed'] = undefined;






export default ShipFuel;

