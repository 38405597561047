/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipRequirements from './ShipRequirements';

/**
 * The ShipModule model module.
 * @module models/ShipModule
 * @version 2.0.0
 */
class ShipModule {
    /**
     * Constructs a new <code>ShipModule</code>.
     * A module can be installed in a ship and provides a set of capabilities such as storage space or quarters for crew. Module installations are permanent.
     * @alias module:models/ShipModule
     * @param symbol {module:models/ShipModule.SymbolEnum} The symbol of the module.
     * @param name {String} Name of this module.
     * @param description {String} Description of this module.
     * @param requirements {module:models/ShipRequirements} 
     */
    constructor(symbol, name, description, requirements) { 
        
        ShipModule.initialize(this, symbol, name, description, requirements);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, name, description, requirements) { 
        obj['symbol'] = symbol;
        obj['name'] = name;
        obj['description'] = description;
        obj['requirements'] = requirements;
    }

    /**
     * Constructs a <code>ShipModule</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipModule} obj Optional instance to populate.
     * @return {module:models/ShipModule} The populated <code>ShipModule</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipModule();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('capacity')) {
                obj['capacity'] = ApiClient.convertToType(data['capacity'], 'Number');
            }
            if (data.hasOwnProperty('range')) {
                obj['range'] = ApiClient.convertToType(data['range'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('requirements')) {
                obj['requirements'] = ShipRequirements.constructFromObject(data['requirements']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipModule</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipModule</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipModule.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // validate the optional field `requirements`
        if (data['requirements']) { // data not null
          ShipRequirements.validateJSON(data['requirements']);
        }

        return true;
    }


}

ShipModule.RequiredProperties = ["symbol", "name", "description", "requirements"];

/**
 * The symbol of the module.
 * @member {module:models/ShipModule.SymbolEnum} symbol
 */
ShipModule.prototype['symbol'] = undefined;

/**
 * Modules that provide capacity, such as cargo hold or crew quarters will show this value to denote how much of a bonus the module grants.
 * @member {Number} capacity
 */
ShipModule.prototype['capacity'] = undefined;

/**
 * Modules that have a range will such as a sensor array show this value to denote how far can the module reach with its capabilities.
 * @member {Number} range
 */
ShipModule.prototype['range'] = undefined;

/**
 * Name of this module.
 * @member {String} name
 */
ShipModule.prototype['name'] = undefined;

/**
 * Description of this module.
 * @member {String} description
 */
ShipModule.prototype['description'] = undefined;

/**
 * @member {module:models/ShipRequirements} requirements
 */
ShipModule.prototype['requirements'] = undefined;





/**
 * Allowed values for the <code>symbol</code> property.
 * @enum {String}
 * @readonly
 */
ShipModule['SymbolEnum'] = {

    /**
     * value: "MODULE_MINERAL_PROCESSOR_I"
     * @const
     */
    "MINERAL_PROCESSOR_I": "MODULE_MINERAL_PROCESSOR_I",

    /**
     * value: "MODULE_GAS_PROCESSOR_I"
     * @const
     */
    "GAS_PROCESSOR_I": "MODULE_GAS_PROCESSOR_I",

    /**
     * value: "MODULE_CARGO_HOLD_I"
     * @const
     */
    "CARGO_HOLD_I": "MODULE_CARGO_HOLD_I",

    /**
     * value: "MODULE_CARGO_HOLD_II"
     * @const
     */
    "CARGO_HOLD_II": "MODULE_CARGO_HOLD_II",

    /**
     * value: "MODULE_CARGO_HOLD_III"
     * @const
     */
    "CARGO_HOLD_III": "MODULE_CARGO_HOLD_III",

    /**
     * value: "MODULE_CREW_QUARTERS_I"
     * @const
     */
    "CREW_QUARTERS_I": "MODULE_CREW_QUARTERS_I",

    /**
     * value: "MODULE_ENVOY_QUARTERS_I"
     * @const
     */
    "ENVOY_QUARTERS_I": "MODULE_ENVOY_QUARTERS_I",

    /**
     * value: "MODULE_PASSENGER_CABIN_I"
     * @const
     */
    "PASSENGER_CABIN_I": "MODULE_PASSENGER_CABIN_I",

    /**
     * value: "MODULE_MICRO_REFINERY_I"
     * @const
     */
    "MICRO_REFINERY_I": "MODULE_MICRO_REFINERY_I",

    /**
     * value: "MODULE_ORE_REFINERY_I"
     * @const
     */
    "ORE_REFINERY_I": "MODULE_ORE_REFINERY_I",

    /**
     * value: "MODULE_FUEL_REFINERY_I"
     * @const
     */
    "FUEL_REFINERY_I": "MODULE_FUEL_REFINERY_I",

    /**
     * value: "MODULE_SCIENCE_LAB_I"
     * @const
     */
    "SCIENCE_LAB_I": "MODULE_SCIENCE_LAB_I",

    /**
     * value: "MODULE_JUMP_DRIVE_I"
     * @const
     */
    "JUMP_DRIVE_I": "MODULE_JUMP_DRIVE_I",

    /**
     * value: "MODULE_JUMP_DRIVE_II"
     * @const
     */
    "JUMP_DRIVE_II": "MODULE_JUMP_DRIVE_II",

    /**
     * value: "MODULE_JUMP_DRIVE_III"
     * @const
     */
    "JUMP_DRIVE_III": "MODULE_JUMP_DRIVE_III",

    /**
     * value: "MODULE_WARP_DRIVE_I"
     * @const
     */
    "WARP_DRIVE_I": "MODULE_WARP_DRIVE_I",

    /**
     * value: "MODULE_WARP_DRIVE_II"
     * @const
     */
    "WARP_DRIVE_II": "MODULE_WARP_DRIVE_II",

    /**
     * value: "MODULE_WARP_DRIVE_III"
     * @const
     */
    "WARP_DRIVE_III": "MODULE_WARP_DRIVE_III",

    /**
     * value: "MODULE_SHIELD_GENERATOR_I"
     * @const
     */
    "SHIELD_GENERATOR_I": "MODULE_SHIELD_GENERATOR_I",

    /**
     * value: "MODULE_SHIELD_GENERATOR_II"
     * @const
     */
    "SHIELD_GENERATOR_II": "MODULE_SHIELD_GENERATOR_II"
};



export default ShipModule;

