/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Agent from './Agent';
import Contract from './Contract';
import Faction from './Faction';
import Ship from './Ship';

/**
 * The Register201ResponseData model module.
 * @module models/Register201ResponseData
 * @version 2.0.0
 */
class Register201ResponseData {
    /**
     * Constructs a new <code>Register201ResponseData</code>.
     * @alias module:models/Register201ResponseData
     * @param agent {module:models/Agent} 
     * @param contract {module:models/Contract} 
     * @param faction {module:models/Faction} 
     * @param ship {module:models/Ship} 
     * @param token {String} A Bearer token for accessing secured API endpoints.
     */
    constructor(agent, contract, faction, ship, token) { 
        
        Register201ResponseData.initialize(this, agent, contract, faction, ship, token);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, agent, contract, faction, ship, token) { 
        obj['agent'] = agent;
        obj['contract'] = contract;
        obj['faction'] = faction;
        obj['ship'] = ship;
        obj['token'] = token;
    }

    /**
     * Constructs a <code>Register201ResponseData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/Register201ResponseData} obj Optional instance to populate.
     * @return {module:models/Register201ResponseData} The populated <code>Register201ResponseData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Register201ResponseData();

            if (data.hasOwnProperty('agent')) {
                obj['agent'] = Agent.constructFromObject(data['agent']);
            }
            if (data.hasOwnProperty('contract')) {
                obj['contract'] = Contract.constructFromObject(data['contract']);
            }
            if (data.hasOwnProperty('faction')) {
                obj['faction'] = Faction.constructFromObject(data['faction']);
            }
            if (data.hasOwnProperty('ship')) {
                obj['ship'] = Ship.constructFromObject(data['ship']);
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Register201ResponseData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Register201ResponseData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Register201ResponseData.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `agent`
        if (data['agent']) { // data not null
          Agent.validateJSON(data['agent']);
        }
        // validate the optional field `contract`
        if (data['contract']) { // data not null
          Contract.validateJSON(data['contract']);
        }
        // validate the optional field `faction`
        if (data['faction']) { // data not null
          Faction.validateJSON(data['faction']);
        }
        // validate the optional field `ship`
        if (data['ship']) { // data not null
          Ship.validateJSON(data['ship']);
        }
        // ensure the json data is a string
        if (data['token'] && !(typeof data['token'] === 'string' || data['token'] instanceof String)) {
            throw new Error("Expected the field `token` to be a primitive type in the JSON string but got " + data['token']);
        }

        return true;
    }


}

Register201ResponseData.RequiredProperties = ["agent", "contract", "faction", "ship", "token"];

/**
 * @member {module:models/Agent} agent
 */
Register201ResponseData.prototype['agent'] = undefined;

/**
 * @member {module:models/Contract} contract
 */
Register201ResponseData.prototype['contract'] = undefined;

/**
 * @member {module:models/Faction} faction
 */
Register201ResponseData.prototype['faction'] = undefined;

/**
 * @member {module:models/Ship} ship
 */
Register201ResponseData.prototype['ship'] = undefined;

/**
 * A Bearer token for accessing secured API endpoints.
 * @member {String} token
 */
Register201ResponseData.prototype['token'] = undefined;






export default Register201ResponseData;

