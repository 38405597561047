/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import GetStatus200ResponseLeaderboardsMostCreditsInner from './GetStatus200ResponseLeaderboardsMostCreditsInner';
import GetStatus200ResponseLeaderboardsMostSubmittedChartsInner from './GetStatus200ResponseLeaderboardsMostSubmittedChartsInner';

/**
 * The GetStatus200ResponseLeaderboards model module.
 * @module models/GetStatus200ResponseLeaderboards
 * @version 2.0.0
 */
class GetStatus200ResponseLeaderboards {
    /**
     * Constructs a new <code>GetStatus200ResponseLeaderboards</code>.
     * @alias module:models/GetStatus200ResponseLeaderboards
     * @param mostCredits {Array.<module:models/GetStatus200ResponseLeaderboardsMostCreditsInner>} Top agents with the most credits.
     * @param mostSubmittedCharts {Array.<module:models/GetStatus200ResponseLeaderboardsMostSubmittedChartsInner>} Top agents with the most charted submitted.
     */
    constructor(mostCredits, mostSubmittedCharts) { 
        
        GetStatus200ResponseLeaderboards.initialize(this, mostCredits, mostSubmittedCharts);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, mostCredits, mostSubmittedCharts) { 
        obj['mostCredits'] = mostCredits;
        obj['mostSubmittedCharts'] = mostSubmittedCharts;
    }

    /**
     * Constructs a <code>GetStatus200ResponseLeaderboards</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/GetStatus200ResponseLeaderboards} obj Optional instance to populate.
     * @return {module:models/GetStatus200ResponseLeaderboards} The populated <code>GetStatus200ResponseLeaderboards</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetStatus200ResponseLeaderboards();

            if (data.hasOwnProperty('mostCredits')) {
                obj['mostCredits'] = ApiClient.convertToType(data['mostCredits'], [GetStatus200ResponseLeaderboardsMostCreditsInner]);
            }
            if (data.hasOwnProperty('mostSubmittedCharts')) {
                obj['mostSubmittedCharts'] = ApiClient.convertToType(data['mostSubmittedCharts'], [GetStatus200ResponseLeaderboardsMostSubmittedChartsInner]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>GetStatus200ResponseLeaderboards</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>GetStatus200ResponseLeaderboards</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of GetStatus200ResponseLeaderboards.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['mostCredits']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['mostCredits'])) {
                throw new Error("Expected the field `mostCredits` to be an array in the JSON data but got " + data['mostCredits']);
            }
            // validate the optional field `mostCredits` (array)
            for (const item of data['mostCredits']) {
                GetStatus200ResponseLeaderboardsMostCreditsInner.validateJSON(item);
            };
        }
        if (data['mostSubmittedCharts']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['mostSubmittedCharts'])) {
                throw new Error("Expected the field `mostSubmittedCharts` to be an array in the JSON data but got " + data['mostSubmittedCharts']);
            }
            // validate the optional field `mostSubmittedCharts` (array)
            for (const item of data['mostSubmittedCharts']) {
                GetStatus200ResponseLeaderboardsMostSubmittedChartsInner.validateJSON(item);
            };
        }

        return true;
    }


}

GetStatus200ResponseLeaderboards.RequiredProperties = ["mostCredits", "mostSubmittedCharts"];

/**
 * Top agents with the most credits.
 * @member {Array.<module:models/GetStatus200ResponseLeaderboardsMostCreditsInner>} mostCredits
 */
GetStatus200ResponseLeaderboards.prototype['mostCredits'] = undefined;

/**
 * Top agents with the most charted submitted.
 * @member {Array.<module:models/GetStatus200ResponseLeaderboardsMostSubmittedChartsInner>} mostSubmittedCharts
 */
GetStatus200ResponseLeaderboards.prototype['mostSubmittedCharts'] = undefined;






export default GetStatus200ResponseLeaderboards;

