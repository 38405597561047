/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class WaypointTraitSymbol.
* @enum {}
* @readonly
*/
export default class WaypointTraitSymbol {
    
        /**
         * value: "UNCHARTED"
         * @const
         */
        "UNCHARTED" = "UNCHARTED";

    
        /**
         * value: "UNDER_CONSTRUCTION"
         * @const
         */
        "UNDER_CONSTRUCTION" = "UNDER_CONSTRUCTION";

    
        /**
         * value: "MARKETPLACE"
         * @const
         */
        "MARKETPLACE" = "MARKETPLACE";

    
        /**
         * value: "SHIPYARD"
         * @const
         */
        "SHIPYARD" = "SHIPYARD";

    
        /**
         * value: "OUTPOST"
         * @const
         */
        "OUTPOST" = "OUTPOST";

    
        /**
         * value: "SCATTERED_SETTLEMENTS"
         * @const
         */
        "SCATTERED_SETTLEMENTS" = "SCATTERED_SETTLEMENTS";

    
        /**
         * value: "SPRAWLING_CITIES"
         * @const
         */
        "SPRAWLING_CITIES" = "SPRAWLING_CITIES";

    
        /**
         * value: "MEGA_STRUCTURES"
         * @const
         */
        "MEGA_STRUCTURES" = "MEGA_STRUCTURES";

    
        /**
         * value: "PIRATE_BASE"
         * @const
         */
        "PIRATE_BASE" = "PIRATE_BASE";

    
        /**
         * value: "OVERCROWDED"
         * @const
         */
        "OVERCROWDED" = "OVERCROWDED";

    
        /**
         * value: "HIGH_TECH"
         * @const
         */
        "HIGH_TECH" = "HIGH_TECH";

    
        /**
         * value: "CORRUPT"
         * @const
         */
        "CORRUPT" = "CORRUPT";

    
        /**
         * value: "BUREAUCRATIC"
         * @const
         */
        "BUREAUCRATIC" = "BUREAUCRATIC";

    
        /**
         * value: "TRADING_HUB"
         * @const
         */
        "TRADING_HUB" = "TRADING_HUB";

    
        /**
         * value: "INDUSTRIAL"
         * @const
         */
        "INDUSTRIAL" = "INDUSTRIAL";

    
        /**
         * value: "BLACK_MARKET"
         * @const
         */
        "BLACK_MARKET" = "BLACK_MARKET";

    
        /**
         * value: "RESEARCH_FACILITY"
         * @const
         */
        "RESEARCH_FACILITY" = "RESEARCH_FACILITY";

    
        /**
         * value: "MILITARY_BASE"
         * @const
         */
        "MILITARY_BASE" = "MILITARY_BASE";

    
        /**
         * value: "SURVEILLANCE_OUTPOST"
         * @const
         */
        "SURVEILLANCE_OUTPOST" = "SURVEILLANCE_OUTPOST";

    
        /**
         * value: "EXPLORATION_OUTPOST"
         * @const
         */
        "EXPLORATION_OUTPOST" = "EXPLORATION_OUTPOST";

    
        /**
         * value: "MINERAL_DEPOSITS"
         * @const
         */
        "MINERAL_DEPOSITS" = "MINERAL_DEPOSITS";

    
        /**
         * value: "COMMON_METAL_DEPOSITS"
         * @const
         */
        "COMMON_METAL_DEPOSITS" = "COMMON_METAL_DEPOSITS";

    
        /**
         * value: "PRECIOUS_METAL_DEPOSITS"
         * @const
         */
        "PRECIOUS_METAL_DEPOSITS" = "PRECIOUS_METAL_DEPOSITS";

    
        /**
         * value: "RARE_METAL_DEPOSITS"
         * @const
         */
        "RARE_METAL_DEPOSITS" = "RARE_METAL_DEPOSITS";

    
        /**
         * value: "METHANE_POOLS"
         * @const
         */
        "METHANE_POOLS" = "METHANE_POOLS";

    
        /**
         * value: "ICE_CRYSTALS"
         * @const
         */
        "ICE_CRYSTALS" = "ICE_CRYSTALS";

    
        /**
         * value: "EXPLOSIVE_GASES"
         * @const
         */
        "EXPLOSIVE_GASES" = "EXPLOSIVE_GASES";

    
        /**
         * value: "STRONG_MAGNETOSPHERE"
         * @const
         */
        "STRONG_MAGNETOSPHERE" = "STRONG_MAGNETOSPHERE";

    
        /**
         * value: "VIBRANT_AURORAS"
         * @const
         */
        "VIBRANT_AURORAS" = "VIBRANT_AURORAS";

    
        /**
         * value: "SALT_FLATS"
         * @const
         */
        "SALT_FLATS" = "SALT_FLATS";

    
        /**
         * value: "CANYONS"
         * @const
         */
        "CANYONS" = "CANYONS";

    
        /**
         * value: "PERPETUAL_DAYLIGHT"
         * @const
         */
        "PERPETUAL_DAYLIGHT" = "PERPETUAL_DAYLIGHT";

    
        /**
         * value: "PERPETUAL_OVERCAST"
         * @const
         */
        "PERPETUAL_OVERCAST" = "PERPETUAL_OVERCAST";

    
        /**
         * value: "DRY_SEABEDS"
         * @const
         */
        "DRY_SEABEDS" = "DRY_SEABEDS";

    
        /**
         * value: "MAGMA_SEAS"
         * @const
         */
        "MAGMA_SEAS" = "MAGMA_SEAS";

    
        /**
         * value: "SUPERVOLCANOES"
         * @const
         */
        "SUPERVOLCANOES" = "SUPERVOLCANOES";

    
        /**
         * value: "ASH_CLOUDS"
         * @const
         */
        "ASH_CLOUDS" = "ASH_CLOUDS";

    
        /**
         * value: "VAST_RUINS"
         * @const
         */
        "VAST_RUINS" = "VAST_RUINS";

    
        /**
         * value: "MUTATED_FLORA"
         * @const
         */
        "MUTATED_FLORA" = "MUTATED_FLORA";

    
        /**
         * value: "TERRAFORMED"
         * @const
         */
        "TERRAFORMED" = "TERRAFORMED";

    
        /**
         * value: "EXTREME_TEMPERATURES"
         * @const
         */
        "EXTREME_TEMPERATURES" = "EXTREME_TEMPERATURES";

    
        /**
         * value: "EXTREME_PRESSURE"
         * @const
         */
        "EXTREME_PRESSURE" = "EXTREME_PRESSURE";

    
        /**
         * value: "DIVERSE_LIFE"
         * @const
         */
        "DIVERSE_LIFE" = "DIVERSE_LIFE";

    
        /**
         * value: "SCARCE_LIFE"
         * @const
         */
        "SCARCE_LIFE" = "SCARCE_LIFE";

    
        /**
         * value: "FOSSILS"
         * @const
         */
        "FOSSILS" = "FOSSILS";

    
        /**
         * value: "WEAK_GRAVITY"
         * @const
         */
        "WEAK_GRAVITY" = "WEAK_GRAVITY";

    
        /**
         * value: "STRONG_GRAVITY"
         * @const
         */
        "STRONG_GRAVITY" = "STRONG_GRAVITY";

    
        /**
         * value: "CRUSHING_GRAVITY"
         * @const
         */
        "CRUSHING_GRAVITY" = "CRUSHING_GRAVITY";

    
        /**
         * value: "TOXIC_ATMOSPHERE"
         * @const
         */
        "TOXIC_ATMOSPHERE" = "TOXIC_ATMOSPHERE";

    
        /**
         * value: "CORROSIVE_ATMOSPHERE"
         * @const
         */
        "CORROSIVE_ATMOSPHERE" = "CORROSIVE_ATMOSPHERE";

    
        /**
         * value: "BREATHABLE_ATMOSPHERE"
         * @const
         */
        "BREATHABLE_ATMOSPHERE" = "BREATHABLE_ATMOSPHERE";

    
        /**
         * value: "THIN_ATMOSPHERE"
         * @const
         */
        "THIN_ATMOSPHERE" = "THIN_ATMOSPHERE";

    
        /**
         * value: "JOVIAN"
         * @const
         */
        "JOVIAN" = "JOVIAN";

    
        /**
         * value: "ROCKY"
         * @const
         */
        "ROCKY" = "ROCKY";

    
        /**
         * value: "VOLCANIC"
         * @const
         */
        "VOLCANIC" = "VOLCANIC";

    
        /**
         * value: "FROZEN"
         * @const
         */
        "FROZEN" = "FROZEN";

    
        /**
         * value: "SWAMP"
         * @const
         */
        "SWAMP" = "SWAMP";

    
        /**
         * value: "BARREN"
         * @const
         */
        "BARREN" = "BARREN";

    
        /**
         * value: "TEMPERATE"
         * @const
         */
        "TEMPERATE" = "TEMPERATE";

    
        /**
         * value: "JUNGLE"
         * @const
         */
        "JUNGLE" = "JUNGLE";

    
        /**
         * value: "OCEAN"
         * @const
         */
        "OCEAN" = "OCEAN";

    
        /**
         * value: "RADIOACTIVE"
         * @const
         */
        "RADIOACTIVE" = "RADIOACTIVE";

    
        /**
         * value: "MICRO_GRAVITY_ANOMALIES"
         * @const
         */
        "MICRO_GRAVITY_ANOMALIES" = "MICRO_GRAVITY_ANOMALIES";

    
        /**
         * value: "DEBRIS_CLUSTER"
         * @const
         */
        "DEBRIS_CLUSTER" = "DEBRIS_CLUSTER";

    
        /**
         * value: "DEEP_CRATERS"
         * @const
         */
        "DEEP_CRATERS" = "DEEP_CRATERS";

    
        /**
         * value: "SHALLOW_CRATERS"
         * @const
         */
        "SHALLOW_CRATERS" = "SHALLOW_CRATERS";

    
        /**
         * value: "UNSTABLE_COMPOSITION"
         * @const
         */
        "UNSTABLE_COMPOSITION" = "UNSTABLE_COMPOSITION";

    
        /**
         * value: "HOLLOWED_INTERIOR"
         * @const
         */
        "HOLLOWED_INTERIOR" = "HOLLOWED_INTERIOR";

    
        /**
         * value: "STRIPPED"
         * @const
         */
        "STRIPPED" = "STRIPPED";

    

    /**
    * Returns a <code>WaypointTraitSymbol</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/WaypointTraitSymbol} The enum <code>WaypointTraitSymbol</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

