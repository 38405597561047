/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ContractDeliverGood model module.
 * @module models/ContractDeliverGood
 * @version 2.0.0
 */
class ContractDeliverGood {
    /**
     * Constructs a new <code>ContractDeliverGood</code>.
     * The details of a delivery contract. Includes the type of good, units needed, and the destination.
     * @alias module:models/ContractDeliverGood
     * @param tradeSymbol {String} The symbol of the trade good to deliver.
     * @param destinationSymbol {String} The destination where goods need to be delivered.
     * @param unitsRequired {Number} The number of units that need to be delivered on this contract.
     * @param unitsFulfilled {Number} The number of units fulfilled on this contract.
     */
    constructor(tradeSymbol, destinationSymbol, unitsRequired, unitsFulfilled) { 
        
        ContractDeliverGood.initialize(this, tradeSymbol, destinationSymbol, unitsRequired, unitsFulfilled);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, tradeSymbol, destinationSymbol, unitsRequired, unitsFulfilled) { 
        obj['tradeSymbol'] = tradeSymbol;
        obj['destinationSymbol'] = destinationSymbol;
        obj['unitsRequired'] = unitsRequired;
        obj['unitsFulfilled'] = unitsFulfilled;
    }

    /**
     * Constructs a <code>ContractDeliverGood</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ContractDeliverGood} obj Optional instance to populate.
     * @return {module:models/ContractDeliverGood} The populated <code>ContractDeliverGood</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ContractDeliverGood();

            if (data.hasOwnProperty('tradeSymbol')) {
                obj['tradeSymbol'] = ApiClient.convertToType(data['tradeSymbol'], 'String');
            }
            if (data.hasOwnProperty('destinationSymbol')) {
                obj['destinationSymbol'] = ApiClient.convertToType(data['destinationSymbol'], 'String');
            }
            if (data.hasOwnProperty('unitsRequired')) {
                obj['unitsRequired'] = ApiClient.convertToType(data['unitsRequired'], 'Number');
            }
            if (data.hasOwnProperty('unitsFulfilled')) {
                obj['unitsFulfilled'] = ApiClient.convertToType(data['unitsFulfilled'], 'Number');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ContractDeliverGood</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ContractDeliverGood</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ContractDeliverGood.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['tradeSymbol'] && !(typeof data['tradeSymbol'] === 'string' || data['tradeSymbol'] instanceof String)) {
            throw new Error("Expected the field `tradeSymbol` to be a primitive type in the JSON string but got " + data['tradeSymbol']);
        }
        // ensure the json data is a string
        if (data['destinationSymbol'] && !(typeof data['destinationSymbol'] === 'string' || data['destinationSymbol'] instanceof String)) {
            throw new Error("Expected the field `destinationSymbol` to be a primitive type in the JSON string but got " + data['destinationSymbol']);
        }

        return true;
    }


}

ContractDeliverGood.RequiredProperties = ["tradeSymbol", "destinationSymbol", "unitsRequired", "unitsFulfilled"];

/**
 * The symbol of the trade good to deliver.
 * @member {String} tradeSymbol
 */
ContractDeliverGood.prototype['tradeSymbol'] = undefined;

/**
 * The destination where goods need to be delivered.
 * @member {String} destinationSymbol
 */
ContractDeliverGood.prototype['destinationSymbol'] = undefined;

/**
 * The number of units that need to be delivered on this contract.
 * @member {Number} unitsRequired
 */
ContractDeliverGood.prototype['unitsRequired'] = undefined;

/**
 * The number of units fulfilled on this contract.
 * @member {Number} unitsFulfilled
 */
ContractDeliverGood.prototype['unitsFulfilled'] = undefined;






export default ContractDeliverGood;

