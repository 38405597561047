/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipCargoItem from './ShipCargoItem';

/**
 * The ShipCargo model module.
 * @module models/ShipCargo
 * @version 2.0.0
 */
class ShipCargo {
    /**
     * Constructs a new <code>ShipCargo</code>.
     * Ship cargo details.
     * @alias module:models/ShipCargo
     * @param capacity {Number} The max number of items that can be stored in the cargo hold.
     * @param units {Number} The number of items currently stored in the cargo hold.
     * @param inventory {Array.<module:models/ShipCargoItem>} The items currently in the cargo hold.
     */
    constructor(capacity, units, inventory) { 
        
        ShipCargo.initialize(this, capacity, units, inventory);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, capacity, units, inventory) { 
        obj['capacity'] = capacity;
        obj['units'] = units;
        obj['inventory'] = inventory;
    }

    /**
     * Constructs a <code>ShipCargo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipCargo} obj Optional instance to populate.
     * @return {module:models/ShipCargo} The populated <code>ShipCargo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipCargo();

            if (data.hasOwnProperty('capacity')) {
                obj['capacity'] = ApiClient.convertToType(data['capacity'], 'Number');
            }
            if (data.hasOwnProperty('units')) {
                obj['units'] = ApiClient.convertToType(data['units'], 'Number');
            }
            if (data.hasOwnProperty('inventory')) {
                obj['inventory'] = ApiClient.convertToType(data['inventory'], [ShipCargoItem]);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipCargo</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipCargo</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipCargo.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        if (data['inventory']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['inventory'])) {
                throw new Error("Expected the field `inventory` to be an array in the JSON data but got " + data['inventory']);
            }
            // validate the optional field `inventory` (array)
            for (const item of data['inventory']) {
                ShipCargoItem.validateJSON(item);
            };
        }

        return true;
    }


}

ShipCargo.RequiredProperties = ["capacity", "units", "inventory"];

/**
 * The max number of items that can be stored in the cargo hold.
 * @member {Number} capacity
 */
ShipCargo.prototype['capacity'] = undefined;

/**
 * The number of items currently stored in the cargo hold.
 * @member {Number} units
 */
ShipCargo.prototype['units'] = undefined;

/**
 * The items currently in the cargo hold.
 * @member {Array.<module:models/ShipCargoItem>} inventory
 */
ShipCargo.prototype['inventory'] = undefined;






export default ShipCargo;

