/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class TradeSymbol.
* @enum {}
* @readonly
*/
export default class TradeSymbol {
    
        /**
         * value: "PRECIOUS_STONES"
         * @const
         */
        "PRECIOUS_STONES" = "PRECIOUS_STONES";

    
        /**
         * value: "QUARTZ_SAND"
         * @const
         */
        "QUARTZ_SAND" = "QUARTZ_SAND";

    
        /**
         * value: "SILICON_CRYSTALS"
         * @const
         */
        "SILICON_CRYSTALS" = "SILICON_CRYSTALS";

    
        /**
         * value: "AMMONIA_ICE"
         * @const
         */
        "AMMONIA_ICE" = "AMMONIA_ICE";

    
        /**
         * value: "LIQUID_HYDROGEN"
         * @const
         */
        "LIQUID_HYDROGEN" = "LIQUID_HYDROGEN";

    
        /**
         * value: "LIQUID_NITROGEN"
         * @const
         */
        "LIQUID_NITROGEN" = "LIQUID_NITROGEN";

    
        /**
         * value: "ICE_WATER"
         * @const
         */
        "ICE_WATER" = "ICE_WATER";

    
        /**
         * value: "EXOTIC_MATTER"
         * @const
         */
        "EXOTIC_MATTER" = "EXOTIC_MATTER";

    
        /**
         * value: "ADVANCED_CIRCUITRY"
         * @const
         */
        "ADVANCED_CIRCUITRY" = "ADVANCED_CIRCUITRY";

    
        /**
         * value: "GRAVITON_EMITTERS"
         * @const
         */
        "GRAVITON_EMITTERS" = "GRAVITON_EMITTERS";

    
        /**
         * value: "IRON"
         * @const
         */
        "IRON" = "IRON";

    
        /**
         * value: "IRON_ORE"
         * @const
         */
        "IRON_ORE" = "IRON_ORE";

    
        /**
         * value: "COPPER"
         * @const
         */
        "COPPER" = "COPPER";

    
        /**
         * value: "COPPER_ORE"
         * @const
         */
        "COPPER_ORE" = "COPPER_ORE";

    
        /**
         * value: "ALUMINUM"
         * @const
         */
        "ALUMINUM" = "ALUMINUM";

    
        /**
         * value: "ALUMINUM_ORE"
         * @const
         */
        "ALUMINUM_ORE" = "ALUMINUM_ORE";

    
        /**
         * value: "SILVER"
         * @const
         */
        "SILVER" = "SILVER";

    
        /**
         * value: "SILVER_ORE"
         * @const
         */
        "SILVER_ORE" = "SILVER_ORE";

    
        /**
         * value: "GOLD"
         * @const
         */
        "GOLD" = "GOLD";

    
        /**
         * value: "GOLD_ORE"
         * @const
         */
        "GOLD_ORE" = "GOLD_ORE";

    
        /**
         * value: "PLATINUM"
         * @const
         */
        "PLATINUM" = "PLATINUM";

    
        /**
         * value: "PLATINUM_ORE"
         * @const
         */
        "PLATINUM_ORE" = "PLATINUM_ORE";

    
        /**
         * value: "DIAMONDS"
         * @const
         */
        "DIAMONDS" = "DIAMONDS";

    
        /**
         * value: "URANITE"
         * @const
         */
        "URANITE" = "URANITE";

    
        /**
         * value: "URANITE_ORE"
         * @const
         */
        "URANITE_ORE" = "URANITE_ORE";

    
        /**
         * value: "MERITIUM"
         * @const
         */
        "MERITIUM" = "MERITIUM";

    
        /**
         * value: "MERITIUM_ORE"
         * @const
         */
        "MERITIUM_ORE" = "MERITIUM_ORE";

    
        /**
         * value: "HYDROCARBON"
         * @const
         */
        "HYDROCARBON" = "HYDROCARBON";

    
        /**
         * value: "ANTIMATTER"
         * @const
         */
        "ANTIMATTER" = "ANTIMATTER";

    
        /**
         * value: "FAB_MATS"
         * @const
         */
        "FAB_MATS" = "FAB_MATS";

    
        /**
         * value: "FERTILIZERS"
         * @const
         */
        "FERTILIZERS" = "FERTILIZERS";

    
        /**
         * value: "FABRICS"
         * @const
         */
        "FABRICS" = "FABRICS";

    
        /**
         * value: "FOOD"
         * @const
         */
        "FOOD" = "FOOD";

    
        /**
         * value: "JEWELRY"
         * @const
         */
        "JEWELRY" = "JEWELRY";

    
        /**
         * value: "MACHINERY"
         * @const
         */
        "MACHINERY" = "MACHINERY";

    
        /**
         * value: "FIREARMS"
         * @const
         */
        "FIREARMS" = "FIREARMS";

    
        /**
         * value: "ASSAULT_RIFLES"
         * @const
         */
        "ASSAULT_RIFLES" = "ASSAULT_RIFLES";

    
        /**
         * value: "MILITARY_EQUIPMENT"
         * @const
         */
        "MILITARY_EQUIPMENT" = "MILITARY_EQUIPMENT";

    
        /**
         * value: "EXPLOSIVES"
         * @const
         */
        "EXPLOSIVES" = "EXPLOSIVES";

    
        /**
         * value: "LAB_INSTRUMENTS"
         * @const
         */
        "LAB_INSTRUMENTS" = "LAB_INSTRUMENTS";

    
        /**
         * value: "AMMUNITION"
         * @const
         */
        "AMMUNITION" = "AMMUNITION";

    
        /**
         * value: "ELECTRONICS"
         * @const
         */
        "ELECTRONICS" = "ELECTRONICS";

    
        /**
         * value: "SHIP_PLATING"
         * @const
         */
        "SHIP_PLATING" = "SHIP_PLATING";

    
        /**
         * value: "SHIP_PARTS"
         * @const
         */
        "SHIP_PARTS" = "SHIP_PARTS";

    
        /**
         * value: "EQUIPMENT"
         * @const
         */
        "EQUIPMENT" = "EQUIPMENT";

    
        /**
         * value: "FUEL"
         * @const
         */
        "FUEL" = "FUEL";

    
        /**
         * value: "MEDICINE"
         * @const
         */
        "MEDICINE" = "MEDICINE";

    
        /**
         * value: "DRUGS"
         * @const
         */
        "DRUGS" = "DRUGS";

    
        /**
         * value: "CLOTHING"
         * @const
         */
        "CLOTHING" = "CLOTHING";

    
        /**
         * value: "MICROPROCESSORS"
         * @const
         */
        "MICROPROCESSORS" = "MICROPROCESSORS";

    
        /**
         * value: "PLASTICS"
         * @const
         */
        "PLASTICS" = "PLASTICS";

    
        /**
         * value: "POLYNUCLEOTIDES"
         * @const
         */
        "POLYNUCLEOTIDES" = "POLYNUCLEOTIDES";

    
        /**
         * value: "BIOCOMPOSITES"
         * @const
         */
        "BIOCOMPOSITES" = "BIOCOMPOSITES";

    
        /**
         * value: "QUANTUM_STABILIZERS"
         * @const
         */
        "QUANTUM_STABILIZERS" = "QUANTUM_STABILIZERS";

    
        /**
         * value: "NANOBOTS"
         * @const
         */
        "NANOBOTS" = "NANOBOTS";

    
        /**
         * value: "AI_MAINFRAMES"
         * @const
         */
        "AI_MAINFRAMES" = "AI_MAINFRAMES";

    
        /**
         * value: "QUANTUM_DRIVES"
         * @const
         */
        "QUANTUM_DRIVES" = "QUANTUM_DRIVES";

    
        /**
         * value: "ROBOTIC_DRONES"
         * @const
         */
        "ROBOTIC_DRONES" = "ROBOTIC_DRONES";

    
        /**
         * value: "CYBER_IMPLANTS"
         * @const
         */
        "CYBER_IMPLANTS" = "CYBER_IMPLANTS";

    
        /**
         * value: "GENE_THERAPEUTICS"
         * @const
         */
        "GENE_THERAPEUTICS" = "GENE_THERAPEUTICS";

    
        /**
         * value: "NEURAL_CHIPS"
         * @const
         */
        "NEURAL_CHIPS" = "NEURAL_CHIPS";

    
        /**
         * value: "MOOD_REGULATORS"
         * @const
         */
        "MOOD_REGULATORS" = "MOOD_REGULATORS";

    
        /**
         * value: "VIRAL_AGENTS"
         * @const
         */
        "VIRAL_AGENTS" = "VIRAL_AGENTS";

    
        /**
         * value: "MICRO_FUSION_GENERATORS"
         * @const
         */
        "MICRO_FUSION_GENERATORS" = "MICRO_FUSION_GENERATORS";

    
        /**
         * value: "SUPERGRAINS"
         * @const
         */
        "SUPERGRAINS" = "SUPERGRAINS";

    
        /**
         * value: "LASER_RIFLES"
         * @const
         */
        "LASER_RIFLES" = "LASER_RIFLES";

    
        /**
         * value: "HOLOGRAPHICS"
         * @const
         */
        "HOLOGRAPHICS" = "HOLOGRAPHICS";

    
        /**
         * value: "SHIP_SALVAGE"
         * @const
         */
        "SHIP_SALVAGE" = "SHIP_SALVAGE";

    
        /**
         * value: "RELIC_TECH"
         * @const
         */
        "RELIC_TECH" = "RELIC_TECH";

    
        /**
         * value: "NOVEL_LIFEFORMS"
         * @const
         */
        "NOVEL_LIFEFORMS" = "NOVEL_LIFEFORMS";

    
        /**
         * value: "BOTANICAL_SPECIMENS"
         * @const
         */
        "BOTANICAL_SPECIMENS" = "BOTANICAL_SPECIMENS";

    
        /**
         * value: "CULTURAL_ARTIFACTS"
         * @const
         */
        "CULTURAL_ARTIFACTS" = "CULTURAL_ARTIFACTS";

    
        /**
         * value: "FRAME_PROBE"
         * @const
         */
        "FRAME_PROBE" = "FRAME_PROBE";

    
        /**
         * value: "FRAME_DRONE"
         * @const
         */
        "FRAME_DRONE" = "FRAME_DRONE";

    
        /**
         * value: "FRAME_INTERCEPTOR"
         * @const
         */
        "FRAME_INTERCEPTOR" = "FRAME_INTERCEPTOR";

    
        /**
         * value: "FRAME_RACER"
         * @const
         */
        "FRAME_RACER" = "FRAME_RACER";

    
        /**
         * value: "FRAME_FIGHTER"
         * @const
         */
        "FRAME_FIGHTER" = "FRAME_FIGHTER";

    
        /**
         * value: "FRAME_FRIGATE"
         * @const
         */
        "FRAME_FRIGATE" = "FRAME_FRIGATE";

    
        /**
         * value: "FRAME_SHUTTLE"
         * @const
         */
        "FRAME_SHUTTLE" = "FRAME_SHUTTLE";

    
        /**
         * value: "FRAME_EXPLORER"
         * @const
         */
        "FRAME_EXPLORER" = "FRAME_EXPLORER";

    
        /**
         * value: "FRAME_MINER"
         * @const
         */
        "FRAME_MINER" = "FRAME_MINER";

    
        /**
         * value: "FRAME_LIGHT_FREIGHTER"
         * @const
         */
        "FRAME_LIGHT_FREIGHTER" = "FRAME_LIGHT_FREIGHTER";

    
        /**
         * value: "FRAME_HEAVY_FREIGHTER"
         * @const
         */
        "FRAME_HEAVY_FREIGHTER" = "FRAME_HEAVY_FREIGHTER";

    
        /**
         * value: "FRAME_TRANSPORT"
         * @const
         */
        "FRAME_TRANSPORT" = "FRAME_TRANSPORT";

    
        /**
         * value: "FRAME_DESTROYER"
         * @const
         */
        "FRAME_DESTROYER" = "FRAME_DESTROYER";

    
        /**
         * value: "FRAME_CRUISER"
         * @const
         */
        "FRAME_CRUISER" = "FRAME_CRUISER";

    
        /**
         * value: "FRAME_CARRIER"
         * @const
         */
        "FRAME_CARRIER" = "FRAME_CARRIER";

    
        /**
         * value: "REACTOR_SOLAR_I"
         * @const
         */
        "REACTOR_SOLAR_I" = "REACTOR_SOLAR_I";

    
        /**
         * value: "REACTOR_FUSION_I"
         * @const
         */
        "REACTOR_FUSION_I" = "REACTOR_FUSION_I";

    
        /**
         * value: "REACTOR_FISSION_I"
         * @const
         */
        "REACTOR_FISSION_I" = "REACTOR_FISSION_I";

    
        /**
         * value: "REACTOR_CHEMICAL_I"
         * @const
         */
        "REACTOR_CHEMICAL_I" = "REACTOR_CHEMICAL_I";

    
        /**
         * value: "REACTOR_ANTIMATTER_I"
         * @const
         */
        "REACTOR_ANTIMATTER_I" = "REACTOR_ANTIMATTER_I";

    
        /**
         * value: "ENGINE_IMPULSE_DRIVE_I"
         * @const
         */
        "ENGINE_IMPULSE_DRIVE_I" = "ENGINE_IMPULSE_DRIVE_I";

    
        /**
         * value: "ENGINE_ION_DRIVE_I"
         * @const
         */
        "ENGINE_ION_DRIVE_I" = "ENGINE_ION_DRIVE_I";

    
        /**
         * value: "ENGINE_ION_DRIVE_II"
         * @const
         */
        "ENGINE_ION_DRIVE_II" = "ENGINE_ION_DRIVE_II";

    
        /**
         * value: "ENGINE_HYPER_DRIVE_I"
         * @const
         */
        "ENGINE_HYPER_DRIVE_I" = "ENGINE_HYPER_DRIVE_I";

    
        /**
         * value: "MODULE_MINERAL_PROCESSOR_I"
         * @const
         */
        "MODULE_MINERAL_PROCESSOR_I" = "MODULE_MINERAL_PROCESSOR_I";

    
        /**
         * value: "MODULE_GAS_PROCESSOR_I"
         * @const
         */
        "MODULE_GAS_PROCESSOR_I" = "MODULE_GAS_PROCESSOR_I";

    
        /**
         * value: "MODULE_CARGO_HOLD_I"
         * @const
         */
        "MODULE_CARGO_HOLD_I" = "MODULE_CARGO_HOLD_I";

    
        /**
         * value: "MODULE_CARGO_HOLD_II"
         * @const
         */
        "MODULE_CARGO_HOLD_II" = "MODULE_CARGO_HOLD_II";

    
        /**
         * value: "MODULE_CARGO_HOLD_III"
         * @const
         */
        "MODULE_CARGO_HOLD_III" = "MODULE_CARGO_HOLD_III";

    
        /**
         * value: "MODULE_CREW_QUARTERS_I"
         * @const
         */
        "MODULE_CREW_QUARTERS_I" = "MODULE_CREW_QUARTERS_I";

    
        /**
         * value: "MODULE_ENVOY_QUARTERS_I"
         * @const
         */
        "MODULE_ENVOY_QUARTERS_I" = "MODULE_ENVOY_QUARTERS_I";

    
        /**
         * value: "MODULE_PASSENGER_CABIN_I"
         * @const
         */
        "MODULE_PASSENGER_CABIN_I" = "MODULE_PASSENGER_CABIN_I";

    
        /**
         * value: "MODULE_MICRO_REFINERY_I"
         * @const
         */
        "MODULE_MICRO_REFINERY_I" = "MODULE_MICRO_REFINERY_I";

    
        /**
         * value: "MODULE_SCIENCE_LAB_I"
         * @const
         */
        "MODULE_SCIENCE_LAB_I" = "MODULE_SCIENCE_LAB_I";

    
        /**
         * value: "MODULE_JUMP_DRIVE_I"
         * @const
         */
        "MODULE_JUMP_DRIVE_I" = "MODULE_JUMP_DRIVE_I";

    
        /**
         * value: "MODULE_JUMP_DRIVE_II"
         * @const
         */
        "MODULE_JUMP_DRIVE_II" = "MODULE_JUMP_DRIVE_II";

    
        /**
         * value: "MODULE_JUMP_DRIVE_III"
         * @const
         */
        "MODULE_JUMP_DRIVE_III" = "MODULE_JUMP_DRIVE_III";

    
        /**
         * value: "MODULE_WARP_DRIVE_I"
         * @const
         */
        "MODULE_WARP_DRIVE_I" = "MODULE_WARP_DRIVE_I";

    
        /**
         * value: "MODULE_WARP_DRIVE_II"
         * @const
         */
        "MODULE_WARP_DRIVE_II" = "MODULE_WARP_DRIVE_II";

    
        /**
         * value: "MODULE_WARP_DRIVE_III"
         * @const
         */
        "MODULE_WARP_DRIVE_III" = "MODULE_WARP_DRIVE_III";

    
        /**
         * value: "MODULE_SHIELD_GENERATOR_I"
         * @const
         */
        "MODULE_SHIELD_GENERATOR_I" = "MODULE_SHIELD_GENERATOR_I";

    
        /**
         * value: "MODULE_SHIELD_GENERATOR_II"
         * @const
         */
        "MODULE_SHIELD_GENERATOR_II" = "MODULE_SHIELD_GENERATOR_II";

    
        /**
         * value: "MODULE_ORE_REFINERY_I"
         * @const
         */
        "MODULE_ORE_REFINERY_I" = "MODULE_ORE_REFINERY_I";

    
        /**
         * value: "MODULE_FUEL_REFINERY_I"
         * @const
         */
        "MODULE_FUEL_REFINERY_I" = "MODULE_FUEL_REFINERY_I";

    
        /**
         * value: "MOUNT_GAS_SIPHON_I"
         * @const
         */
        "MOUNT_GAS_SIPHON_I" = "MOUNT_GAS_SIPHON_I";

    
        /**
         * value: "MOUNT_GAS_SIPHON_II"
         * @const
         */
        "MOUNT_GAS_SIPHON_II" = "MOUNT_GAS_SIPHON_II";

    
        /**
         * value: "MOUNT_GAS_SIPHON_III"
         * @const
         */
        "MOUNT_GAS_SIPHON_III" = "MOUNT_GAS_SIPHON_III";

    
        /**
         * value: "MOUNT_SURVEYOR_I"
         * @const
         */
        "MOUNT_SURVEYOR_I" = "MOUNT_SURVEYOR_I";

    
        /**
         * value: "MOUNT_SURVEYOR_II"
         * @const
         */
        "MOUNT_SURVEYOR_II" = "MOUNT_SURVEYOR_II";

    
        /**
         * value: "MOUNT_SURVEYOR_III"
         * @const
         */
        "MOUNT_SURVEYOR_III" = "MOUNT_SURVEYOR_III";

    
        /**
         * value: "MOUNT_SENSOR_ARRAY_I"
         * @const
         */
        "MOUNT_SENSOR_ARRAY_I" = "MOUNT_SENSOR_ARRAY_I";

    
        /**
         * value: "MOUNT_SENSOR_ARRAY_II"
         * @const
         */
        "MOUNT_SENSOR_ARRAY_II" = "MOUNT_SENSOR_ARRAY_II";

    
        /**
         * value: "MOUNT_SENSOR_ARRAY_III"
         * @const
         */
        "MOUNT_SENSOR_ARRAY_III" = "MOUNT_SENSOR_ARRAY_III";

    
        /**
         * value: "MOUNT_MINING_LASER_I"
         * @const
         */
        "MOUNT_MINING_LASER_I" = "MOUNT_MINING_LASER_I";

    
        /**
         * value: "MOUNT_MINING_LASER_II"
         * @const
         */
        "MOUNT_MINING_LASER_II" = "MOUNT_MINING_LASER_II";

    
        /**
         * value: "MOUNT_MINING_LASER_III"
         * @const
         */
        "MOUNT_MINING_LASER_III" = "MOUNT_MINING_LASER_III";

    
        /**
         * value: "MOUNT_LASER_CANNON_I"
         * @const
         */
        "MOUNT_LASER_CANNON_I" = "MOUNT_LASER_CANNON_I";

    
        /**
         * value: "MOUNT_MISSILE_LAUNCHER_I"
         * @const
         */
        "MOUNT_MISSILE_LAUNCHER_I" = "MOUNT_MISSILE_LAUNCHER_I";

    
        /**
         * value: "MOUNT_TURRET_I"
         * @const
         */
        "MOUNT_TURRET_I" = "MOUNT_TURRET_I";

    
        /**
         * value: "SHIP_PROBE"
         * @const
         */
        "SHIP_PROBE" = "SHIP_PROBE";

    
        /**
         * value: "SHIP_MINING_DRONE"
         * @const
         */
        "SHIP_MINING_DRONE" = "SHIP_MINING_DRONE";

    
        /**
         * value: "SHIP_SIPHON_DRONE"
         * @const
         */
        "SHIP_SIPHON_DRONE" = "SHIP_SIPHON_DRONE";

    
        /**
         * value: "SHIP_INTERCEPTOR"
         * @const
         */
        "SHIP_INTERCEPTOR" = "SHIP_INTERCEPTOR";

    
        /**
         * value: "SHIP_LIGHT_HAULER"
         * @const
         */
        "SHIP_LIGHT_HAULER" = "SHIP_LIGHT_HAULER";

    
        /**
         * value: "SHIP_COMMAND_FRIGATE"
         * @const
         */
        "SHIP_COMMAND_FRIGATE" = "SHIP_COMMAND_FRIGATE";

    
        /**
         * value: "SHIP_EXPLORER"
         * @const
         */
        "SHIP_EXPLORER" = "SHIP_EXPLORER";

    
        /**
         * value: "SHIP_HEAVY_FREIGHTER"
         * @const
         */
        "SHIP_HEAVY_FREIGHTER" = "SHIP_HEAVY_FREIGHTER";

    
        /**
         * value: "SHIP_LIGHT_SHUTTLE"
         * @const
         */
        "SHIP_LIGHT_SHUTTLE" = "SHIP_LIGHT_SHUTTLE";

    
        /**
         * value: "SHIP_ORE_HOUND"
         * @const
         */
        "SHIP_ORE_HOUND" = "SHIP_ORE_HOUND";

    
        /**
         * value: "SHIP_REFINING_FREIGHTER"
         * @const
         */
        "SHIP_REFINING_FREIGHTER" = "SHIP_REFINING_FREIGHTER";

    
        /**
         * value: "SHIP_SURVEYOR"
         * @const
         */
        "SHIP_SURVEYOR" = "SHIP_SURVEYOR";

    

    /**
    * Returns a <code>TradeSymbol</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:models/TradeSymbol} The enum <code>TradeSymbol</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

