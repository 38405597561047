/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SurveyDeposit from './SurveyDeposit';

/**
 * The Survey model module.
 * @module models/Survey
 * @version 2.0.0
 */
class Survey {
    /**
     * Constructs a new <code>Survey</code>.
     * A resource survey of a waypoint, detailing a specific extraction location and the types of resources that can be found there.
     * @alias module:models/Survey
     * @param signature {String} A unique signature for the location of this survey. This signature is verified when attempting an extraction using this survey.
     * @param symbol {String} The symbol of the waypoint that this survey is for.
     * @param deposits {Array.<module:models/SurveyDeposit>} A list of deposits that can be found at this location. A ship will extract one of these deposits when using this survey in an extraction request. If multiple deposits of the same type are present, the chance of extracting that deposit is increased.
     * @param expiration {Date} The date and time when the survey expires. After this date and time, the survey will no longer be available for extraction.
     * @param size {module:models/Survey.SizeEnum} The size of the deposit. This value indicates how much can be extracted from the survey before it is exhausted.
     */
    constructor(signature, symbol, deposits, expiration, size) { 
        
        Survey.initialize(this, signature, symbol, deposits, expiration, size);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, signature, symbol, deposits, expiration, size) { 
        obj['signature'] = signature;
        obj['symbol'] = symbol;
        obj['deposits'] = deposits;
        obj['expiration'] = expiration;
        obj['size'] = size;
    }

    /**
     * Constructs a <code>Survey</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/Survey} obj Optional instance to populate.
     * @return {module:models/Survey} The populated <code>Survey</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Survey();

            if (data.hasOwnProperty('signature')) {
                obj['signature'] = ApiClient.convertToType(data['signature'], 'String');
            }
            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('deposits')) {
                obj['deposits'] = ApiClient.convertToType(data['deposits'], [SurveyDeposit]);
            }
            if (data.hasOwnProperty('expiration')) {
                obj['expiration'] = ApiClient.convertToType(data['expiration'], 'Date');
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = ApiClient.convertToType(data['size'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Survey</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Survey</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Survey.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['signature'] && !(typeof data['signature'] === 'string' || data['signature'] instanceof String)) {
            throw new Error("Expected the field `signature` to be a primitive type in the JSON string but got " + data['signature']);
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        if (data['deposits']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['deposits'])) {
                throw new Error("Expected the field `deposits` to be an array in the JSON data but got " + data['deposits']);
            }
            // validate the optional field `deposits` (array)
            for (const item of data['deposits']) {
                SurveyDeposit.validateJSON(item);
            };
        }
        // ensure the json data is a string
        if (data['size'] && !(typeof data['size'] === 'string' || data['size'] instanceof String)) {
            throw new Error("Expected the field `size` to be a primitive type in the JSON string but got " + data['size']);
        }

        return true;
    }


}

Survey.RequiredProperties = ["signature", "symbol", "deposits", "expiration", "size"];

/**
 * A unique signature for the location of this survey. This signature is verified when attempting an extraction using this survey.
 * @member {String} signature
 */
Survey.prototype['signature'] = undefined;

/**
 * The symbol of the waypoint that this survey is for.
 * @member {String} symbol
 */
Survey.prototype['symbol'] = undefined;

/**
 * A list of deposits that can be found at this location. A ship will extract one of these deposits when using this survey in an extraction request. If multiple deposits of the same type are present, the chance of extracting that deposit is increased.
 * @member {Array.<module:models/SurveyDeposit>} deposits
 */
Survey.prototype['deposits'] = undefined;

/**
 * The date and time when the survey expires. After this date and time, the survey will no longer be available for extraction.
 * @member {Date} expiration
 */
Survey.prototype['expiration'] = undefined;

/**
 * The size of the deposit. This value indicates how much can be extracted from the survey before it is exhausted.
 * @member {module:models/Survey.SizeEnum} size
 */
Survey.prototype['size'] = undefined;





/**
 * Allowed values for the <code>size</code> property.
 * @enum {String}
 * @readonly
 */
Survey['SizeEnum'] = {

    /**
     * value: "SMALL"
     * @const
     */
    "SMALL": "SMALL",

    /**
     * value: "MODERATE"
     * @const
     */
    "MODERATE": "MODERATE",

    /**
     * value: "LARGE"
     * @const
     */
    "LARGE": "LARGE"
};



export default Survey;

