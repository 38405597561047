/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Cooldown from './Cooldown';
import ShipCargo from './ShipCargo';
import ShipCrew from './ShipCrew';
import ShipEngine from './ShipEngine';
import ShipFrame from './ShipFrame';
import ShipFuel from './ShipFuel';
import ShipModule from './ShipModule';
import ShipMount from './ShipMount';
import ShipNav from './ShipNav';
import ShipReactor from './ShipReactor';
import ShipRegistration from './ShipRegistration';

/**
 * The Ship model module.
 * @module models/Ship
 * @version 2.0.0
 */
class Ship {
    /**
     * Constructs a new <code>Ship</code>.
     * Ship details.
     * @alias module:models/Ship
     * @param symbol {String} The globally unique identifier of the ship in the following format: `[AGENT_SYMBOL]-[HEX_ID]`
     * @param registration {module:models/ShipRegistration} 
     * @param nav {module:models/ShipNav} 
     * @param crew {module:models/ShipCrew} 
     * @param frame {module:models/ShipFrame} 
     * @param reactor {module:models/ShipReactor} 
     * @param engine {module:models/ShipEngine} 
     * @param cooldown {module:models/Cooldown} 
     * @param modules {Array.<module:models/ShipModule>} Modules installed in this ship.
     * @param mounts {Array.<module:models/ShipMount>} Mounts installed in this ship.
     * @param cargo {module:models/ShipCargo} 
     * @param fuel {module:models/ShipFuel} 
     */
    constructor(symbol, registration, nav, crew, frame, reactor, engine, cooldown, modules, mounts, cargo, fuel) { 
        
        Ship.initialize(this, symbol, registration, nav, crew, frame, reactor, engine, cooldown, modules, mounts, cargo, fuel);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, registration, nav, crew, frame, reactor, engine, cooldown, modules, mounts, cargo, fuel) { 
        obj['symbol'] = symbol;
        obj['registration'] = registration;
        obj['nav'] = nav;
        obj['crew'] = crew;
        obj['frame'] = frame;
        obj['reactor'] = reactor;
        obj['engine'] = engine;
        obj['cooldown'] = cooldown;
        obj['modules'] = modules;
        obj['mounts'] = mounts;
        obj['cargo'] = cargo;
        obj['fuel'] = fuel;
    }

    /**
     * Constructs a <code>Ship</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/Ship} obj Optional instance to populate.
     * @return {module:models/Ship} The populated <code>Ship</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Ship();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('registration')) {
                obj['registration'] = ShipRegistration.constructFromObject(data['registration']);
            }
            if (data.hasOwnProperty('nav')) {
                obj['nav'] = ShipNav.constructFromObject(data['nav']);
            }
            if (data.hasOwnProperty('crew')) {
                obj['crew'] = ShipCrew.constructFromObject(data['crew']);
            }
            if (data.hasOwnProperty('frame')) {
                obj['frame'] = ShipFrame.constructFromObject(data['frame']);
            }
            if (data.hasOwnProperty('reactor')) {
                obj['reactor'] = ShipReactor.constructFromObject(data['reactor']);
            }
            if (data.hasOwnProperty('engine')) {
                obj['engine'] = ShipEngine.constructFromObject(data['engine']);
            }
            if (data.hasOwnProperty('cooldown')) {
                obj['cooldown'] = Cooldown.constructFromObject(data['cooldown']);
            }
            if (data.hasOwnProperty('modules')) {
                obj['modules'] = ApiClient.convertToType(data['modules'], [ShipModule]);
            }
            if (data.hasOwnProperty('mounts')) {
                obj['mounts'] = ApiClient.convertToType(data['mounts'], [ShipMount]);
            }
            if (data.hasOwnProperty('cargo')) {
                obj['cargo'] = ShipCargo.constructFromObject(data['cargo']);
            }
            if (data.hasOwnProperty('fuel')) {
                obj['fuel'] = ShipFuel.constructFromObject(data['fuel']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Ship</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Ship</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Ship.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // validate the optional field `registration`
        if (data['registration']) { // data not null
          ShipRegistration.validateJSON(data['registration']);
        }
        // validate the optional field `nav`
        if (data['nav']) { // data not null
          ShipNav.validateJSON(data['nav']);
        }
        // validate the optional field `crew`
        if (data['crew']) { // data not null
          ShipCrew.validateJSON(data['crew']);
        }
        // validate the optional field `frame`
        if (data['frame']) { // data not null
          ShipFrame.validateJSON(data['frame']);
        }
        // validate the optional field `reactor`
        if (data['reactor']) { // data not null
          ShipReactor.validateJSON(data['reactor']);
        }
        // validate the optional field `engine`
        if (data['engine']) { // data not null
          ShipEngine.validateJSON(data['engine']);
        }
        // validate the optional field `cooldown`
        if (data['cooldown']) { // data not null
          Cooldown.validateJSON(data['cooldown']);
        }
        if (data['modules']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['modules'])) {
                throw new Error("Expected the field `modules` to be an array in the JSON data but got " + data['modules']);
            }
            // validate the optional field `modules` (array)
            for (const item of data['modules']) {
                ShipModule.validateJSON(item);
            };
        }
        if (data['mounts']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['mounts'])) {
                throw new Error("Expected the field `mounts` to be an array in the JSON data but got " + data['mounts']);
            }
            // validate the optional field `mounts` (array)
            for (const item of data['mounts']) {
                ShipMount.validateJSON(item);
            };
        }
        // validate the optional field `cargo`
        if (data['cargo']) { // data not null
          ShipCargo.validateJSON(data['cargo']);
        }
        // validate the optional field `fuel`
        if (data['fuel']) { // data not null
          ShipFuel.validateJSON(data['fuel']);
        }

        return true;
    }


}

Ship.RequiredProperties = ["symbol", "registration", "nav", "crew", "frame", "reactor", "engine", "cooldown", "modules", "mounts", "cargo", "fuel"];

/**
 * The globally unique identifier of the ship in the following format: `[AGENT_SYMBOL]-[HEX_ID]`
 * @member {String} symbol
 */
Ship.prototype['symbol'] = undefined;

/**
 * @member {module:models/ShipRegistration} registration
 */
Ship.prototype['registration'] = undefined;

/**
 * @member {module:models/ShipNav} nav
 */
Ship.prototype['nav'] = undefined;

/**
 * @member {module:models/ShipCrew} crew
 */
Ship.prototype['crew'] = undefined;

/**
 * @member {module:models/ShipFrame} frame
 */
Ship.prototype['frame'] = undefined;

/**
 * @member {module:models/ShipReactor} reactor
 */
Ship.prototype['reactor'] = undefined;

/**
 * @member {module:models/ShipEngine} engine
 */
Ship.prototype['engine'] = undefined;

/**
 * @member {module:models/Cooldown} cooldown
 */
Ship.prototype['cooldown'] = undefined;

/**
 * Modules installed in this ship.
 * @member {Array.<module:models/ShipModule>} modules
 */
Ship.prototype['modules'] = undefined;

/**
 * Mounts installed in this ship.
 * @member {Array.<module:models/ShipMount>} mounts
 */
Ship.prototype['mounts'] = undefined;

/**
 * @member {module:models/ShipCargo} cargo
 */
Ship.prototype['cargo'] = undefined;

/**
 * @member {module:models/ShipFuel} fuel
 */
Ship.prototype['fuel'] = undefined;






export default Ship;

