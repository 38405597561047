/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Construction from './Construction';
import ShipCargo from './ShipCargo';

/**
 * The SupplyConstruction201ResponseData model module.
 * @module models/SupplyConstruction201ResponseData
 * @version 2.0.0
 */
class SupplyConstruction201ResponseData {
    /**
     * Constructs a new <code>SupplyConstruction201ResponseData</code>.
     * @alias module:models/SupplyConstruction201ResponseData
     * @param construction {module:models/Construction} 
     * @param cargo {module:models/ShipCargo} 
     */
    constructor(construction, cargo) { 
        
        SupplyConstruction201ResponseData.initialize(this, construction, cargo);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, construction, cargo) { 
        obj['construction'] = construction;
        obj['cargo'] = cargo;
    }

    /**
     * Constructs a <code>SupplyConstruction201ResponseData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/SupplyConstruction201ResponseData} obj Optional instance to populate.
     * @return {module:models/SupplyConstruction201ResponseData} The populated <code>SupplyConstruction201ResponseData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SupplyConstruction201ResponseData();

            if (data.hasOwnProperty('construction')) {
                obj['construction'] = Construction.constructFromObject(data['construction']);
            }
            if (data.hasOwnProperty('cargo')) {
                obj['cargo'] = ShipCargo.constructFromObject(data['cargo']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>SupplyConstruction201ResponseData</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>SupplyConstruction201ResponseData</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of SupplyConstruction201ResponseData.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // validate the optional field `construction`
        if (data['construction']) { // data not null
          Construction.validateJSON(data['construction']);
        }
        // validate the optional field `cargo`
        if (data['cargo']) { // data not null
          ShipCargo.validateJSON(data['cargo']);
        }

        return true;
    }


}

SupplyConstruction201ResponseData.RequiredProperties = ["construction", "cargo"];

/**
 * @member {module:models/Construction} construction
 */
SupplyConstruction201ResponseData.prototype['construction'] = undefined;

/**
 * @member {module:models/ShipCargo} cargo
 */
SupplyConstruction201ResponseData.prototype['cargo'] = undefined;






export default SupplyConstruction201ResponseData;

