/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AcceptContract200Response from '../models/AcceptContract200Response';
import DeliverContract200Response from '../models/DeliverContract200Response';
import DeliverContractRequest from '../models/DeliverContractRequest';
import FulfillContract200Response from '../models/FulfillContract200Response';
import GetContract200Response from '../models/GetContract200Response';
import GetContracts200Response from '../models/GetContracts200Response';

/**
* Contracts service.
* @module api/ContractsApi
* @version 2.0.0
*/
export default class ContractsApi {

    /**
    * Constructs a new ContractsApi. 
    * @alias module:api/ContractsApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Accept Contract
     * Accept a contract by ID.   You can only accept contracts that were offered to you, were not accepted yet, and whose deadlines has not passed yet.
     * @param {String} contractId The contract ID to accept.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:models/AcceptContract200Response} and HTTP response
     */
    acceptContractWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling acceptContract");
      }

      let pathParams = {
        'contractId': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AgentToken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AcceptContract200Response;
      return this.apiClient.callApi(
        '/my/contracts/{contractId}/accept', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Accept Contract
     * Accept a contract by ID.   You can only accept contracts that were offered to you, were not accepted yet, and whose deadlines has not passed yet.
     * @param {String} contractId The contract ID to accept.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:models/AcceptContract200Response}
     */
    acceptContract(contractId) {
      return this.acceptContractWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Deliver Cargo to Contract
     * Deliver cargo to a contract.  In order to use this API, a ship must be at the delivery location (denoted in the delivery terms as `destinationSymbol` of a contract) and must have a number of units of a good required by this contract in its cargo.  Cargo that was delivered will be removed from the ship's cargo.
     * @param {String} contractId The ID of the contract.
     * @param {Object} opts Optional parameters
     * @param {module:models/DeliverContractRequest} [deliverContractRequest] 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:models/DeliverContract200Response} and HTTP response
     */
    deliverContractWithHttpInfo(contractId, opts) {
      opts = opts || {};
      let postBody = opts['deliverContractRequest'];
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling deliverContract");
      }

      let pathParams = {
        'contractId': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AgentToken'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = DeliverContract200Response;
      return this.apiClient.callApi(
        '/my/contracts/{contractId}/deliver', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Deliver Cargo to Contract
     * Deliver cargo to a contract.  In order to use this API, a ship must be at the delivery location (denoted in the delivery terms as `destinationSymbol` of a contract) and must have a number of units of a good required by this contract in its cargo.  Cargo that was delivered will be removed from the ship's cargo.
     * @param {String} contractId The ID of the contract.
     * @param {Object} opts Optional parameters
     * @param {module:models/DeliverContractRequest} opts.deliverContractRequest 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:models/DeliverContract200Response}
     */
    deliverContract(contractId, opts) {
      return this.deliverContractWithHttpInfo(contractId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Fulfill Contract
     * Fulfill a contract. Can only be used on contracts that have all of their delivery terms fulfilled.
     * @param {String} contractId The ID of the contract to fulfill.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:models/FulfillContract200Response} and HTTP response
     */
    fulfillContractWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling fulfillContract");
      }

      let pathParams = {
        'contractId': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AgentToken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = FulfillContract200Response;
      return this.apiClient.callApi(
        '/my/contracts/{contractId}/fulfill', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Fulfill Contract
     * Fulfill a contract. Can only be used on contracts that have all of their delivery terms fulfilled.
     * @param {String} contractId The ID of the contract to fulfill.
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:models/FulfillContract200Response}
     */
    fulfillContract(contractId) {
      return this.fulfillContractWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Get Contract
     * Get the details of a contract by ID.
     * @param {String} contractId The contract ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:models/GetContract200Response} and HTTP response
     */
    getContractWithHttpInfo(contractId) {
      let postBody = null;
      // verify the required parameter 'contractId' is set
      if (contractId === undefined || contractId === null) {
        throw new Error("Missing the required parameter 'contractId' when calling getContract");
      }

      let pathParams = {
        'contractId': contractId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AgentToken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetContract200Response;
      return this.apiClient.callApi(
        '/my/contracts/{contractId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Get Contract
     * Get the details of a contract by ID.
     * @param {String} contractId The contract ID
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:models/GetContract200Response}
     */
    getContract(contractId) {
      return this.getContractWithHttpInfo(contractId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * List Contracts
     * Return a paginated list of all your contracts.
     * @param {Object} opts Optional parameters
     * @param {Number} [page = 1)] What entry offset to request
     * @param {Number} [limit = 10)] How many entries to return per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:models/GetContracts200Response} and HTTP response
     */
    getContractsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'page': opts['page'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['AgentToken'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetContracts200Response;
      return this.apiClient.callApi(
        '/my/contracts', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * List Contracts
     * Return a paginated list of all your contracts.
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page What entry offset to request (default to 1)
     * @param {Number} opts.limit How many entries to return per page (default to 10)
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:models/GetContracts200Response}
     */
    getContracts(opts = {}) {
      return this.getContractsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
