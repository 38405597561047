/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The JumpShipRequest model module.
 * @module models/JumpShipRequest
 * @version 2.0.0
 */
class JumpShipRequest {
    /**
     * Constructs a new <code>JumpShipRequest</code>.
     * @alias module:models/JumpShipRequest
     * @param waypointSymbol {String} The symbol of the waypoint to jump to. The destination must be a connected waypoint.
     */
    constructor(waypointSymbol) { 
        
        JumpShipRequest.initialize(this, waypointSymbol);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, waypointSymbol) { 
        obj['waypointSymbol'] = waypointSymbol;
    }

    /**
     * Constructs a <code>JumpShipRequest</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/JumpShipRequest} obj Optional instance to populate.
     * @return {module:models/JumpShipRequest} The populated <code>JumpShipRequest</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new JumpShipRequest();

            if (data.hasOwnProperty('waypointSymbol')) {
                obj['waypointSymbol'] = ApiClient.convertToType(data['waypointSymbol'], 'String');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>JumpShipRequest</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>JumpShipRequest</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of JumpShipRequest.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['waypointSymbol'] && !(typeof data['waypointSymbol'] === 'string' || data['waypointSymbol'] instanceof String)) {
            throw new Error("Expected the field `waypointSymbol` to be a primitive type in the JSON string but got " + data['waypointSymbol']);
        }

        return true;
    }


}

JumpShipRequest.RequiredProperties = ["waypointSymbol"];

/**
 * The symbol of the waypoint to jump to. The destination must be a connected waypoint.
 * @member {String} waypointSymbol
 */
JumpShipRequest.prototype['waypointSymbol'] = undefined;






export default JumpShipRequest;

