/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FactionSymbol from './FactionSymbol';
import FactionTrait from './FactionTrait';

/**
 * The Faction model module.
 * @module models/Faction
 * @version 2.0.0
 */
class Faction {
    /**
     * Constructs a new <code>Faction</code>.
     * Faction details.
     * @alias module:models/Faction
     * @param symbol {module:models/FactionSymbol} 
     * @param name {String} Name of the faction.
     * @param description {String} Description of the faction.
     * @param headquarters {String} The waypoint in which the faction's HQ is located in.
     * @param traits {Array.<module:models/FactionTrait>} List of traits that define this faction.
     * @param isRecruiting {Boolean} Whether or not the faction is currently recruiting new agents.
     */
    constructor(symbol, name, description, headquarters, traits, isRecruiting) { 
        
        Faction.initialize(this, symbol, name, description, headquarters, traits, isRecruiting);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, name, description, headquarters, traits, isRecruiting) { 
        obj['symbol'] = symbol;
        obj['name'] = name;
        obj['description'] = description;
        obj['headquarters'] = headquarters;
        obj['traits'] = traits;
        obj['isRecruiting'] = isRecruiting;
    }

    /**
     * Constructs a <code>Faction</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/Faction} obj Optional instance to populate.
     * @return {module:models/Faction} The populated <code>Faction</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Faction();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = FactionSymbol.constructFromObject(data['symbol']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('headquarters')) {
                obj['headquarters'] = ApiClient.convertToType(data['headquarters'], 'String');
            }
            if (data.hasOwnProperty('traits')) {
                obj['traits'] = ApiClient.convertToType(data['traits'], [FactionTrait]);
            }
            if (data.hasOwnProperty('isRecruiting')) {
                obj['isRecruiting'] = ApiClient.convertToType(data['isRecruiting'], 'Boolean');
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>Faction</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>Faction</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of Faction.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['headquarters'] && !(typeof data['headquarters'] === 'string' || data['headquarters'] instanceof String)) {
            throw new Error("Expected the field `headquarters` to be a primitive type in the JSON string but got " + data['headquarters']);
        }
        if (data['traits']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['traits'])) {
                throw new Error("Expected the field `traits` to be an array in the JSON data but got " + data['traits']);
            }
            // validate the optional field `traits` (array)
            for (const item of data['traits']) {
                FactionTrait.validateJSON(item);
            };
        }

        return true;
    }


}

Faction.RequiredProperties = ["symbol", "name", "description", "headquarters", "traits", "isRecruiting"];

/**
 * @member {module:models/FactionSymbol} symbol
 */
Faction.prototype['symbol'] = undefined;

/**
 * Name of the faction.
 * @member {String} name
 */
Faction.prototype['name'] = undefined;

/**
 * Description of the faction.
 * @member {String} description
 */
Faction.prototype['description'] = undefined;

/**
 * The waypoint in which the faction's HQ is located in.
 * @member {String} headquarters
 */
Faction.prototype['headquarters'] = undefined;

/**
 * List of traits that define this faction.
 * @member {Array.<module:models/FactionTrait>} traits
 */
Faction.prototype['traits'] = undefined;

/**
 * Whether or not the faction is currently recruiting new agents.
 * @member {Boolean} isRecruiting
 */
Faction.prototype['isRecruiting'] = undefined;






export default Faction;

