/**
 * SpaceTraders API
 * SpaceTraders is an open-universe game and learning platform that offers a set of HTTP endpoints to control a fleet of ships and explore a multiplayer universe.  The API is documented using [OpenAPI](https://github.com/SpaceTradersAPI/api-docs). You can send your first request right here in your browser to check the status of the game server.  ```json http {   \"method\": \"GET\",   \"url\": \"https://api.spacetraders.io/v2\", } ```  Unlike a traditional game, SpaceTraders does not have a first-party client or app to play the game. Instead, you can use the API to build your own client, write a script to automate your ships, or try an app built by the community.  We have a [Discord channel](https://discord.com/invite/jh6zurdWk5) where you can share your projects, ask questions, and get help from other players.   
 *
 * The version of the OpenAPI document: 2.0.0
 * Contact: joel@spacetraders.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShipRequirements from './ShipRequirements';

/**
 * The ShipEngine model module.
 * @module models/ShipEngine
 * @version 2.0.0
 */
class ShipEngine {
    /**
     * Constructs a new <code>ShipEngine</code>.
     * The engine determines how quickly a ship travels between waypoints.
     * @alias module:models/ShipEngine
     * @param symbol {module:models/ShipEngine.SymbolEnum} The symbol of the engine.
     * @param name {String} The name of the engine.
     * @param description {String} The description of the engine.
     * @param condition {Number} The repairable condition of a component. A value of 0 indicates the component needs significant repairs, while a value of 1 indicates the component is in near perfect condition. As the condition of a component is repaired, the overall integrity of the component decreases.
     * @param integrity {Number} The overall integrity of the component, which determines the performance of the component. A value of 0 indicates that the component is almost completely degraded, while a value of 1 indicates that the component is in near perfect condition. The integrity of the component is non-repairable, and represents permanent wear over time.
     * @param speed {Number} The speed stat of this engine. The higher the speed, the faster a ship can travel from one point to another. Reduces the time of arrival when navigating the ship.
     * @param requirements {module:models/ShipRequirements} 
     */
    constructor(symbol, name, description, condition, integrity, speed, requirements) { 
        
        ShipEngine.initialize(this, symbol, name, description, condition, integrity, speed, requirements);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, symbol, name, description, condition, integrity, speed, requirements) { 
        obj['symbol'] = symbol;
        obj['name'] = name;
        obj['description'] = description;
        obj['condition'] = condition;
        obj['integrity'] = integrity;
        obj['speed'] = speed;
        obj['requirements'] = requirements;
    }

    /**
     * Constructs a <code>ShipEngine</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:models/ShipEngine} obj Optional instance to populate.
     * @return {module:models/ShipEngine} The populated <code>ShipEngine</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShipEngine();

            if (data.hasOwnProperty('symbol')) {
                obj['symbol'] = ApiClient.convertToType(data['symbol'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('condition')) {
                obj['condition'] = ApiClient.convertToType(data['condition'], 'Number');
            }
            if (data.hasOwnProperty('integrity')) {
                obj['integrity'] = ApiClient.convertToType(data['integrity'], 'Number');
            }
            if (data.hasOwnProperty('speed')) {
                obj['speed'] = ApiClient.convertToType(data['speed'], 'Number');
            }
            if (data.hasOwnProperty('requirements')) {
                obj['requirements'] = ShipRequirements.constructFromObject(data['requirements']);
            }
        }
        return obj;
    }

    /**
     * Validates the JSON data with respect to <code>ShipEngine</code>.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @return {boolean} to indicate whether the JSON data is valid with respect to <code>ShipEngine</code>.
     */
    static validateJSON(data) {
        // check to make sure all required properties are present in the JSON string
        for (const property of ShipEngine.RequiredProperties) {
            if (!data.hasOwnProperty(property)) {
                throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
            }
        }
        // ensure the json data is a string
        if (data['symbol'] && !(typeof data['symbol'] === 'string' || data['symbol'] instanceof String)) {
            throw new Error("Expected the field `symbol` to be a primitive type in the JSON string but got " + data['symbol']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // validate the optional field `requirements`
        if (data['requirements']) { // data not null
          ShipRequirements.validateJSON(data['requirements']);
        }

        return true;
    }


}

ShipEngine.RequiredProperties = ["symbol", "name", "description", "condition", "integrity", "speed", "requirements"];

/**
 * The symbol of the engine.
 * @member {module:models/ShipEngine.SymbolEnum} symbol
 */
ShipEngine.prototype['symbol'] = undefined;

/**
 * The name of the engine.
 * @member {String} name
 */
ShipEngine.prototype['name'] = undefined;

/**
 * The description of the engine.
 * @member {String} description
 */
ShipEngine.prototype['description'] = undefined;

/**
 * The repairable condition of a component. A value of 0 indicates the component needs significant repairs, while a value of 1 indicates the component is in near perfect condition. As the condition of a component is repaired, the overall integrity of the component decreases.
 * @member {Number} condition
 */
ShipEngine.prototype['condition'] = undefined;

/**
 * The overall integrity of the component, which determines the performance of the component. A value of 0 indicates that the component is almost completely degraded, while a value of 1 indicates that the component is in near perfect condition. The integrity of the component is non-repairable, and represents permanent wear over time.
 * @member {Number} integrity
 */
ShipEngine.prototype['integrity'] = undefined;

/**
 * The speed stat of this engine. The higher the speed, the faster a ship can travel from one point to another. Reduces the time of arrival when navigating the ship.
 * @member {Number} speed
 */
ShipEngine.prototype['speed'] = undefined;

/**
 * @member {module:models/ShipRequirements} requirements
 */
ShipEngine.prototype['requirements'] = undefined;





/**
 * Allowed values for the <code>symbol</code> property.
 * @enum {String}
 * @readonly
 */
ShipEngine['SymbolEnum'] = {

    /**
     * value: "ENGINE_IMPULSE_DRIVE_I"
     * @const
     */
    "IMPULSE_DRIVE_I": "ENGINE_IMPULSE_DRIVE_I",

    /**
     * value: "ENGINE_ION_DRIVE_I"
     * @const
     */
    "ION_DRIVE_I": "ENGINE_ION_DRIVE_I",

    /**
     * value: "ENGINE_ION_DRIVE_II"
     * @const
     */
    "ION_DRIVE_II": "ENGINE_ION_DRIVE_II",

    /**
     * value: "ENGINE_HYPER_DRIVE_I"
     * @const
     */
    "HYPER_DRIVE_I": "ENGINE_HYPER_DRIVE_I"
};



export default ShipEngine;

